import { DataSourceSettings } from '@grafana/data';
import { createAsyncThunk, AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { setRuleGroup, deleteRuleGroup, deleteNamespace } from 'pages/Rules/utils/api';
import { ReduxState } from 'store';
import { getRuler } from 'store/utils/datasources';
import { RuleGroupYML } from 'types/rulesExternal';
import { RulesConfig } from 'types/rulesInternal';
import { DataSourceType } from 'utils/enums';
import { assertIsDefined } from 'utils/misc';
import { rulesActions, fetchRulesConfig } from '.';

export type GroupLocation = {
  groupIndex: number;
  namespace: string;
};

export type RuleLocation = GroupLocation & {
  ruleIndex: number;
};

export type MoveRulePayload = {
  source: RuleLocation;
  destination: RuleLocation;
};

/**
 * async thunk factory that will:
 * cancel ongoing rules fetch, dispatch start/finish actions, initiate rules re-fetch after update is done
 */

type CreateRulesChangeThunkExtraArgs = {
  rulesConfig: RulesConfig;
  ruler: DataSourceSettings;
  rulesSource: DataSourceSettings;
};

type DataSourceArg = {
  dataSourceName: string;
};

const createRulesChangeThunk = <T extends DataSourceArg = DataSourceArg>(
  prefix: string,
  payloadCreator: AsyncThunkPayloadCreator<void, T & CreateRulesChangeThunkExtraArgs>
) =>
  createAsyncThunk(prefix, async (arg: T, thunkAPI) => {
    const state = thunkAPI.getState() as ReduxState;
    const rulesConfig = state.rules.rulesConfig;
    const { ruler, rulesSource } = getRuler(state, arg.dataSourceName);
    if (!rulesConfig) {
      throw new Error('rules config not found in state when trying to edit rules');
    } else if (!ruler) {
      throw new Error('ruler name not found in state when trying to edit rules');
    } else if (state.rules.isWriteInProgress) {
      throw new Error('write already in progress');
    } else {
      try {
        thunkAPI.dispatch(rulesActions.startWriting());
        await payloadCreator({ ...arg, rulesConfig, ruler, rulesSource }, thunkAPI);
        await thunkAPI.dispatch(fetchRulesConfig(arg));
        thunkAPI.dispatch(rulesActions.finishedWriting());
        return {};
      } catch (e) {
        thunkAPI.dispatch(rulesActions.finishedWriting());
        return thunkAPI.rejectWithValue(e);
      }
    }
  });

export const setGroup = createRulesChangeThunk<GroupLocation & DataSourceArg>(
  'rules/setGroup',
  async ({ namespace, groupIndex, rulesConfig, ruler, rulesSource }) => {
    const group = rulesConfig[namespace][groupIndex];
    await setRuleGroup(ruler, rulesSource, namespace, group);
  }
);

export const renameGroup = createRulesChangeThunk<
  { groupIndex: number; newGroupName: string; namespace: string } & DataSourceArg
>('rules/renameGroup', async ({ namespace, groupIndex, newGroupName, rulesConfig, ruler, rulesSource }) => {
  const origGroup = getRuleGroup(rulesConfig, namespace, groupIndex);
  const newGroup: RuleGroupYML = {
    ...origGroup,
    name: newGroupName,
  };
  // doing these sequentially with the hope that in case 2nd op fails, it's better to have 2 duplicate groups than none at all
  await setRuleGroup(ruler, rulesSource, namespace, newGroup);
  await deleteRuleGroup(ruler, rulesSource, namespace, origGroup.name);
});

// we expose it both as a dedicated thunk below, as well as use it in moveRule thunk
async function internalDeleteRule(params: RuleLocation & CreateRulesChangeThunkExtraArgs) {
  const { namespace, groupIndex, rulesConfig, ruler, rulesSource, ruleIndex } = params;
  const group = getRuleGroup(rulesConfig, namespace, groupIndex);
  if (group.rules.length < ruleIndex + 1) {
    throw new Error(
      `trying to delete rule idx ${ruleIndex} from group ${namespace}:${group.name}, but group only has ${group.rules.length} groups`
    );
  }

  if (group.rules.length === 1) {
    // Loki seems to not support deleting namespaces at the time: https://github.com/grafana/alerting-ui-plugin/issues/216
    if (rulesConfig[namespace].length === 1 && rulesSource.type !== DataSourceType.Loki) {
      await deleteNamespace(ruler, rulesSource, namespace);
    } else {
      await deleteRuleGroup(ruler, rulesSource, namespace, group.name);
    }
  } else {
    const groupPayload = cloneDeep(group);
    groupPayload.rules.splice(ruleIndex, 1);
    await setRuleGroup(ruler, rulesSource, namespace, groupPayload);
  }
}

export const deleteRule = createRulesChangeThunk<RuleLocation & DataSourceArg>('rules/deleteRule', internalDeleteRule);

export const moveRule = createRulesChangeThunk<MoveRulePayload & DataSourceArg>(
  'rules/moveRule',
  async ({ source, destination, rulesConfig, ruler, rulesSource }) => {
    const sourceGroup = assertIsDefined(rulesConfig[source.namespace][source.groupIndex], 'source group not found');
    const destinationGroup = assertIsDefined(
      rulesConfig[destination.namespace][destination.groupIndex],
      'destination group not found'
    );
    if (source.ruleIndex >= sourceGroup.rules.length) {
      throw new Error('invalid source rule index');
    }
    if (destination.ruleIndex > destinationGroup.rules.length) {
      throw new Error('invalid destination rule index');
    }

    // move within same group
    if (sourceGroup === destinationGroup) {
      const groupPayload = cloneDeep(sourceGroup);
      const sourceRule = groupPayload.rules[source.ruleIndex];
      groupPayload.rules.splice(destination.ruleIndex, 0, cloneDeep(sourceRule));
      groupPayload.rules.splice(groupPayload.rules.indexOf(sourceRule), 1);
      await setRuleGroup(ruler, rulesSource, destination.namespace, groupPayload);
    } else {
      // move between groups or namespaces
      const destGroupPayload = cloneDeep(destinationGroup);
      destGroupPayload.rules.splice(destination.ruleIndex, 0, sourceGroup.rules[source.ruleIndex]);
      await setRuleGroup(ruler, rulesSource, destination.namespace, destGroupPayload);
      await internalDeleteRule({
        ruler,
        rulesSource,
        rulesConfig,
        ...source,
      });
    }
  }
);

function getRuleGroup(rulesConfig: RulesConfig, namespace: string, groupIndex: number): RuleGroupYML {
  if (!rulesConfig[namespace]) {
    throw new Error(`Namespace ${namespace} not found in rules config`);
  }
  const group = rulesConfig[namespace][groupIndex];
  if (!group) {
    throw new Error(
      `Group with index ${groupIndex} not found in namespace ${namespace}. It only has ${rulesConfig[namespace].length} groups.`
    );
  }
  return group;
}
