import React, { FC } from 'react';
import { GrayTag } from './GrayTag';
import { TagGroup } from './TagGroup';

type Props = {
  labelKey: string;
  value: string;

  className?: string;
  onClick?: (label: string, value: string) => void;
};

type AlertLabelsProps = { labels: Record<string, string> } & Pick<Props, 'onClick'>;

export const AlertLabels: FC<AlertLabelsProps> = ({ labels, onClick }) => (
  <TagGroup>
    {Object.entries(labels).map(([key, value]) => (
      <AlertLabel key={key} labelKey={key} value={value} onClick={onClick} />
    ))}
  </TagGroup>
);

export const AlertLabel: React.FC<Props> = ({ labelKey, value, className, onClick }) => {
  const onTagClick = onClick && (() => onClick(labelKey, value));
  return <GrayTag className={className} name={`${labelKey}=${value}`} onClick={onTagClick} />;
};
