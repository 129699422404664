import { YMLCode } from 'components/YMLCode';
import React, { FC, useMemo } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { rulesActions } from 'store/rules';
import { deleteRule as deleteRuleAction, moveRule as moveRuleAction, RuleLocation } from 'store/rules/thunks';
import { AlertingRuleYML, RecordingRuleYML } from 'types/rulesExternal';
import { useDispatchWithFeedback } from 'utils/hooks';
import { trackEvent } from 'utils/tracking';
import yaml from 'yaml';
import { RuleMeta } from '../EditRuleList';
import { rulesText } from '../utils/consts';
import { EditRuleContainer } from './EditRuleContainer';
import { EditRuleHeader } from './EditRuleHeader';
import { EditRuleHeaderControls } from './EditRuleHeaderControls';

export type Props = {
  isWriteInProgress: boolean;
  rule: AlertingRuleYML | RecordingRuleYML;
  ruleMeta: RuleMeta;
  dataSourceName: string;
};

export const EditRuleReadMode: FC<Props> = ({ isWriteInProgress, ruleMeta, rule, dataSourceName }) => {
  const dispatch: AppDispatch = useDispatch();
  const dispatchWithFeedback = useDispatchWithFeedback();

  const { location } = ruleMeta;

  const definition = useMemo(() => yaml.stringify(rule), [rule]);

  const deleteRule = useAsyncCallback(() => {
    trackEvent('Click delete rule');
    return dispatchWithFeedback(deleteRuleAction({ ...location, dataSourceName }), rulesText.dispatchToasts);
  });

  const moveRule = useAsyncCallback((destination: RuleLocation) => {
    return dispatchWithFeedback(
      moveRuleAction({ source: location, destination, dataSourceName }),
      rulesText.dispatchToasts
    );
  });

  const editRule = () => {
    trackEvent('Click edit rule');
    dispatch(rulesActions.editRule(location));
  };
  return (
    <EditRuleContainer loading={deleteRule.loading || moveRule.loading}>
      <EditRuleContainer.Inner>
        <EditRuleHeader rule={rule}>
          {!isWriteInProgress && (
            <EditRuleHeaderControls
              onDelete={deleteRule.execute}
              onMove={(dest) => moveRule.execute(dest)}
              onStartEditing={editRule}
              ruleMeta={ruleMeta}
            />
          )}
        </EditRuleHeader>
        <YMLCode value={definition} />
      </EditRuleContainer.Inner>
    </EditRuleContainer>
  );
};
