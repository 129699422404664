import { AnyAction, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import mergeWith from 'lodash/mergeWith';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { PromiseType } from 'utility-types';
import { reduxStateMergeCustomizer } from 'utils/misc';
import { initialState as alertsInitialState, alertsSlice, AlertsState } from './alerts';
import { loadState, saveState } from './indexeddb';
import { initialState as routesInitialState, routesSlice, RoutesState } from './routes';
import { initialState as rulesInitialState, rulesSlice, RulesState } from './rules';
import { initialState as silencesInitialState, silencesSlice, SilencesState } from './silences';

export type ReduxState = { alerts: AlertsState; rules: RulesState; routes: RoutesState; silences: SilencesState };

export const initialState: ReduxState = {
  alerts: alertsInitialState,
  rules: rulesInitialState,
  routes: routesInitialState,
  silences: silencesInitialState,
};
export const useReduxSelector: TypedUseSelectorHook<ReduxState> = useSelector;

export async function createStore() {
  const storedState = await loadState();
  const store = configureStore<
    { [key in keyof ReduxState]: ReduxState[key] },
    AnyAction,
    ReturnType<typeof getDefaultMiddleware>
  >({
    middleware: getDefaultMiddleware({ thunk: true, immutableCheck: false, serializableCheck: false }),
    preloadedState: mergeWith(initialState, storedState, reduxStateMergeCustomizer),
    reducer: {
      alerts: alertsSlice.reducer,
      routes: routesSlice.reducer,
      rules: rulesSlice.reducer,
      silences: silencesSlice.reducer,
    },
  });

  store.subscribe(() => {
    saveState(store.getState());
  });

  return store;
}

export type AppDispatch = PromiseType<ReturnType<typeof createStore>>['dispatch'];
