import { GrafanaTheme } from '@grafana/data';
import { useStyles, Tooltip } from '@grafana/ui';
import { GrayTag } from 'components/GrayTag';
import { TagGroup } from 'components/TagGroup';
import { css } from 'emotion';
import { getReceiverIntegrations, countReceiverUsage } from 'pages/Receivers/utils/misc';
import React, { FC, Fragment, useState } from 'react';
import { AlertmanagerConfig } from 'types/alertmanager';
import { alertmanagerIntegrationNames } from 'utils/alertmanager/consts';
import { text } from 'utils/consts';
import { QueryKey } from 'utils/enums';
import { useQueryParam } from 'utils/hooks';
import { marginLeft, marginTop, marginBottom } from 'utils/styles';
import { ConfirmDeleteReceiverModal } from './ConfirmDeleteReceiverModal';
import { SectionTable } from './SectionTable';
import { NEW_RECEIVER_NAME } from './utils/consts';
import { useReceiverLabels, useDeleteReceiverRequest } from './utils/hooks';
import { canEditReceivers } from './utils/permissions';

export type ReceiverListProps = {
  config: AlertmanagerConfig;
  updateConfig: (payload: AlertmanagerConfig) => Promise<void>;
};

const getStyles = (theme: GrafanaTheme) => ({
  labelGroupBreak: css`
    ${marginTop(theme).sm};
    ${marginBottom(theme).sm};
  `,
  actions: css`
    a + a {
      ${marginLeft(theme).md};
    }
  `,

  action: css`
    color: ${theme.colors.textBlue};
  `,

  actionDisabled: css`
    color: ${theme.colors.textWeak};
  `,

  actionCol: css`
    width: 115px;
  `,
});

export const ReceiverList: FC<ReceiverListProps> = ({ config, updateConfig }) => {
  const receiverLabels = useReceiverLabels(config);
  const styles = useStyles(getStyles);
  const [toBeDeletedReceiverName, setToBeDeletedReceiverName] = useState<string>();
  const setEditReceiverName = useQueryParam(QueryKey.ReceiversReceiverName)[1];

  const numberOfRotuesWithToBeDeletedReceiver =
    config.route && toBeDeletedReceiverName ? countReceiverUsage(config.route, toBeDeletedReceiverName) : 0;

  const deleteRequest = useDeleteReceiverRequest(config, updateConfig);

  const onConfirmDeleteReceiver = () => {
    if (toBeDeletedReceiverName) {
      deleteRequest.execute(toBeDeletedReceiverName);
      setToBeDeletedReceiverName(undefined);
    }
  };

  return (
    <>
      <SectionTable
        header={text.receivers.receiversSectionTitle}
        description={text.receivers.receiversSectionDescription}
        addButtonLabel={text.receivers.newReceiverButton}
        onAdd={() => setEditReceiverName(NEW_RECEIVER_NAME)}
      >
        <colgroup>
          <col />
          <col />
          <col />
          {canEditReceivers() && <col className={styles.actionCol} />}
        </colgroup>
        <thead>
          <tr>
            <th>{text.receivers.receiversTableColName}</th>
            <th>{text.receivers.receiversTableColIntTypes}</th>
            <th>{text.receivers.receiversTableColRouteLabels}</th>
            {canEditReceivers() && <th>{text.receivers.receiversTableColActions}</th>}
          </tr>
        </thead>
        <tbody>
          {!config.receivers?.length && (
            <tr>
              <td colSpan={canEditReceivers() ? 4 : 3}>{text.error.receiversNoneFound}</td>
            </tr>
          )}
          {!!config.receivers?.length &&
            config.receivers.map((receiver) => (
              <tr key={receiver.name} data-testid="receiver-row">
                <td>{receiver.name}</td>
                <td>
                  {getReceiverIntegrations(receiver)
                    .map((int) => alertmanagerIntegrationNames[int])
                    .join(', ')}
                </td>
                <td>
                  {receiverLabels[receiver.name]?.map((labels, idx) => (
                    <Fragment key={idx}>
                      {idx > 0 && <hr className={styles.labelGroupBreak} />}
                      <TagGroup key={idx}>
                        {labels.map((label) => (
                          <GrayTag key={label} name={label} />
                        ))}
                      </TagGroup>
                    </Fragment>
                  ))}
                </td>
                {canEditReceivers() && (
                  <td className={styles.actions}>
                    <a
                      className={styles.action}
                      onClick={() => {
                        setEditReceiverName(receiver.name);
                      }}
                    >
                      {text.receivers.actionEdit}
                    </a>
                    {config.route?.receiver !== receiver.name && (
                      <a className={styles.action} onClick={() => setToBeDeletedReceiverName(receiver.name)}>
                        {text.receivers.actionDelete}
                      </a>
                    )}
                    {config.route?.receiver === receiver.name && (
                      <Tooltip content={text.receivers.cannotDeleteDefaultReceiverMsg} theme="info">
                        <a className={styles.actionDisabled}>{text.receivers.actionDelete}</a>
                      </Tooltip>
                    )}
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </SectionTable>
      {toBeDeletedReceiverName && (
        <ConfirmDeleteReceiverModal
          receiverName={toBeDeletedReceiverName}
          numRoutesAffected={numberOfRotuesWithToBeDeletedReceiver}
          onConfirm={onConfirmDeleteReceiver}
          onDismiss={() => setToBeDeletedReceiverName(undefined)}
        />
      )}
    </>
  );
};
