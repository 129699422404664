import { GrafanaTheme, AppEvents } from '@grafana/data';
import { makeValue, useStyles, FieldValidationMessage, Field, Input, TextArea, InfoBox, Switch } from '@grafana/ui';
import { Button } from 'components/Button';
import { ButtonsContainer } from 'components/ButtonsContainer';
import { CardContainer } from 'components/CardContainer';
import { YMLCodeEditor } from 'components/YMLCodeEditor';
import { css } from 'emotion';
import appEvents from 'grafana/app/core/app_events';
import React, { FC, useMemo, useCallback } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useForm, Controller, FieldError, useFieldArray, Validate } from 'react-hook-form';
import { AlertmanagerCortexConfig, AlertmanagerConfig } from 'types/alertmanager';
import { ALERTMANAGER_CONFIG_ERROR_PREFIX, ALERTMANAGER_CONFIG_TEMPLATE_ERROR_RE } from 'utils/alertmanager/consts';
import { ParsedCortexAlertmanagerConfig, UpdateConfigFn, DeleteConfigFn } from 'utils/alertmanager/hooks';
import { isFetchError } from 'utils/api';
import { text, isAdmin } from 'utils/consts';
import { Feature } from 'utils/enums';
import { useSelectedAlertManagerName } from 'utils/hooks';
import { isFeatureEnabled } from 'utils/misc';
import { marginTop, marginBottom, fontSize } from 'utils/styles';
import { trackEvent } from 'utils/tracking';
import yaml from 'yaml';
import { FormSection } from './FormSection';
import { GlobalConfigHeader } from './GlobalConfigHeader';

const getStyles = (theme: GrafanaTheme) => ({
  deleteLink: css`
    color: ${theme.colors.textBlue};
    position: absolute;
    top: ${theme.spacing.xs};
    right: ${theme.spacing.sm};
  `,
  templateContentTextarea: css`
    min-height: 6em;
  `,
  sectionLabel: css`
    ${marginTop(theme).xl};
  `,
  addTemplateButton: css`
    ${marginBottom(theme).md};
  `,
  infoBox: css`
    ${fontSize(theme).base};
  `,
  templateTopContainer: css`
    display: flex;
    align-items: center;
    & > *:first-item {
      flex: 1;
    }
  `,
});

type TemplateValue = {
  name: string;
  content: string;
  addToConfig: boolean;
};

const emptyTemplate: TemplateValue = {
  name: '',
  content: '',
  addToConfig: true,
};

type FormValues = {
  config: string;
  templates: TemplateValue[];
};

type Props = {
  updateConfig: UpdateConfigFn;
  deleteConfig: DeleteConfigFn;
  onFinishEditing: () => void;

  alertmanagerConfig?: ParsedCortexAlertmanagerConfig;
};

const validateConfig: Validate = (value: string) => {
  if (!value.trim()) {
    return 'Required.';
  }
  try {
    yaml.parse(value, { prettyErrors: true });
  } catch (error) {
    return `\nYAML Parse Error: \n${error.message};`;
  }
  return true;
};

export const GlobalConfigEditMode: FC<Props> = ({
  alertmanagerConfig,
  onFinishEditing,
  updateConfig,
  deleteConfig,
}) => {
  const styles = useStyles(getStyles);
  const selectedAlertmanagerName = useSelectedAlertManagerName();

  const defaultValues: FormValues = useMemo(() => {
    if (alertmanagerConfig) {
      const parsedConf = alertmanagerConfig.config;
      const configuredTemplateNames: string[] =
        parsedConf && typeof parsedConf === 'object' && Array.isArray(parsedConf.templates) ? parsedConf.templates : [];
      return {
        config: yaml.stringify(alertmanagerConfig.config),
        templates: Object.entries(alertmanagerConfig.templates)
          .map(([name, content]) => ({ name, content, addToConfig: configuredTemplateNames.includes(name) }))
          .sort((a, b) => a.name.localeCompare(b.name)),
      };
    } else {
      return {
        config: '',
        templates: [],
      };
    }
  }, [alertmanagerConfig]);

  const {
    register,
    control,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    getValues,
    setValue,
    formState: { isDirty },
  } = useForm<FormValues>({
    reValidateMode: 'onSubmit',
    defaultValues,
  });

  const save = useAsyncCallback(async (data: FormValues) => {
    if (selectedAlertmanagerName) {
      const templatesPayload =
        data.templates?.reduce<AlertmanagerCortexConfig['template_files']>(
          (acc, { name, content }) => ({ ...acc, [name]: content }),
          {}
        ) ?? {};
      const configPayload: AlertmanagerConfig = {
        ...yaml.parse(data.config),
        templates: data.templates?.filter((t) => t.addToConfig).map((t) => t.name) || [],
      };
      try {
        await updateConfig(configPayload, templatesPayload);
        setValue('config', makeValue(yaml.stringify(configPayload)));
        appEvents.emit(AppEvents.alertSuccess, [text.toast.amConfigSuccess]);
        onFinishEditing();
      } catch (e) {
        // try to parse out validation errors for specific fields
        if (isFetchError(e) && e.status === 400 && e.data?.message) {
          const tplErrorMatch = ALERTMANAGER_CONFIG_TEMPLATE_ERROR_RE.exec(e.data.message);
          // for a particular template
          if (tplErrorMatch) {
            const [templateName, errorMessage] = tplErrorMatch.slice(1);
            const templateIndex = data.templates.map((t) => t.name).indexOf(templateName);
            if (templateIndex !== -1) {
              return setError(`templates[${templateIndex}].content`, { type: 'manual', message: errorMessage.trim() });
            }
            // for the main config
          } else if (e.data.message.includes(ALERTMANAGER_CONFIG_ERROR_PREFIX)) {
            return setError('config', {
              type: 'manual',
              message: e.data.message.replace(ALERTMANAGER_CONFIG_ERROR_PREFIX, '').trim(),
            });
          }
        }
        appEvents.emit(AppEvents.alertError, [text.toast.amConfigFailure]);
      }
    }
  });

  // behind a feature flag, only used for testing. hence the ugly confirm dialog
  const deleteConf = useAsyncCallback(async () => {
    trackEvent('Click delete global config');
    if (selectedAlertmanagerName && confirm('Are you sure you want to delete this config?')) {
      await deleteConfig();
      appEvents.emit(AppEvents.alertSuccess, [text.toast.amConfigSuccess]);
      onFinishEditing();
    }
  });

  const onSubmit = (e: React.BaseSyntheticEvent) => {
    trackEvent('Save global config');
    return handleSubmit(save.execute)(e);
  };

  const configErrorMessage = (errors.config as FieldError)?.message;

  const templateFields = useFieldArray<TemplateValue>({
    control,
    name: 'templates',
  });

  const appendTemplate = () => {
    trackEvent('Click add template');
    templateFields.append(emptyTemplate);
  };

  const removeTemplate = (index: number) => {
    trackEvent('Click remove template');
    templateFields.remove(index);
  };

  // validate that there are no duplicate template names
  const validateTemplateName: Validate = useCallback(
    (name: string) => {
      if (name) {
        const values = getValues();
        if (values.templates.filter((t) => t.name === name).length > 1) {
          return 'Duplicate template name.';
        }
      }
      return true;
    },
    [getValues]
  );

  const onFinishEditingClick = () => {
    trackEvent('Cancel editing global config');
    onFinishEditing();
  };

  const loading = save.loading || deleteConf.loading;

  return (
    <form onSubmit={onSubmit}>
      <InfoBox className={styles.infoBox} url="https://prometheus.io/docs/alerting/latest/configuration/">
        Configure Alertmanager like you would through a YAML file. Refer to the Alertmanager docs for more information.
        <br />
        Note that "templates" property will be overwritten based on templates configuration below.
      </InfoBox>
      {isAdmin && (
        <GlobalConfigHeader>
          <ButtonsContainer>
            <Button isLoading={loading} disabled={loading || !isDirty} type="submit">
              Save and finish editing
            </Button>
            <Button type="button" disabled={loading} variant="secondary" onClick={onFinishEditingClick}>
              Cancel
            </Button>

            {isFeatureEnabled(Feature.DeleteAlertmanagerConfig) && (
              <Button
                type="button"
                onClick={deleteConf.execute}
                isLoading={loading}
                disabled={loading}
                variant="destructive"
              >
                Delete
              </Button>
            )}
          </ButtonsContainer>
        </GlobalConfigHeader>
      )}
      <FormSection header="Config">
        <Controller
          control={control}
          name="config"
          rules={{
            validate: validateConfig,
          }}
          render={({ onChange, value }) => (
            <YMLCodeEditor
              autoFocus={true}
              minHeight={128}
              onChange={(newValue) => {
                if (newValue !== value) {
                  if (configErrorMessage) {
                    clearErrors('config');
                  }
                  onChange(newValue);
                }
              }}
              defaultValue={defaultValues.config}
            />
          )}
        />
      </FormSection>
      {configErrorMessage && <FieldValidationMessage>{configErrorMessage}</FieldValidationMessage>}
      <FormSection header="Templates">
        {!templateFields.fields.length && <p>There are no templates defined.</p>}
        {templateFields.fields.map((field, index) => (
          <CardContainer key={field.id} data-testid={`template-${index}`}>
            <a className={styles.deleteLink} onClick={() => removeTemplate(index)}>
              Delete
            </a>
            <Field
              label="Name"
              invalid={!!errors.templates?.[index]?.name}
              error={errors.templates?.[index]?.name?.message || 'Required.'}
            >
              <Input
                id={`templates[${index}].name`}
                name={`templates[${index}].name`}
                autoFocus={!field.name}
                ref={register({ required: true, validate: validateTemplateName })}
                defaultValue={field.name}
              />
            </Field>
            <Field label="Add to config">
              <Switch
                defaultChecked={field.addToConfig}
                data-testid="add-to-config-switch"
                name={`templates[${index}].addToConfig`}
                ref={register()}
              />
            </Field>
            <Field
              label="Content"
              invalid={!!errors.templates?.[index]?.content}
              error={errors.templates?.[index]?.content?.message || 'Required.'}
            >
              <TextArea
                id={`templates[${index}].content`}
                className={styles.templateContentTextarea}
                name={`templates[${index}].content`}
                ref={register({ required: true })}
                defaultValue={field.content}
              />
            </Field>
          </CardContainer>
        ))}
        <ButtonsContainer>
          <Button
            className={styles.addTemplateButton}
            variant="secondary"
            icon="plus"
            onClick={appendTemplate}
            type="button"
            disabled={save.loading}
          >
            Add template
          </Button>
        </ButtonsContainer>
      </FormSection>
    </form>
  );
};
