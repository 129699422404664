export enum Color {
  GreenDarkest = '#1E6910',
  GreenDarker = '#388729',
  Green = '#5AA64B',
  GreenLightest = '#99D98D',
  OrangeDarkest = '#CE4C00',
  OrangeDarker = '##ED5700',
  Orange = '#FF780A',
  OrangeLightest = '#FFB375',
  RedDarkest = '#AB031F',
  RedDarker = '#C41834',
  Red = '#DE314D',
  RedLightest = '#FF7389',
}

export enum DataSourceType {
  Alertmanager = 'grafana-alertmanager-datasource',
  Loki = 'loki',
  Prometheus = 'prometheus',
  Ruler = 'grafana-ruler-datasource',
}

// Always prefix with "alertingui-".
export enum Feature {
  DeleteAlertmanagerConfig = 'alertingui-delete-alertmanager-config',
  Receivers = 'alertingui-receivers',
}

export enum QueryKey {
  SelectedRulesSourceName = 'rulessource',
  SelectedAlertmanagerName = 'alertmanager',
  SilenceEditorDefaultValues = 'silenceEditorDefaults',
  ReceiversReceiverName = 'receiver',
}

export enum Status {
  OK,
  Error,
}

export enum HttpError {
  Unauthorized = 401,
  NotFound = 404,
  Unreachable = 502,
  Other = -1,
}

export enum AlertmanagerStatus {
  Unknown,
  Available,
  Unavailable,
}

/**
 * PAYLOAD VALUES
 */
export enum AlertingRuleState {
  Firing = 'firing',
  Inactive = 'inactive',
  Pending = 'pending',
}

export enum AlertState {
  Unprocessed = 'unprocessed',
  Active = 'active',
  Suppressed = 'suppressed',
}

export enum RuleType {
  Alerting = 'alerting',
  Recording = 'recording',
}

export enum SilenceState {
  Active = 'active',
  Expired = 'expired',
  Pending = 'pending',
}

/**
 * ENDPOINTS
 */
export enum AlertManagerEndpoint {
  Alerts = '/alertmanager/api/v2/alerts',
  AlertGroups = '/alertmanager/api/v2/alerts/groups',
  Config = '/api/v1/alerts',
  Silence = '/alertmanager/api/v2/silence',
  Silences = '/alertmanager/api/v2/silences',
  Status = '/alertmanager/api/v2/status',
}

export enum CortexEndpoint {
  PrometheusRules = '/api/v1/rules',
  Rules = '/rules',
}

export enum LokiEndpoint {
  PrometheusRules = '/prometheus/api/v1/rules',
  // Contrary to Cortex, the /api/prom prefix is used here instead of in the data source because
  // we need to be able to access / during the data source health check (which uses PrometheusRules).
  Rules = '/api/prom/rules',
}

export enum AlertmanagerIntegration {
  Email = 'email',
  PagerDuty = 'pagerduty',
  Pushover = 'pushover',
  Slack = 'slack',
  OpsGenie = 'opsgenie',
  Webhook = 'webhook',
  VictorOps = 'victorops',
  WeChat = 'wechat',
}
