import { GrafanaTheme, AppEvents } from '@grafana/data';
import { useStyles, Field, InfoBox } from '@grafana/ui';
import { Button } from 'components/Button';
import { ButtonsContainer } from 'components/ButtonsContainer';
import { YMLCodeEditor } from 'components/YMLCodeEditor';
import { css } from 'emotion';
import appEvents from 'grafana/app/core/app_events';
import React, { FC, useMemo, useEffect } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useForm, FieldError, Validate, Controller } from 'react-hook-form';
import { AlertmanagerConfig, Route } from 'types/alertmanager';
import { ALERTMANAGER_CONFIG_ERROR_PREFIX } from 'utils/alertmanager/consts';
import { isFetchError } from 'utils/api';
import { text } from 'utils/consts';
import { QueryKey } from 'utils/enums';
import { useQueryParam } from 'utils/hooks';
import { deepCopy } from 'utils/misc';
import { fontSize, marginBottom } from 'utils/styles';
import { trackEvent } from 'utils/tracking';
import yaml from 'yaml';
import { NEW_RECEIVER_NAME } from './utils/consts';

const getStyles = (theme: GrafanaTheme) => ({
  header: css`
    ${fontSize(theme).lg};
    ${marginBottom(theme).xl};
  `,
  infoBox: css`
    ${fontSize(theme).base};
  `,
});

type FormValues = {
  config: string;
};

const makeConfigValidator = (config: AlertmanagerConfig, currentReceiverName?: string): Validate => (value: string) => {
  if (!value.trim()) {
    return 'Required.';
  }
  try {
    const values = yaml.parse(value, { prettyErrors: true });
    if (!values?.name) {
      return text.receivers.validationNameRequired;
    }
    if (typeof values.name !== 'string') {
      return text.receivers.validationNameType;
    }
    if (
      ((currentReceiverName && values.name !== currentReceiverName) || !currentReceiverName) &&
      config.receivers?.find((receiver) => receiver.name === values.name)
    ) {
      return text.receivers.validationReceiverAlreadyExists;
    }
  } catch (error) {
    return `\nYAML Parse Error: \n${error.message};`;
  }
  return true;
};

export type EditReceiverProps = {
  receiverName: string;
  config: AlertmanagerConfig;
  updateConfig: (payload: AlertmanagerConfig) => Promise<void>;
};

export const EditReceiver: FC<EditReceiverProps> = ({ config, receiverName, updateConfig }) => {
  const styles = useStyles(getStyles);
  const isNew = receiverName === NEW_RECEIVER_NAME;
  const setReceiverName = useQueryParam(QueryKey.ReceiversReceiverName)[1];

  useEffect(() => trackEvent('Edit Receiver'), []);

  const defaultValues: FormValues = useMemo(() => {
    const existingReceiver = !isNew && config.receivers?.find((receiver) => receiver.name === receiverName);
    return {
      config: yaml.stringify(existingReceiver || { name: '' }),
    };
  }, [receiverName, config, isNew]);

  const { control, errors, setError, clearErrors, handleSubmit } = useForm<FormValues>({
    reValidateMode: 'onSubmit',
    defaultValues,
  });

  const configErrorMessage = (errors.config as FieldError)?.message;

  const onCancelClick = () => {
    trackEvent('Cancel Editing Receiver');
    setReceiverName(undefined);
  };

  const save = useAsyncCallback(async (data: FormValues) => {
    const receiverPayload = yaml.parse(data.config);
    const payload = deepCopy(config);

    if (payload.receivers) {
      if (!isNew) {
        // in case of editing, replace existing receiver config with updated one
        payload.receivers = payload.receivers.map((receiver) =>
          receiver.name === receiverName ? receiverPayload : receiver
        );
        // if receiver is being renamed, rename all of it's usages in routes as well
        if (payload.route && receiverName !== receiverPayload.name) {
          const rename = (route: Route): void => {
            if (route.receiver === receiverName) {
              route.receiver = receiverPayload.name;
            }
            route.routes?.map(rename);
          };
          rename(payload.route);
        }
      } else {
        // in case of new receiver, just append it to the end
        payload.receivers.push(receiverPayload);
      }
    } else {
      // first receiver
      payload.receivers = [receiverPayload];
    }
    // add default route if none exists (empty config). Otherwise cannot save
    if (!payload.route) {
      payload.route = {
        receiver: receiverPayload.name,
      };
    }
    try {
      await updateConfig(payload);
      appEvents.emit(AppEvents.alertSuccess, [text.toast.successSavingReceiver]);
      setReceiverName(undefined);
    } catch (e) {
      if (isFetchError(e) && e.status === 400 && e.data?.message) {
        return setError('config', {
          type: 'manual',
          message: e.data.message.replace(ALERTMANAGER_CONFIG_ERROR_PREFIX, '').trim(),
        });
      }
      appEvents.emit(AppEvents.alertError, [text.toast.amConfigFailure]);
    }
  });

  const onSubmit = (e: React.BaseSyntheticEvent) => {
    trackEvent('Save Receiver');
    return handleSubmit(save.execute)(e);
  };

  const configValidator = useMemo(() => makeConfigValidator(config, receiverName), [config, receiverName]);

  return (
    <>
      <h1 className={styles.header}>{isNew ? text.receivers.newReceiverHeader : text.receivers.editReceiverHeader}</h1>
      {!config.route?.receiver && <InfoBox className={styles.infoBox}>{text.receivers.defaultReceiverInfo}</InfoBox>}
      <form onSubmit={onSubmit}>
        <Controller
          control={control}
          name="config"
          rules={{
            validate: configValidator,
          }}
          render={({ onChange, value }) => {
            return (
              <Field label="Receiver config" invalid={!!configErrorMessage} error={configErrorMessage}>
                <YMLCodeEditor
                  autoFocus={true}
                  initialCursorPosition={isNew ? 7 : undefined}
                  minHeight={128}
                  onChange={(newValue) => {
                    if (configErrorMessage && value !== newValue) {
                      clearErrors('config');
                    }
                    onChange(newValue);
                  }}
                  defaultValue={defaultValues.config}
                />
              </Field>
            );
          }}
        />

        <ButtonsContainer>
          <Button isLoading={save.loading} disabled={save.loading} type="submit">
            {text.receivers.submitReceiverButton}
          </Button>
          <Button disabled={save.loading} type="button" variant="secondary" onClick={onCancelClick}>
            {text.receivers.cancelEditingReceiverButton}
          </Button>
        </ButtonsContainer>
      </form>
    </>
  );
};
