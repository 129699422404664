import { Editor } from '@grafana/slate-react';
import { makeValue, SlatePrism, TextArea } from '@grafana/ui';
import { cx, css } from 'emotion';
import React, { useState, useEffect, useMemo, FC } from 'react';
import { Editor as CoreEditor, Value } from 'slate';
import { serializeSlateValue } from 'utils/misc';

type Props = {
  defaultValue: string;
  onChange: (value: string) => void;
  minHeight?: number;
  className?: string;
  initialCursorPosition?: number;
  readOnly?: boolean;
  autoFocus?: boolean;
};

const slatePlugins = [
  SlatePrism({
    getSyntax: () => 'yml',
  }),
];

const getStyle = ({ minHeight }: { minHeight?: number }) => css`
  ${minHeight ? `min-height: ${minHeight}px` : ''}
`;

const TextAreaEditor: FC<Props> = ({ autoFocus, className, minHeight, defaultValue, onChange }) => {
  const internalStyle = getStyle({ minHeight });
  return (
    <TextArea
      className={cx(internalStyle, className)}
      defaultValue={defaultValue}
      autoFocus={autoFocus}
      onChange={(e) => onChange(e.currentTarget.value)}
      rows={30}
    />
  );
};

function SlateEditor({
  autoFocus,
  className,
  minHeight,
  initialCursorPosition,
  onChange,
  readOnly,
  defaultValue,
}: Props) {
  const [editor, setEditor] = useState<CoreEditor | null>(null);
  useEffect(() => {
    if (editor) {
      setImmediate(() => {
        if (autoFocus !== false) {
          editor.focus();
        }
        if (initialCursorPosition) {
          editor.moveTo(initialCursorPosition);
        }
      });
    }
  }, [editor, autoFocus, initialCursorPosition]);

  const internalStyle = getStyle({ minHeight });

  const defaultSlateValue = useMemo(() => makeValue(defaultValue), [defaultValue]);

  return (
    <div className={cx(['slate-query-field__wrapper', className])}>
      <div className="slate-query-field" style={{ fontSize: 12 }}>
        <Editor
          key={defaultValue}
          className={internalStyle}
          autoCorrect={false}
          onChange={(change: { value: Value }) => onChange(serializeSlateValue(change.value))}
          plugins={slatePlugins}
          ref={setEditor}
          readOnly={readOnly}
          spellCheck={false}
          defaultValue={defaultSlateValue}
        />
      </div>
    </div>
  );
}

export const YMLCodeEditor: FC<Props> = (props) => {
  const { readOnly, defaultValue } = props;

  // slate gets super slow with big content. So if there's > 1000 chars, fall back to textarea
  const shouldRenderTextarea = !readOnly && defaultValue.length > 1000;

  if (shouldRenderTextarea) {
    return <TextAreaEditor {...props} />;
  }
  return <SlateEditor {...props} />;
};
