import { GrafanaTheme } from '@grafana/data';
import { css } from 'emotion';
import { borderRadius, fontSize, marginTop, padding, altBackgroundColor } from 'utils/styles';

export const getGlobalStyles = (theme: GrafanaTheme) => ({
  containerAppRoot: css`
    ${fontSize(theme).sm};
  `,
  containerCard: (highlightThisCard = false, withPadding = true) => css`
    position: relative;
    ${highlightThisCard && 'z-index: 10'};

    background-color: ${altBackgroundColor(theme)};
    ${borderRadius(theme).sm};

    ${marginTop(theme).md};
    ${withPadding && padding(theme).sm};

    /* If rendered as <li> */
    list-style-type: none;
  `,

  headerError: css`
    ${marginTop(theme).xl};
  `,
});
