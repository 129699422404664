import { useStyles } from '@grafana/ui';
import { css, cx } from 'emotion';
import React from 'react';
import { isAdmin } from 'utils/consts';
import { EditRulesButton } from './EditRulesButton';
import { getSharedStyles } from './styles/shared';

type Props = {
  isEditable: boolean;
  dataSourceName: string;
};

const styles = css`
  display: flex;
  justify-content: space-between;
`;

export function EmptyRuleList({ isEditable, dataSourceName }: Props) {
  const sharedStyles = useStyles(getSharedStyles);

  return (
    <div className={cx([sharedStyles.headerError, styles])}>
      <h5>No rules found.</h5>
      {isEditable && isAdmin && <EditRulesButton dataSourceName={dataSourceName} />}
    </div>
  );
}
