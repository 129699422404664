import { AlertmanagerDatasourcePicker } from 'components/DataSourcePicker';
import { DelayedSpinner } from 'components/DelayedSpinner';
import { DeprecationNotice } from 'components/DeprecationNotice';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ParsedCortexAlertmanagerConfig,
  useAlertmanagerConfig,
  useDefaultAlertmanagerConfig,
} from 'utils/alertmanager/hooks';
import { text } from 'utils/consts';
import { useSelectedAlertManagerName } from 'utils/hooks';
import { trackEvent } from 'utils/tracking';
import yaml from 'yaml';
import { GlobalConfigEditMode } from './GlobalConfigEditMode';
import { GlobalConfigReadMode } from './GlobalConfigReadMode';

export const GlobalConfig: FC = () => {
  const selectedAlertmanagerName = useSelectedAlertManagerName();
  const [isEditing, setIsEditing] = useState(false);

  const { configRequest, updateConfig, deleteConfig } = useAlertmanagerConfig();
  const defaultConfigRequest = useDefaultAlertmanagerConfig();
  const config = useMemo(() => {
    if (configRequest.status === 'success' && defaultConfigRequest.status === 'success') {
      const userConfig = configRequest.result;
      const isUserConfigEmpty = userConfig === undefined || (userConfig && Object.keys(userConfig.config).length === 0);

      return defaultConfigRequest.result && isUserConfigEmpty
        ? ({ config: yaml.parse(defaultConfigRequest.result), templates: {} } as ParsedCortexAlertmanagerConfig)
        : userConfig;
    }
    return undefined;
  }, [configRequest, defaultConfigRequest]);

  useEffect(() => trackEvent('View Global Config'), []);

  const onFinishEditing = useCallback(() => setIsEditing(false), []);

  return (
    <>
      <DeprecationNotice
        url={
          '/alerting/notifications' +
          (selectedAlertmanagerName ? `?alertmanager=${encodeURIComponent(selectedAlertmanagerName)}` : '')
        }
      />
      <AlertmanagerDatasourcePicker />
      {selectedAlertmanagerName &&
        (() => {
          if (configRequest.error || defaultConfigRequest.error) {
            return <h5>{text.error.alertmanagerConfigError}</h5>;
          } else if (configRequest.status !== 'success' || defaultConfigRequest.status !== 'success') {
            return <DelayedSpinner />;
          }
          return (
            <>
              {isEditing ? (
                <GlobalConfigEditMode
                  onFinishEditing={onFinishEditing}
                  alertmanagerConfig={config}
                  updateConfig={updateConfig}
                  deleteConfig={deleteConfig}
                />
              ) : (
                <GlobalConfigReadMode
                  onEdit={() => setIsEditing(true)}
                  alertmanagerConfig={config}
                  updateConfig={updateConfig}
                />
              )}
            </>
          );
        })()}
    </>
  );
};
