// See grafana: public/app/core/components/Select/DataSourcePicker.tsx

// Libraries
import { SelectableValue, DataSourceSelectItem } from '@grafana/data';
// Components
import { Select } from '@grafana/ui';
import { cx } from 'emotion';
import React, { useCallback, FC, useEffect } from 'react';
import { isAdmin } from 'utils/consts';
import { getAlertmanagerSelectItems, getRulesSourcesSelectItems } from 'utils/datasource';
import { QueryKey } from 'utils/enums';
import { useQueryParam } from 'utils/hooks';
import { trackEvent } from 'utils/tracking';
import { Link } from './Link';

export type DataSourcePickerProps = {
  datasourceNameQueryKey: QueryKey;
  datasources: DataSourceSelectItem[];

  disabled?: boolean;
  className?: string;
};

const DataSourcePicker: FC<DataSourcePickerProps> = ({ className, datasourceNameQueryKey, datasources, disabled }) => {
  const options = datasources.map((ds) => ({
    value: ds.name,
    label: ds.name,
    imgUrl: ds.meta.info.logos.small,
  }));

  const [selectedDataSourceName, setSelectedDataSourceName] = useQueryParam(
    datasourceNameQueryKey,
    datasources[0]?.name
  );

  // Use first available data source if query param value is invalid
  useEffect(() => {
    if (!options.find((option) => option.value === selectedDataSourceName) && datasources.length > 0) {
      setSelectedDataSourceName(datasources[0].name, true);
    }
  }, [datasources, options, selectedDataSourceName, setSelectedDataSourceName]);

  const onSelectChange = useCallback(
    (value: SelectableValue) => {
      trackEvent(`Select ${datasourceNameQueryKey} data source`);
      setSelectedDataSourceName(value.value);
    },
    [datasourceNameQueryKey, setSelectedDataSourceName]
  );

  return (
    <Select
      className={cx('ds-picker', 'select-container', className)}
      backspaceRemovesValue={false}
      disabled={disabled}
      onChange={onSelectChange}
      options={options}
      maxMenuHeight={500}
      noOptionsMessage="No data sources found"
      value={options && options.find((option) => option.value === selectedDataSourceName)}
    />
  );
};

export const AlertmanagerDatasourcePicker: FC<
  Omit<DataSourcePickerProps, 'datasourceNameQueryKey' | 'datasources'>
> = ({ className, disabled }) => {
  const datasources = getAlertmanagerSelectItems();
  if (!datasources.length) {
    if (isAdmin) {
      return (
        <h5>
          An Alertmanager data source is needed. Please add one <Link href="/datasources">here</Link>.
        </h5>
      );
    } else {
      return <h5>There are no Alertmanager data sources configured.</h5>;
    }
  } else if (datasources.length === 1) {
    return null;
  }

  return (
    <div data-testid="alertmanager-datasource-picker">
      <DataSourcePicker
        disabled={disabled}
        className={className}
        datasources={datasources}
        datasourceNameQueryKey={QueryKey.SelectedAlertmanagerName}
      />
      <hr />
    </div>
  );
};

export const RulesDatasourcePicker: FC<Omit<DataSourcePickerProps, 'datasourceNameQueryKey' | 'datasources'>> = ({
  className,
  disabled,
}) => {
  return (
    <div data-testid="rules-datasource-picker">
      <DataSourcePicker
        disabled={disabled}
        className={className}
        datasources={getRulesSourcesSelectItems()}
        datasourceNameQueryKey={QueryKey.SelectedRulesSourceName}
      />
      <hr />
    </div>
  );
};
