import { useStyles } from '@grafana/ui';
import React, { FC } from 'react';
import { AlertingRuleYML, RecordingRuleYML } from 'types/rulesExternal';
import { getRuleHeaderStyles } from '../styles/ruleHeader';

export type Props = {
  rule: AlertingRuleYML | RecordingRuleYML;
  hideName?: boolean;
};

export const EditRuleHeader: FC<Props> = ({ rule, children, hideName }) => {
  const styles = useStyles(getRuleHeaderStyles);

  return (
    <div className={styles.containerHeader(true)}>
      <div className={styles.containerLeft}>
        {!hideName && <div className={styles.containerAlertName}>{'alert' in rule ? rule.alert : rule.record}</div>}
      </div>
      {children}
    </div>
  );
};
