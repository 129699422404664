import { GrafanaTheme } from '@grafana/data';
import { useStyles } from '@grafana/ui';
import { css } from 'emotion';
import React, { FC } from 'react';
import { marginTop, marginBottom, fontSize } from 'utils/styles';

const getStyles = (theme: GrafanaTheme) => ({
  fieldset: css`
    ${marginTop(theme).md};
    ${marginBottom(theme).sm};
  `,
  legend: css`
    ${fontSize(theme).md};
    ${marginBottom(theme).sm};
  `,
});

type Props = {
  header: string;
};

export const FormSection: FC<Props> = ({ header, children }) => {
  const styles = useStyles(getStyles);
  return (
    <fieldset className={styles.fieldset}>
      <legend className={styles.legend}>{header}</legend>
      {children}
    </fieldset>
  );
};
