import { useMemo } from 'react';
import { UseAsyncReturn } from 'react-async-hook';
import { AlertmanagerConfig, Route } from 'types/alertmanager';
import { isFetchError } from 'utils/api';
import { text } from 'utils/consts';
import { useAsyncCallbackWithFeedback } from 'utils/hooks';

export type ReceiverLabels = Record<string, string[][]>;

/*
Returns a map  `receiver name: list of lists of label pairs` that the receiver is configured to alert on.

Does NOT handle more complicated stuff, like "matches X and Y, but NOT Z" which is possible to acheieve with routes config..
*/
export function useReceiverLabels(config: AlertmanagerConfig): ReceiverLabels {
  return useMemo(() => {
    const result: ReceiverLabels = {};

    function processRoute(route: Route, labels: string[], depth: number) {
      if (route.match) {
        labels = [...labels, ...Object.entries(route.match).map(([k, v]) => `${k}=${v}`)];
      }
      if (route.match_re) {
        labels = [...labels, ...Object.entries(route.match_re).map(([k, v]) => `${k}=${v}`)];
      }
      if (route.receiver) {
        if (labels.length) {
          result[route.receiver] = [...(result[route.receiver] || []), labels];
        } else if (depth === 0) {
          result[route.receiver] = [...(result[route.receiver] || []), ['All (default alert)']];
        }
      }
      route.routes?.forEach((r) => processRoute(r, labels, depth + 1));
    }

    if (config.route) {
      processRoute(config.route, [], 0);
    }
    return result;
  }, [config]);
}

export function useDeleteReceiverRequest(
  config: AlertmanagerConfig,
  updateConfig: (payload: AlertmanagerConfig) => Promise<void>
): UseAsyncReturn<void, [string]> {
  return useAsyncCallbackWithFeedback(
    async (receiverName: string) => {
      const removeReceiverFromRoute = (route: Route): Route => {
        const copy = { ...route };
        if (copy.receiver === receiverName) {
          delete copy.receiver;
        }
        if (copy.routes) {
          copy.routes = copy.routes.map(removeReceiverFromRoute);
        }
        return copy;
      };
      const payload: AlertmanagerConfig = { ...config };
      if (payload.receivers) {
        payload.receivers = payload.receivers.filter((receiver) => receiver.name !== receiverName);
      }
      if (payload.route) {
        payload.route = removeReceiverFromRoute(payload.route);
      }
      return updateConfig(payload);
    },
    {
      successMessage: text.toast.successDeletingReceiver,
      errorMessage: (err) => {
        if (isFetchError(err) && err.status === 400 && err.data.message) {
          return err.data.message;
        }
        return text.toast.errorDeletingReceiver;
      },
    }
  );
}
