import { GrafanaTheme } from '@grafana/data';
import { Spinner, useStyles } from '@grafana/ui';
import { css, cx } from 'emotion';
import React, { FC } from 'react';
import { getGlobalStyles } from 'styles';
import { padding } from 'utils/styles';

const getStyles = (theme: GrafanaTheme) => ({
  loadingContainer: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `,
  loadingLightbox: css`
    opacity: 0.4;
    background-color: ${theme.isDark ? 'black' : 'white'};
  `,
  loadingSpinner: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  innerContainer: css`
    ${padding(theme).sm};
  `,
});

export type EditRuleContainerProps = {
  loading?: boolean;
  highlight?: boolean;
};

export const EditRuleContainer: FC<EditRuleContainerProps> & { Inner: FC } = ({ loading, highlight, children }) => {
  const globalStyles = useStyles(getGlobalStyles);
  const styles = useStyles(getStyles);

  return (
    <div
      className={globalStyles.containerCard(highlight, false)}
      style={{ position: 'relative' }}
      data-testid="edit-rule"
    >
      {children}
      {loading && (
        <>
          <div className={cx([styles.loadingContainer, styles.loadingLightbox])} />
          <div className={cx([styles.loadingContainer, styles.loadingSpinner])}>
            <Spinner />
          </div>
        </>
      )}
    </div>
  );
};

export const EditRuleInnerContainer: FC = ({ children }) => {
  const styles = useStyles(getStyles);
  return <div className={styles.innerContainer}>{children}</div>;
};

EditRuleContainer.Inner = EditRuleInnerContainer;
