import isEmpty from 'lodash/isEmpty';
import { Rules, RuleGroup, BaseRuleGroup, BaseRules } from 'types/rulesInternal';
import yaml from 'yaml';
import {
  AlertingRulePayload,
  RecordingRulePayload,
  AlertingRuleYML,
  RecordingRuleYML,
  PromRulesGroupPayload,
  RuleGroupYML,
} from '../../../types/rulesExternal';
import { RuleType, AlertingRuleState } from '../../../utils/enums';

type AlertStats = {
  [alertState in AlertingRuleState]: number;
};

function initEmptyStatsObject() {
  return {
    [AlertingRuleState.Firing]: 0,
    [AlertingRuleState.Inactive]: 0,
    [AlertingRuleState.Pending]: 0,
  };
}

export function getRuleGroupStats(ruleGroup: RuleGroup) {
  const stats: AlertStats = initEmptyStatsObject();

  for (const rule of ruleGroup.rules) {
    if (rule.type === RuleType.Alerting) {
      if (stats.hasOwnProperty(rule.state)) {
        stats[rule.state]!++;
      } else {
        console.error(`Unknown rule state ${rule.state}.`);
      }
    }
  }

  return stats;
}

export function getAlertsStats(rules: Rules) {
  const stats: AlertStats = initEmptyStatsObject();

  for (const namespace of Object.keys(rules)) {
    const groups = rules[namespace];
    for (const group of groups) {
      const groupStats = getRuleGroupStats(group);
      for (const key of Object.keys(groupStats) as AlertingRuleState[]) {
        stats[key] += groupStats[key];
      }
    }
  }

  return stats;
}

export function transformGroupToYml(group: RuleGroup): RuleGroupYML {
  const rules: Array<AlertingRuleYML | RecordingRuleYML> = [];

  for (const rule of group.rules) {
    if (rule.type === RuleType.Alerting) {
      rules.push({
        alert: rule.name,
        expr: rule.query,
        annotations: rule.annotations,
        for: rule.duration !== undefined ? rule.duration + 's' : undefined,
        labels: rule.labels,
      });
    } else {
      rules.push({
        record: rule.name,
        expr: rule.query,
        labels: rule.labels,
      });
    }
  }

  return {
    name: group.name,
    interval: group.interval !== undefined ? group.interval + 's' : undefined,
    rules,
  };
}

export function transformPrometheusPayload(payload: PromRulesGroupPayload[]): Rules {
  const rules: Rules = {};

  for (const group of payload) {
    if (!rules.hasOwnProperty(group.file)) {
      rules[group.file] = [];
    }

    const transformedGroup: RuleGroup = {
      name: group.name,
      interval: group.interval,
      rules: [],
    };

    for (const rule of group.rules) {
      const definition = yamlifyRule(rule);

      transformedGroup.rules.push({
        ...rule,
        definition,
      });
    }

    rules[group.file].push(transformedGroup);
  }

  // Sort by both namespace and group, so that list
  // remains stable when rules are deleted, rearranged, etc.
  // (We receive them from the Ruler API in random order)
  return sortRuleGroups(rules);
}

export function yamlifyRule(rule: AlertingRulePayload | RecordingRulePayload): string {
  if (rule.type === RuleType.Alerting) {
    const { annotations, duration, labels, name, query } = rule;

    const ymlObject: AlertingRuleYML = {
      alert: name,
      expr: query,
      ...(duration && {
        // TODO: Duration may be defined as m/h or something but Prometheus always provides it in seconds
        //    And so our visualization may not match what's in the config.
        for: duration + 's',
      }),
      ...(!isEmpty(labels) && { labels }),
      ...(!isEmpty(annotations) && { annotations }),
    };

    return yaml.stringify(ymlObject);
  } else if (rule.type === RuleType.Recording) {
    const { labels, name, query } = rule;

    const ymlObject: RecordingRuleYML = {
      record: name,
      expr: query,
      ...(!isEmpty(labels) && { labels }),
    };

    return yaml.stringify(ymlObject);
  }

  throw new Error('This rule is not of a known type:' + rule);
}

// will sort groups alphabetically
export function sortRuleGroups<G extends BaseRuleGroup>(payload: BaseRules<G>) {
  const sortedRules: BaseRules<G> = {};
  for (const namespace of Object.keys(payload).sort()) {
    sortedRules[namespace] = payload[namespace].slice().sort((a, b) => a.name.localeCompare(b.name));
  }
  return sortedRules;
}
