import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { SilencePayload } from 'types/silencesExternal';
import { SilenceData } from 'types/silencesInternal';
import { text } from 'utils/consts';
import { httpStatusToError } from 'utils/datasource';
import { HttpError } from 'utils/enums';
import { fetchSilencesThunk } from './thunks';

export type SilencesState = {
  dataSourceError?: string;
  editorDefaultValues?: Partial<SilencePayload>;
  silences?: SilenceData[];
};

export const initialState: SilencesState = {};

export const silencesSlice = createSlice({
  name: 'silences',
  initialState,
  reducers: {
    removeEditorDefaultValues: (state) => {
      delete state.editorDefaultValues;
    },
    setEditorDefaultValues: (state, { payload }: PayloadAction<SilencesState['editorDefaultValues']>) => {
      state.editorDefaultValues = cloneDeep(payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSilencesThunk.fulfilled, (state, { payload }) => {
      state.silences = payload;
      delete state.dataSourceError;
    });
    builder.addCase(fetchSilencesThunk.rejected, (state, { payload }: any) => {
      delete state.silences;
      const error = httpStatusToError(payload?.status);
      if (
        error === HttpError.NotFound &&
        payload?.data?.message.match(/the Alertmanager is not configured/i) !== null
      ) {
        state.dataSourceError = text.error.alertmanagerNoConfig;
      } else {
        state.dataSourceError = text.httpErrors[error];
      }
    });
  },
});

export const { actions: silencesActions } = silencesSlice;
