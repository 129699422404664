import { DataSourceSettings } from '@grafana/data';
import { getBackendSrv, FetchError } from '@grafana/runtime';

export async function fetchDataSourceSettings(sourceId: number): Promise<DataSourceSettings> {
  return getBackendSrv().get(`/api/datasources/${sourceId}`);
}

export function isFetchError(obj: unknown): obj is FetchError {
  return !!(obj && typeof obj === 'object' && 'status' in obj && 'data' in obj);
}
