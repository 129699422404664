import { Button } from 'components/Button';
import { CardContainer } from 'components/CardContainer';
import { YMLCode } from 'components/YMLCode';
import React, { FC, useMemo } from 'react';
import { ParsedCortexAlertmanagerConfig, UpdateConfigFn } from 'utils/alertmanager/hooks';
import { isAdmin } from 'utils/consts';
import { trackEvent } from 'utils/tracking';
import yaml from 'yaml';
import { FormSection } from './FormSection';
import { GlobalConfigHeader } from './GlobalConfigHeader';
import { GrafanaSmtpConfig } from './GrafanaSMTPConfig';

export type GlobalConfigReadModeProps = {
  onEdit: () => void;
  updateConfig: UpdateConfigFn;

  alertmanagerConfig?: ParsedCortexAlertmanagerConfig;
};

export const GlobalConfigReadMode: FC<GlobalConfigReadModeProps> = ({ updateConfig, alertmanagerConfig, onEdit }) => {
  const templateFiles = alertmanagerConfig?.templates;
  const templates = useMemo(
    () =>
      Object.entries(templateFiles || {})
        .map(([name, content]) => ({ name, content }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [templateFiles]
  );

  const configValue = useMemo(() => (alertmanagerConfig ? yaml.stringify(alertmanagerConfig.config) : ''), [
    alertmanagerConfig,
  ]);

  const onEditClick = () => {
    trackEvent('Click edit global config');
    onEdit();
  };

  return (
    <>
      {isAdmin && (
        <>
          <GrafanaSmtpConfig updateConfig={updateConfig} alertmanagerConfig={alertmanagerConfig} />
          <GlobalConfigHeader>
            <Button type="button" onClick={onEditClick}>
              Edit config
            </Button>
          </GlobalConfigHeader>
        </>
      )}
      <FormSection header="Config">
        <YMLCode value={configValue} />
      </FormSection>
      {!!templates?.length && (
        <FormSection header="Templates">
          {templates.map(({ name, content }) => (
            <CardContainer key={name} data-testid={`template-read-${name}`}>
              <p>{name}</p>
              <pre>{content}</pre>
            </CardContainer>
          ))}
        </FormSection>
      )}
    </>
  );
};
