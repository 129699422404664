import { GrafanaTheme } from '@grafana/data';
import { useStyles } from '@grafana/ui';
import { css } from 'emotion';
import React, { ReactNode } from 'react';
import { AlertingRuleState, Status, Color } from 'utils/enums';

type Props = {
  children: ReactNode;
  status: AlertingRuleState | Status;
};

const StatusToColor = {
  [AlertingRuleState.Firing]: 'red',
  [AlertingRuleState.Inactive]: 'green',
  [AlertingRuleState.Pending]: 'orange',
  [Status.Error]: 'red',
  [Status.OK]: 'green',
} as const;

const getStyles = (theme: GrafanaTheme) => ({
  green: css`
    color: ${theme.isDark ? Color.GreenLightest : Color.GreenDarkest};
  `,
  orange: css`
    color: ${theme.isDark ? Color.OrangeLightest : Color.OrangeDarkest};
  `,
  red: css`
    color: ${theme.isDark ? Color.RedLightest : Color.RedDarkest};
  `,
});

export function StatusColoredText({ children, status }: Props) {
  const styles = useStyles(getStyles);

  return <span className={styles[StatusToColor[status]]}>{children}</span>;
}
