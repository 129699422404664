import { DateTime, dateTime } from '@grafana/data';
import { Field, TimeRangeInput } from '@grafana/ui';
import React, { FC } from 'react';
import { useController, UseFormMethods } from 'react-hook-form';
import { SilenceCreationPayload } from 'types/silencesExternal';

type Props = Pick<UseFormMethods<SilenceCreationPayload>, 'control'>;

const compareStartsEndsAt = (startsAt: string | DateTime, endsAt: string | DateTime) => {
  if (typeof startsAt === 'string') {
    startsAt = dateTime(startsAt)!;
  }

  if (typeof endsAt === 'string') {
    endsAt = dateTime(endsAt)!;
  }

  return startsAt < endsAt;
};

export const SilencePeriod: FC<Props> = ({ control }) => {
  const {
    field: { onChange: onChangeStartsAt, value: startsAt },
    meta: { invalid: startsAtInvalid },
  } = useController({
    name: 'startsAt',
    control,
    rules: {
      validate: (value) => compareStartsEndsAt(value, control.getValues().endsAt),
    },
  });

  const {
    field: { onChange: onChangeEndsAt, value: endsAt },
    meta: { invalid: endsAtInvalid },
  } = useController({
    name: 'endsAt',
    control,
    rules: {
      validate: (value) => compareStartsEndsAt(control.getValues().startsAt, value),
    },
  });

  const {
    field: { onChange: onChangeTimeZone, value: timeZone },
  } = useController({
    name: 'timeZone',
    control,
  });

  const invalid = startsAtInvalid || endsAtInvalid;

  const from = dateTime(startsAt);
  const to = dateTime(endsAt);

  return (
    <Field label="Silence period" error={invalid ? 'To is before or the same as from' : ''} invalid={invalid}>
      <TimeRangeInput
        value={{
          from,
          to,
          raw: {
            from,
            to,
          },
        }}
        timeZone={timeZone}
        onChange={(newValue) => {
          onChangeStartsAt(dateTime(newValue.from));
          onChangeEndsAt(dateTime(newValue.to));
        }}
        onChangeTimeZone={(newValue) => onChangeTimeZone(newValue)}
        hideTimeZone={false}
        hideQuickRanges={true}
      />
    </Field>
  );
};
