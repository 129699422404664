import { config } from '@grafana/runtime';
import { Alert, LinkButton } from '@grafana/ui';
import React from 'react';
import { isNgAlertingEnabled } from '../utils/misc';

interface Props {
  url: string;
}

export const DeprecationNotice = ({ url }: Props): React.ReactElement => {
  if (isNgAlertingEnabled()) {
    return (
      <Alert severity="warning" title="Grafana Cloud Alerting UI is going away soon">
        <p>
          The new Grafana Alerting is now available! Classic Grafana Cloud Alerting UI will be deprecated. The new
          alerting framework has all of the features you're used to but with an upgraded interface and many more
          improvements. Give it a try today and remember to update your bookmarks!
        </p>
        <p>
          Head to our docs to{' '}
          <a className="external-link" href="https://grafana.com/docs/grafana/latest/alerting/unified-alerting/">
            learn more about the new alerting
          </a>
          .
        </p>
        <LinkButton href={config.appSubUrl + url}>Go to Grafana 8 Alerting</LinkButton>
      </Alert>
    );
  }
  return <></>;
};
