import { AppEvents } from '@grafana/data';
import { EditableLabel } from 'components/EditableLabel';
import appEvents from 'grafana/app/core/app_events';
import React, { FC, useEffect, useCallback } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useDispatch } from 'react-redux';
import { useReduxSelector } from 'store';
import { rulesActions } from 'store/rules';
import { renameGroup } from 'store/rules/thunks';
import { useDispatchWithFeedback } from 'utils/hooks';
import { trackEvent } from 'utils/tracking';
import { rulesText } from './utils/consts';

type EditableGroupNameProps = {
  dataSourceName: string;
  groupName: string;
  groupIndex: number;
  namespace: string;
};

export const EditableGroupLabel: FC<EditableGroupNameProps> = ({
  groupName,
  groupIndex,
  namespace,
  dataSourceName,
}) => {
  const isEditing = useReduxSelector(
    (state) =>
      state.rules.editGroupNameLocation?.groupName === groupName &&
      state.rules.editGroupNameLocation.namespace === namespace
  );
  const existingGroupNames = useReduxSelector(
    (state) => state.rules.rulesConfig?.[namespace].map((group) => group.name) ?? []
  );
  const isNewGroup = useReduxSelector((state) => !!state.rules.editGroupNameLocation?.isNewGroup);
  const dispatchWithFeedback = useDispatchWithFeedback();
  const dispatch = useDispatch();

  const save = useAsyncCallback((newGroupName: string) => {
    trackEvent('Click save updated group name');
    if (newGroupName !== groupName) {
      // We could just disable the submit button. but imho it's better ux to show informative error
      if (existingGroupNames.includes(newGroupName)) {
        const msg = `Group "${newGroupName}" already exists.`;
        appEvents.emit(AppEvents.alertError, [msg]);
        return Promise.reject(new Error(msg));
      } else {
        dispatch(rulesActions.changeNewlyCreatedGroupName({ namespace, groupName, newName: newGroupName }));
        return dispatchWithFeedback(
          renameGroup({ groupIndex, newGroupName, namespace, dataSourceName }),
          rulesText.dispatchToasts
        );
      }
    }
    return {} as unknown;
  });

  const setIsEditing = useCallback(
    (isEditing: boolean) => {
      dispatch(rulesActions.setEditGroupNameLocation(isEditing ? { namespace, groupName } : undefined));
    },
    [dispatch, groupName, namespace]
  );

  useEffect(() => {
    if (save.status === 'success') {
      setIsEditing(false);
    }
  }, [save.status, setIsEditing]);

  const onStartEditing = () => {
    trackEvent('Click edit group name');
    setIsEditing(true);
  };

  return (
    <EditableLabel
      text={groupName}
      isEditing={isEditing}
      isSaving={save.loading}
      onStartEditing={onStartEditing}
      onStopEditing={() => setIsEditing(false)}
      editIconTitle="Edit group name"
      disableSaveWhenUnchanged={!isNewGroup}
      onSave={(groupName) => save.execute(groupName.trim())}
    />
  );
};
