import { AlertmanagerDatasourcePicker } from 'components/DataSourcePicker';
import { DelayedSpinner } from 'components/DelayedSpinner';
import React, { FC, useEffect } from 'react';
import { useAlertmanagerConfig } from 'utils/alertmanager/hooks';
import { QueryKey } from 'utils/enums';
import { useQueryParam } from 'utils/hooks';
import { trackEvent } from 'utils/tracking';
import { ReceiverRoutes } from './ReceiversRoutes';

export const ReceiversIndex: FC = () => {
  useEffect(() => trackEvent('View Receivers'), []);

  const { configRequest, updateConfig } = useAlertmanagerConfig();

  const [receiverName] = useQueryParam(QueryKey.ReceiversReceiverName);

  return (
    <>
      <AlertmanagerDatasourcePicker disabled={!!receiverName} />
      {configRequest.loading && <DelayedSpinner />}
      {configRequest.error && <h5>Unable to fetch Alertmanager configuration due to an unexpected error.</h5>}
      {configRequest.status === 'success' && (
        <ReceiverRoutes updateConfig={updateConfig} config={configRequest.result?.config || {}} />
      )}
    </>
  );
};
