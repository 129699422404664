import { Spinner, useTheme } from '@grafana/ui';
import { css } from 'emotion';
import React from 'react';
import { padding } from 'utils/styles';

/**
 * Delayed by: 750 ms
 * Animates to be visible in: 250 ms
 */
export function DelayedSpinner() {
  const theme = useTheme();
  return (
    <Spinner
      className={css`
        @keyframes delayVisibility {
          0% {
            opacity: 0;
            animation-timing-function: linear;
          }
          75% {
            opacity: 0;
            animation-timing-function: ease;
          }
          100% {
            opacity: 1;
          }
        }

        animation-name: delayVisibility;
        animation-duration: 1s;
        animation-fill-mode: both;

        display: flex;
        justify-content: center;
        align-items: center;

        ${padding(theme).md};
      `}
    />
  );
}
