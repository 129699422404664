import { Button, Icon, Tag, Tooltip, useStyles } from '@grafana/ui';
import { StatusColoredText } from 'components/StatusColoredText';
import { TimeDistanceToNow } from 'components/TimeDistanceToNow';
import React, { FC, ReactNode } from 'react';
import { AlertingRule, RecordingRule } from 'types/rulesInternal';
import { isViewer } from 'utils/consts';
import { AlertingRuleState, RuleType, Status } from 'utils/enums';
import { useSelectedRulesSourceName } from 'utils/hooks';
import { createExploreLink, formatDuration } from 'utils/misc';
import { getRuleHeaderStyles } from './styles/ruleHeader';

export type Props = {
  isCollapsed: boolean;
  onToggleCollapse: () => void;
  rule: AlertingRule | RecordingRule;
};

export const RuleHeader: FC<Props> = ({ isCollapsed, onToggleCollapse, rule }) => {
  const { health, name, lastError, lastEvaluation, evaluationTime, query } = rule;

  const dataSourceName = useSelectedRulesSourceName();

  const styles = useStyles(getRuleHeaderStyles);

  let alertInfo: ReactNode, collapseToggle: ReactNode;
  if (rule.type === RuleType.Alerting) {
    const { state, alerts } = rule;

    alertInfo = (
      <>
        ({alerts.length} active)&nbsp;
        {state === AlertingRuleState.Firing ? (
          <Tag name="firing" colorIndex={15} />
        ) : state === AlertingRuleState.Pending ? (
          <Tag name="pending" colorIndex={7} />
        ) : null}
      </>
    );

    collapseToggle = alerts.length > 0 && (
      <Icon
        className={styles.collapseToggle}
        data-testid="toggle-annotations-button"
        size="xl"
        name={isCollapsed ? 'angle-right' : 'angle-down'}
        onClick={onToggleCollapse}
      />
    );
  }

  const evaluationError = lastError && (
    <div className={styles.evaluationError}>
      <StatusColoredText status={Status.Error}>
        <Tooltip content={lastError} placement="right">
          <Icon name="exclamation-triangle" />
        </Tooltip>
      </StatusColoredText>
    </div>
  );

  const evaluationInfo = health !== 'unknown' && lastEvaluation && evaluationTime && (
    <>
      Last evaluation: <TimeDistanceToNow date={new Date(lastEvaluation)} /> ago, evaluation time:{' '}
      {formatDuration(evaluationTime)}
    </>
  );

  const viewInExploreLink = dataSourceName ? createExploreLink(dataSourceName, query) : '';
  const rightContent = (
    <>
      <a href={viewInExploreLink} target="_blank">
        <Button variant="secondary" icon="compass">
          View in Explore
        </Button>
      </a>
    </>
  );

  return (
    <div className={styles.containerHeader(false)} data-testid="rule-header">
      <div className={styles.containerLeft}>
        <div className={styles.containerAlertName}>
          {collapseToggle} {name} {alertInfo}
        </div>
        <div>
          {evaluationError}
          {evaluationInfo}
        </div>
      </div>
      {!isViewer && <div className={styles.containerRight}>{rightContent}</div>}
    </div>
  );
};
