import { AppEvents } from '@grafana/data';
import { useStyles, useTheme } from '@grafana/ui';
import { DelayedSpinner } from 'components/DelayedSpinner';
import appEvents from 'grafana/app/core/app_events';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch, useReduxSelector } from 'store';
import { rulesActions, fetchRulesConfig, EditRuleLocation } from 'store/rules';
import { RuleGroupYML } from 'types/rulesExternal';
import { RulesConfig } from 'types/rulesInternal';
import { text } from 'utils/consts';
import { assertIsDefined } from 'utils/misc';
import { trackEvent } from 'utils/tracking';
import { AddRuleButton } from './AddRuleButton';
import { EditableGroupLabel } from './EditableGroupName';
import { EditOptions } from './EditOptions';
import { EditRule } from './EditRule/EditRule';
import { NewGroupTag } from './NewGroupTag';
import { getRuleListStyles } from './styles/ruleList';

export type RuleLocation = {
  groupIndex: number;
  namespace: string;
  ruleIndex: number;
};

export type RuleMeta = {
  isEditing: boolean;
  isNewGroup: boolean;
  isNewRule: boolean;
  groupName: string;
  location: RuleLocation;
  isFirstInGroup: boolean;
  isLastInGroup: boolean;
  isOnlyRule: boolean;
};

const generateRuleMeta = (
  ruleConfig: RulesConfig,
  location: RuleLocation,
  editRuleLocation?: EditRuleLocation
): RuleMeta => {
  const isEditing =
    location.namespace === editRuleLocation?.namespace &&
    location.groupIndex === editRuleLocation.groupIndex &&
    location.ruleIndex === editRuleLocation.ruleIndex;
  const group = assertIsDefined(ruleConfig[location.namespace][location.groupIndex]);
  return {
    isEditing,
    isNewGroup: isEditing && !!editRuleLocation?.isNewGroup,
    isNewRule: isEditing && !!editRuleLocation?.isNewRule,
    groupName: ruleConfig[location.namespace][location.groupIndex].name,
    location,
    isFirstInGroup: location.ruleIndex === 0,
    isLastInGroup: location.ruleIndex === group.rules.length - 1,
    isOnlyRule: Object.values(ruleConfig).flat().length === 1 && group.rules.length === 1,
  };
};

function generateRuleGroupStatsText(group: RuleGroupYML) {
  return (
    <div>
      {group.rules.length} rule{group.rules.length === 1 ? '' : 's'}.
    </div>
  );
}

interface Props {
  dataSourceName: string;
}

export function EditRuleList({ dataSourceName }: Props) {
  const dispatch: AppDispatch = useDispatch();

  const [isFetchRulesDone, setIsFetchRulesDone] = useState(false);

  const editRuleLocation = useReduxSelector((state) => state.rules.editRuleLocation);
  const isWriteInProgress = useReduxSelector((state) => state.rules.isWriteInProgress);
  const rules = useReduxSelector((state) => state.rules.rulesConfig);
  const recentlyCreatedGroupNames = useReduxSelector((state) => state.rules.newlyCreatedGroupNames);

  const styles = useStyles(getRuleListStyles);

  const theme = useTheme();

  useEffect(() => {
    let isCanceled = false;

    dispatch(fetchRulesConfig({ dataSourceName }))
      .then((action) => {
        // Errors from grafana backend end up in payload, splitting into
        // success and failure with `unwrapResult` doesn't work, since its
        // serialization would swallow all error info.
        if (action.type === fetchRulesConfig.rejected.type) {
          const payload = action.payload as any;
          if (payload instanceof Error) {
            throw payload;
          }

          // An error of 404 is fine, since that just means that there's no rule
          // yet.
          if (payload.status && payload.status !== 404) {
            appEvents.emit(AppEvents.alertError, [text.toast.errorGeneric]);
            dispatch(rulesActions.setIsEditingRules(false));
            throw payload;
          }
        }

        if (!isCanceled) {
          setIsFetchRulesDone(true);
        }
      })
      .catch(() => {});

    return () => {
      isCanceled = true;
    };
  }, [dispatch, dataSourceName]);

  // close group name editing if user leaves editing view
  useEffect(
    () => () => {
      dispatch(rulesActions.setEditGroupNameLocation(undefined));
    },
    [dispatch]
  );

  const addRule = useCallback(
    (namespace: string, groupIndex: number) => {
      trackEvent('Click add rule');
      dispatch(rulesActions.addRule({ namespace, groupIndex, ruleIndex: 0 }));
    },
    [dispatch]
  );

  const addGroup = useCallback(() => {
    trackEvent('Click add rule');
    dispatch(rulesActions.addGroup());
  }, [dispatch]);

  const items = useMemo(() => {
    if (!rules || !Object.keys(rules).length) {
      return null;
    } else {
      const namespaces: JSX.Element[] = [];

      for (const namespace of Object.keys(rules)) {
        const ruleGroups = rules[namespace];

        const groupComponents: JSX.Element[] = [];
        for (let groupIndex = 0; groupIndex < ruleGroups.length; groupIndex++) {
          const group = ruleGroups[groupIndex];

          const ruleComponents: JSX.Element[] = [];

          // we wanna generate keys that don't change when rule is moved, so can't use index.
          // let's use rule name. but there can be multiple rules with the same name,
          // so we gotta keep track of how many were encountered to generate unique keys
          // it will be janky if there's 2 adjacent rules with the same name and you move them,
          // but will look good in general case
          const ruleNameCounts: Record<string, number> = {};
          for (let ruleIndex = 0; ruleIndex < group.rules.length; ruleIndex++) {
            const rule = group.rules[ruleIndex];
            const ruleMeta = generateRuleMeta(rules, { namespace, groupIndex, ruleIndex }, editRuleLocation);
            const ruleName = ruleMeta.isNewRule
              ? '$new$'
              : ruleMeta.isEditing
              ? '$editing$'
              : 'alert' in rule
              ? rule.alert
              : rule.record;
            const nameCount = (ruleNameCounts[ruleName] ?? 0) + 1;
            ruleNameCounts[ruleName] = nameCount;
            const key = `${ruleName}-${nameCount}`;

            ruleComponents.push(
              <EditRule
                key={key}
                dataSourceName={dataSourceName}
                isWriteInProgress={isWriteInProgress}
                ruleMeta={ruleMeta}
                rule={rule}
              />
            );
          }

          const isEditingNewGroup =
            editRuleLocation &&
            editRuleLocation.isNewGroup &&
            editRuleLocation.namespace === namespace &&
            groupIndex === editRuleLocation.groupIndex;

          // group name can change during editing for new group rule, so use $new$ to prevent re-mounting and wiping component state
          const key = isEditingNewGroup ? '$new$' : group.name;
          const isGroupNew = !!(group.name && recentlyCreatedGroupNames[namespace]?.includes(group.name));

          const groupComponent = (
            <div key={key} className={styles.containerRuleGroupEdit} data-testid="edit-group">
              {group.name && !isEditingNewGroup && (
                <>
                  <div className={styles.containerHeaderRuleGroupEdit} data-testid="edit-group-header">
                    <div>
                      <h6 className={styles.headerRuleGroup}>
                        {namespace} &gt;{' '}
                        <EditableGroupLabel
                          dataSourceName={dataSourceName}
                          groupIndex={groupIndex}
                          groupName={group.name}
                          namespace={namespace}
                        />{' '}
                        {isGroupNew && <NewGroupTag />}
                      </h6>
                      {generateRuleGroupStatsText(group)}
                    </div>
                    <AddRuleButton onClick={() => addRule(namespace, groupIndex)} />
                  </div>
                  <hr className={styles.hr} />
                </>
              )}
              {ruleComponents}
            </div>
          );

          groupComponents.push(groupComponent);
        }

        // Group by namespace to stay flexible if namespace level
        // things will be required.
        namespaces.push(
          <div className={styles.containerNamespace} key={namespace}>
            {groupComponents}
          </div>
        );
      }

      return namespaces;
    }
  }, [
    rules,
    styles.containerNamespace,
    styles.containerRuleGroupEdit,
    styles.containerHeaderRuleGroupEdit,
    styles.headerRuleGroup,
    styles.hr,
    editRuleLocation,
    isWriteInProgress,
    addRule,
    recentlyCreatedGroupNames,
    dataSourceName,
  ]);

  return !isFetchRulesDone || items ? (
    <>
      <EditOptions isFetchRulesDone={isFetchRulesDone} showAddGroupButton />
      {(isFetchRulesDone && items) || <DelayedSpinner />}
    </>
  ) : (
    <div>
      <EditOptions isFetchRulesDone={isFetchRulesDone} />
      <p style={{ fontSize: theme.typography.size.base }}>You have not added any rules yet.</p>
      <AddRuleButton onClick={addGroup} />
    </div>
  );
}
