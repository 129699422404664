import { DataSourceSettings } from '@grafana/data';
import { useStyles } from '@grafana/ui';
import { RulesDatasourcePicker } from 'components/DataSourcePicker';
import { DelayedSpinner } from 'components/DelayedSpinner';
import { DeprecationNotice } from 'components/DeprecationNotice';
import { Lightbox } from 'components/Lightbox';
import 'prismjs/components/prism-yaml';
import React, { useEffect, useState, FC } from 'react';
import { useDispatch } from 'react-redux';
import { useReduxSelector } from 'store';
import { rulesActions, fetchRules } from 'store/rules';
import { fetchDataSourceSettings } from 'utils/api';
import { intervals, isAdmin, text } from 'utils/consts';
import { getAlertingSources, httpStatusToError, getDatasourceByName } from 'utils/datasource';
import { useSelectedRulesSourceName } from 'utils/hooks';
import { assertIsDefined } from 'utils/misc';
import { trackEvent } from 'utils/tracking';
import { EditRuleList } from './EditRuleList';
import { EmptyRuleList } from './EmptyRuleList';
import { RuleList } from './RuleList';
import { getSharedStyles } from './styles/shared';
import * as api from './utils/api';

enum DataSourceEditable {
  Yes,
  No,
  Unknown,
}

export const Rules: FC = () => {
  const dispatch = useDispatch();

  const selectedRulesSourceName = useSelectedRulesSourceName();
  const editRuleLocation = useReduxSelector((state) => state.rules.editRuleLocation);
  const dataSourceError = useReduxSelector((state) => state.rules.dataSourceError);
  const isEditingRules = useReduxSelector((state) => state.rules.isEditingRules);
  const rules = useReduxSelector((state) => state.rules.rules);

  const [dataSourceType, setDataSourceType] = useState(DataSourceEditable.Unknown);

  const sharedStyles = useStyles(getSharedStyles);

  useEffect(() => trackEvent('View Alerts'), []);

  // Fetch extended data source info (for admins)
  useEffect(() => {
    if (isAdmin) {
      const sources = getAlertingSources();
      const requests = sources.map((source) => fetchDataSourceSettings(source.id));
      Promise.all(requests).then((responses: DataSourceSettings[]) => {
        dispatch(rulesActions.setDataSourceSettings(responses));
      });
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(rulesActions.setDatasourceError(undefined));
    if (!isEditingRules && selectedRulesSourceName) {
      const rulesSource = assertIsDefined(getDatasourceByName(selectedRulesSourceName));

      setDataSourceType(DataSourceEditable.Unknown);

      // Figure out whether data source is prom or cortex
      let cancelled = false;
      api
        .checkIfCortex(rulesSource)
        .then((isCortex) => {
          if (!cancelled) {
            setDataSourceType(isCortex ? DataSourceEditable.Yes : DataSourceEditable.No);
          }
        })
        .catch((e) => {
          if (!cancelled) {
            rulesActions.setDatasourceError(httpStatusToError(e.status));
          }
        });

      const fetchInterval = setInterval(() => {
        dispatch(fetchRules(rulesSource));
      }, intervals.alerts.refetchRules);
      dispatch(fetchRules(rulesSource));

      return () => {
        clearInterval(fetchInterval);
        cancelled = true;
      };
    }

    return () => {}; // For TS
  }, [dispatch, isEditingRules, selectedRulesSourceName]);

  const isEditable = dataSourceType === DataSourceEditable.Yes;
  const isDoneInitializing = dataSourceType !== DataSourceEditable.Unknown && rules !== null;

  if (!selectedRulesSourceName) {
    return (
      <div>
        <DeprecationNotice url={`/alerting/list`} />
        <h5>You still need to add a Prometheus data source.</h5>
      </div>
    );
  }

  if (isEditingRules) {
    return (
      <div style={{ minWidth: 300 }}>
        <RulesDatasourcePicker disabled={true} />
        <EditRuleList dataSourceName={selectedRulesSourceName} />
        {editRuleLocation && <Lightbox />}
      </div>
    );
  }

  return (
    <div style={{ minWidth: 700 }}>
      <DeprecationNotice url={`/alerting/list?dataSource=${encodeURIComponent(selectedRulesSourceName)}`} />
      <RulesDatasourcePicker />
      {dataSourceError ? (
        <h5 className={sharedStyles.headerError}>
          Failed to make a request to data source "{selectedRulesSourceName}": {text.httpErrors[dataSourceError]}
        </h5>
      ) : !isDoneInitializing ? (
        <DelayedSpinner />
      ) : rules && Object.keys(rules).length === 0 ? (
        <EmptyRuleList dataSourceName={selectedRulesSourceName} isEditable={isEditable} />
      ) : (
        rules && <RuleList rules={rules} isEditable={isEditable} dataSourceName={selectedRulesSourceName} />
      )}
    </div>
  );
};
