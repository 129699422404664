import { AppRootProps, NavModelItem, urlUtil, UrlQueryMap } from '@grafana/data';
import { getLocationSrv } from '@grafana/runtime';
import { useStyles } from '@grafana/ui';
import { AsyncReduxProvider } from 'components/AsyncReduxProvider';
import { ToastProvider } from 'components/ToastProvider';
import { Silences } from 'pages/Silences';
import { NavProvider } from 'providers/NavProvider';
import React, { useCallback, useEffect } from 'react';
import { getGlobalStyles } from 'styles';
import { isAdmin } from 'utils/consts';
import { Feature, QueryKey } from 'utils/enums';
import { isFeatureEnabled, setYamlParameters } from 'utils/misc';
import { Alerts, Rules, GlobalConfig, ReceiversIndex } from './pages';

setYamlParameters();

const pages = [
  {
    component: Rules,
    icon: 'bell',
    id: 'rules',
    text: 'Rules',
    enabled: true,
  },
  {
    component: Alerts,
    icon: 'telegram-alt',
    id: 'alerts',
    text: 'Notifications',
    enabled: true,
  },
  {
    component: GlobalConfig,
    icon: 'sliders-v-alt',
    id: 'config',
    text: 'Alertmanager',
    enabled: isAdmin,
  },
  {
    component: Silences,
    icon: 'bell-slash',
    id: 'silences',
    text: 'Silences',
    enabled: true,
  },
  {
    component: ReceiversIndex,
    icon: 'comment-alt-share',
    id: 'receivers',
    text: 'Receivers',
    enabled: isFeatureEnabled(Feature.Receivers),
  },
].filter((p) => p.enabled);

export const AppRoot = React.memo(function AppRoot({ path, query, meta, onNavChanged }: AppRootProps) {
  const globalStyles = useStyles(getGlobalStyles);

  const { tab } = query;

  useEffect(() => {
    const tabs: NavModelItem[] = [];

    // url for tab should include selected datasource ids, but not params that are local to current tab
    const queryParams: Record<string, string> = [
      QueryKey.SelectedRulesSourceName,
      QueryKey.SelectedAlertmanagerName,
    ].reduce((params, key) => {
      if (query[key]) {
        return { ...params, [key]: query[key] };
      }
      return params;
    }, {});

    pages.forEach(({ text, icon, id }) => {
      tabs.push({
        text,
        icon,
        id,
        url: `${path.replace(/^\//, '')}?${urlUtil.toUrlParams({ tab: id, ...queryParams })}`,
      });

      if (tab === id) {
        tabs[tabs.length - 1].active = true;
      }
    });

    // Fallback if current `tab` doesn't match any page
    if (!tabs.some(({ active }) => active)) {
      tabs[0].active = true;
    }

    const node = {
      text: 'Grafana Cloud Alerting',
      img: meta.info.logos.large,
      subTitle: 'Manage rules & notifications',
      url: path,
      children: tabs,
    };

    onNavChanged({
      node,
      main: node,
    });
  }, [meta.info.logos.large, path, query, tab, onNavChanged]);

  const updateQueryParams = useCallback(
    (queryParams: UrlQueryMap, replace?: boolean) => {
      setImmediate(() => getLocationSrv().update({ query: { ...query, ...queryParams }, replace }));
    },
    [query]
  );

  // Can't use react-router because if we supply a path instead of query params,
  // grafana will throw "Not found". I guess it tries to actually serve
  // whatever is at the path instead of only pushing to the browser history.
  const Page = pages.find(({ id }) => id === tab)?.component || pages[0].component;

  // if user has some tab that does not exist in the url, redirect
  if (tab && !pages.find((p) => p.id === tab)) {
    updateQueryParams({
      tab: pages[0].id,
    });
  }
  return (
    <AsyncReduxProvider>
      <ToastProvider>
        <NavProvider queryParams={query} updateQueryParams={updateQueryParams}>
          <main className={globalStyles.containerAppRoot} role="main">
            <Page />
          </main>
        </NavProvider>
      </ToastProvider>
    </AsyncReduxProvider>
  );
});
