import { GrafanaTheme } from '@grafana/data';
import { Tag, useStyles } from '@grafana/ui';
import { LabelsAndAnnotations } from 'components/LabelsAndAnnotations';
import { StripedTable } from 'components/StripedTable';
import { css } from 'emotion';
import React, { useMemo } from 'react';
import { useReduxSelector } from 'store';
import { Alert, AlertingRule } from 'types/rulesInternal';
import { AlertingRuleState } from 'utils/enums';
import { getDistanceToNow } from 'utils/misc';
import { fontSize, marginTop, padding, thinBorder } from 'utils/styles';

type Props = {
  rule: AlertingRule;
};

const getStyles = (theme: GrafanaTheme) => ({
  containerTable: css`
    ${marginTop(theme).sm};

    border: ${thinBorder(theme)};
  `,

  table: css`
    width: 100%;

    tr {
      line-height: initial;
    }

    tbody > tr:nth-child(odd) {
      background: ${theme.isDark ? theme.palette.gray10 : theme.palette.white};
    }

    th {
      ${fontSize(theme).md};
      border-bottom: ${thinBorder(theme)};
      ${padding(theme).sm};
    }

    td {
      ${padding(theme).sm};
      white-space: nowrap;
    }

    td:first-child {
      white-space: normal;
      word-break: break-word;
    }
  `,
});

// make a sort key consisting of alert's label keys and value + annotation keys and values
function makeAlertSortKey(alert: Alert): string {
  return JSON.stringify([alert.labels, alert.annotations]);
}

export function LabelsAndAnnotationsTable({ rule }: Props) {
  const styles = useStyles(getStyles);

  const showAnnotations = useReduxSelector((state) => state.rules.showAnnotations);

  const sortedAlerts = useMemo(
    () => [...rule.alerts].sort((a, b) => makeAlertSortKey(a).localeCompare(makeAlertSortKey(b))),
    [rule]
  );

  return (
    <div className={styles.containerTable} data-testid="labels-and-annotations">
      <StripedTable>
        <thead>
          <tr>
            <th>Labels and annotations</th>
            <th>Details</th>
            <th>State</th>
          </tr>
        </thead>
        <tbody>
          {sortedAlerts.map((alert, idx) => (
            <tr key={idx} data-testid="alert-row">
              <td>
                <LabelsAndAnnotations
                  labels={alert.labels}
                  annotations={alert.annotations}
                  showAnnotations={showAnnotations}
                />
              </td>
              <td>
                Active for {getDistanceToNow(new Date(alert.activeAt))} - Value: {alert.value}
              </td>
              <td>
                {alert.state === AlertingRuleState.Firing ? (
                  <Tag name="firing" colorIndex={15} />
                ) : alert.state === AlertingRuleState.Pending ? (
                  <Tag name="pending" colorIndex={7} />
                ) : (
                  alert.state
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </StripedTable>
    </div>
  );
}
