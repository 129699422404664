import { GrafanaTheme } from '@grafana/data';
import { Button as UiButton, useStyles, Spinner } from '@grafana/ui';
import { css } from 'emotion';
import React, { FC } from 'react';
import { marginRight } from 'utils/styles';

const getStyles = (theme: GrafanaTheme) => ({
  buttonSpinner: css`
    ${marginRight(theme).sm};
    display: inline-block;
  `,
});

export type ButtonProps = React.ComponentProps<typeof UiButton> & {
  isLoading?: boolean;
};

export const Button: FC<ButtonProps> = ({ isLoading, children, ...restOfProps }) => {
  const styles = useStyles(getStyles);

  return (
    <UiButton disabled={isLoading} {...restOfProps}>
      {isLoading && <Spinner className={styles.buttonSpinner} />}
      {children}
    </UiButton>
  );
};
