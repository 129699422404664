import { SelectableValue } from '@grafana/data';
import { Select } from '@grafana/ui';
import { css } from 'emotion';
import React, { FC, useMemo } from 'react';
import { useReduxSelector } from 'store';
import { RuleLocation } from 'store/rules/thunks';
import { RuleMeta } from '../EditRuleList';

const style = css`
  min-width: 136px;
  margin-top: -4px;
  margin-bottom: -4px;
`;

type MoveRuleSelectProps = {
  ruleMeta: RuleMeta;
  onChange: (location: RuleLocation) => void;
};

type Option = SelectableValue<RuleLocation>;

export const MoveRuleSelect: FC<MoveRuleSelectProps> = ({ ruleMeta, onChange }) => {
  const { location, isFirstInGroup, isLastInGroup } = ruleMeta;

  const rulesConfig = useReduxSelector((state) => state.rules.rulesConfig);

  const options = useMemo((): Array<SelectableValue<RuleLocation>> => {
    const options: Option[] = [];
    if (!isFirstInGroup) {
      options.push({
        value: {
          ...location,
          ruleIndex: 0,
        },
        label: 'Start of this group',
      });
    }
    if (!isLastInGroup) {
      options.push({
        value: {
          ...location,
          ruleIndex: rulesConfig?.[location.namespace]?.[location.groupIndex].rules.length || 0,
        },
        label: 'End of this group',
      });
    }
    // other groups
    Object.entries(rulesConfig || {})
      .sort((a, b) => a[0].localeCompare(b[0]))
      .flatMap(([namespace, groups]) =>
        groups
          .map((group, index) => ({ namespace, name: group.name, index, ruleCount: group.rules.length }))
          .filter(({ name }) => name !== ruleMeta.groupName)
      )
      .forEach(({ namespace, name, index, ruleCount }) => {
        options.push({
          value: {
            namespace,
            groupIndex: index,
            ruleIndex: ruleCount, // move to end by default
          },
          label: `${namespace} > ${name}`,
        });
      });
    return options;
  }, [location, isFirstInGroup, isLastInGroup, rulesConfig, ruleMeta.groupName]);

  const onChangeHandler = (item: SelectableValue<RuleLocation>) => {
    if (item?.value) {
      onChange(item.value);
    }
  };

  return (
    <Select
      className={style}
      disabled={!options.length}
      options={options}
      onChange={onChangeHandler}
      placeholder="Move to"
      isSearchable={false}
    />
  );
};
