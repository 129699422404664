import { Alert } from '@grafana/ui';
import { css } from 'emotion';
import React, { useCallback, useContext, useState } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  defaultDuration?: number;
};

type Toast = {
  alertProps: React.ComponentProps<typeof Alert>;

  duration?: number;
  id?: number;
};

export const ToastContext = React.createContext({
  addToast: (toast: Toast) => {},
  removeToast: (id: number) => {},
});

// Based on grafana's .page-alert-list
const style = css`
  z-index: 8000;
  min-width: 400px;
  max-width: 600px;
  position: fixed;
  right: 10px;
  bottom: 60px;
`;

let currentToastId = 0;
export const ToastProvider: React.FC<Props> = ({ children, defaultDuration = 3000 }) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const remove = useCallback((id: number) => {
    setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
  }, []);

  const add = useCallback(
    ({ alertProps, duration = defaultDuration }: Toast) => {
      const id = currentToastId++;
      let timeout: number | undefined;

      const userOnRemove = alertProps.onRemove;
      alertProps.onRemove = (event) => {
        clearTimeout(timeout);
        remove(id);
        if (userOnRemove) {
          userOnRemove(event);
        }
      };

      timeout = setTimeout(alertProps.onRemove, duration);

      setToasts((toasts) => toasts.concat({ alertProps, duration, id }));
      return id;
    },
    [defaultDuration, remove]
  );

  return (
    <ToastContext.Provider value={{ addToast: add, removeToast: remove }}>
      {children}
      {createPortal(
        <div className={style}>
          {toasts.map(({ alertProps, id }) => (
            <Alert key={id} {...alertProps} />
          ))}
        </div>,
        document.body
      )}
    </ToastContext.Provider>
  );
};

export function useInfoToast() {
  const { addToast } = useContext(ToastContext);

  const addInfoToast = useCallback(
    (title: string, children?: React.ReactNode) => {
      addToast({
        alertProps: {
          children,
          severity: 'info',
          title,
        },
        duration: 5000,
      });
    },
    [addToast]
  );

  return addInfoToast;
}
