import { AppEvents, UrlQueryValue, DataSourceSelectItem } from '@grafana/data';
import { AsyncThunkAction, unwrapResult } from '@reduxjs/toolkit';
import { useInfoToast } from 'components/ToastProvider';
import appEvents from 'grafana/app/core/app_events';
import { linkExprToExplore } from 'pages/Rules/utils/rule';
import Prism from 'prismjs';
import { NavContext } from 'providers/NavProvider';
import { useCallback, useContext, useEffect, useState } from 'react';
import { UseAsyncCallbackOptions, UseAsyncReturn, useAsyncCallback } from 'react-async-hook';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { isViewer } from 'utils/consts';
import { isOnScreen } from 'utils/dom';
import { text } from './consts';
import { getAlertmanagerSelectItems, getRulesSourcesSelectItems } from './datasource';
import { QueryKey } from './enums';

export type DispatchWithFeedbackOptions = {
  successMessage?: string;
  errorMessage?: string;

  // called on error with error payload. return `true` if handled, `false` to show default message
  errorHandler?: (payload: unknown) => boolean;
};

type DispatchWithUserFeedback = (
  action: AsyncThunkAction<unknown, unknown, {}>,
  options?: DispatchWithFeedbackOptions
) => Promise<unknown>;

export function useDispatchWithFeedback(): DispatchWithUserFeedback {
  const dispatch: AppDispatch = useDispatch();
  const addInfoToast = useInfoToast();

  return useCallback(
    (action, options = {}) => {
      return dispatch(action).then((result) => {
        if ('error' in result) {
          if (!options.errorHandler?.(result.payload)) {
            appEvents.emit(AppEvents.alertError, [options.errorMessage || text.toast.errorGeneric]);
          }
        } else {
          addInfoToast(options.successMessage || text.toast.successGeneric);
        }
        return unwrapResult(result);
      });
    },
    [dispatch, addInfoToast]
  );
}

export function useForceUpdate() {
  const [, forceUpdate] = useState(false);

  return useCallback(() => {
    forceUpdate((s) => !s);
  }, []);
}

export function useQueryParam<T extends UrlQueryValue = string>(queryParamName: string, defaultValue?: T) {
  const { queryParams, updateQueryParams } = useContext(NavContext);

  const updateState = useCallback(
    (nextValue: UrlQueryValue, replace?: boolean) => updateQueryParams({ [queryParamName]: nextValue }, replace),
    [updateQueryParams, queryParamName]
  );

  const currentValue = queryParams[queryParamName];

  let value = defaultValue;
  if (currentValue !== undefined) {
    value = (currentValue as any) as T;
  } else if (defaultValue !== undefined && defaultValue !== null) {
    updateState(defaultValue, true);
  }

  return [value, updateState] as const;
}

export function useRerenderEvery(intervalMs = 1000) {
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    const interval = setInterval(forceUpdate, intervalMs);
    return () => clearInterval(interval);
  }, [intervalMs, forceUpdate]);
}

function useSelectedDataSourceName(queryKey: QueryKey, dataSources: DataSourceSelectItem[]): string | undefined {
  const defaultValue = dataSources[0]?.name;
  const [selectedDataSourceName, setSelectedDataSourceName] = useQueryParam<string>(queryKey);
  if (selectedDataSourceName && dataSources.find((ds) => ds.name === selectedDataSourceName)) {
    return selectedDataSourceName;
  } else if (defaultValue) {
    setSelectedDataSourceName(defaultValue, true);
    return defaultValue;
  }
  return undefined;
}

export function useSelectedAlertManagerName() {
  return useSelectedDataSourceName(QueryKey.SelectedAlertmanagerName, getAlertmanagerSelectItems());
}

export function useSelectedRulesSourceName() {
  return useSelectedDataSourceName(QueryKey.SelectedRulesSourceName, getRulesSourcesSelectItems());
}

export function useSyntaxHighlighting() {
  const [dataSourceName] = useQueryParam<string>(QueryKey.SelectedRulesSourceName);

  return useCallback(
    (div: HTMLDivElement, linkToExplore?: string) => {
      const highlight = () => {
        Prism.highlightElement(div);
        if (!isViewer && dataSourceName && linkToExplore) {
          linkExprToExplore(div, dataSourceName, linkToExplore);
        }
      };
      // highlighting takes non trivial amount of time, and can be an issue if done in bulk.
      // we synchronously highlight only code blocks that are visible on screen, and defer highlighting the rest
      if (isOnScreen(div)) {
        highlight();
      } else {
        setImmediate(highlight);
      }
    },
    [dataSourceName]
  );
}

// A wrapper for useAsyncCallback that will dispatch success/failure toasts
export function useAsyncCallbackWithFeedback<R = unknown, Args extends any[] = any[]>(
  asyncFunction: (...args: Args) => Promise<R>,
  options: UseAsyncCallbackOptions<R> & {
    successMessage: string;
    errorMessage: string | ((err: Error) => string);
  }
): UseAsyncReturn<R, Args> {
  return useAsyncCallback(async (...args: Args) => {
    try {
      const result = await asyncFunction(...args);
      appEvents.emit(AppEvents.alertSuccess, [options.successMessage]);
      return result;
    } catch (e) {
      appEvents.emit(AppEvents.alertError, [
        typeof options.errorMessage === 'function' ? options.errorMessage(e) : options.errorMessage,
      ]);
      throw e;
    }
  }, options);
}
