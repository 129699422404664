import { alertmanagerRequest } from 'pages/Silences/utils/api';
import { AlertmanagerGroupPayload } from 'types/alertsExternal';
import { AlertmanagerGroup } from 'types/alertsInternal';
import { transformDatesInPayload } from 'utils/alertmanager/transformations';
import { AlertManagerEndpoint } from 'utils/enums';

export function transformAlertsGroups(alertsGroups: AlertmanagerGroupPayload[]) {
  return alertsGroups.map((alertsGroup) => {
    return {
      ...alertsGroup,
      id: JSON.stringify(alertsGroup.labels),
      alerts: transformDatesInPayload(alertsGroup.alerts),
    };
  });
}

type FetchAlertGroupsOptions = {
  labelFilters: string[];

  showInhibited?: boolean;
  showSilenced?: boolean;
};

export async function fetchAlertGroups(
  dataSourceName: string,
  options?: FetchAlertGroupsOptions
): Promise<AlertmanagerGroup[]> {
  // Using urlUtil would result in `inhibited` instead of `inhibited=false`
  const booleanOptions = `inhibited=${options?.showInhibited ?? true}&silenced=${options?.showSilenced ?? true}`;
  const filterOptions = options?.labelFilters.reduce((acc, labelFilter) => {
    return acc + `filter=${labelFilter}`;
  }, '');
  const allOptions = [booleanOptions, filterOptions].filter((options) => !!options);

  const { data }: { data: AlertmanagerGroupPayload[] } = await alertmanagerRequest(
    dataSourceName,
    `${AlertManagerEndpoint.AlertGroups}?${allOptions.join('&')}`
  );
  return transformAlertsGroups(data);
}
