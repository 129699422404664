import { TimeRange, dateTime, DateTime } from '@grafana/data';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertmanagerGroup } from 'types/alertsInternal';
import { text } from 'utils/consts';
import { httpStatusToError } from 'utils/datasource';
import { HttpError } from 'utils/enums';
import { fetchAlertGroupsThunk } from './thunks';

export type AlertsState = {
  labelFilters: string[];
  groupsExpanded: Record<string, boolean>;
  filterReceiver?: string;
  filterStartDate: TimeRange;
  showInhibited: boolean;
  showSilenced: boolean;

  dataSourceError?: string;
  alertGroups?: AlertmanagerGroup[];
};

const undate: DateTime = dateTime(null);

export const initialState: AlertsState = {
  labelFilters: [],
  groupsExpanded: {},
  showInhibited: false,
  showSilenced: false,
  filterStartDate: {
    from: undate,
    to: undate,
    raw: {
      from: undate,
      to: undate,
    },
  },
};

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    collapseAll: (state) => {
      state.alertGroups?.forEach(({ id }) => {
        state.groupsExpanded[id] = false;
      });
    },
    expandAll: (state) => {
      state.alertGroups?.forEach(({ id }) => {
        state.groupsExpanded[id] = true;
      });
    },
    addLabelFilter: (state, { payload }: { payload: string }) => {
      if (!state.labelFilters.includes(payload)) {
        state.labelFilters.push(payload);
      }
    },
    setLabelFilters: (state, { payload }: { payload: string[] }) => {
      state.labelFilters = payload;
    },
    setFilterReceiver: (state, { payload }: { payload: string | undefined }) => {
      state.filterReceiver = payload;
    },
    setFilterStartDate: (state, { payload }: { payload: TimeRange }) => {
      state.filterStartDate = payload;
    },
    removeLabelFilter: (state, { payload }: { payload: string }) => {
      state.labelFilters = state.labelFilters.filter((labelFilter) => labelFilter !== payload);
    },
    toggleGroupCollapse: (state, { payload }: PayloadAction<AlertmanagerGroup['labels']>) => {
      const id = JSON.stringify(payload);
      state.groupsExpanded[id] = !state.groupsExpanded[id];
    },
    toggleShowInhibited: (state) => {
      state.showInhibited = !state.showInhibited;
    },
    toggleShowSilenced: (state) => {
      state.showSilenced = !state.showSilenced;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAlertGroupsThunk.fulfilled, (state, { payload }) => {
      state.alertGroups = payload;
      delete state.dataSourceError;
    });
    builder.addCase(fetchAlertGroupsThunk.rejected, (state, { payload }: any) => {
      delete state.alertGroups;
      const error = httpStatusToError(payload?.status);
      if (
        error === HttpError.NotFound &&
        payload?.data?.message.match(/the Alertmanager is not configured/i) !== null
      ) {
        state.dataSourceError = text.error.alertmanagerNoConfig;
      } else {
        state.dataSourceError = text.httpErrors[error];
      }
    });
  },
});

export const { actions: alertsActions } = alertsSlice;
