import {
  DataSourceInstanceSettings,
  DataSourceSelectItem,
  DataSourceSettings,
  DataSourceJsonData,
} from '@grafana/data';
import { BackendSrvRequest, getBackendSrv } from '@grafana/runtime';
import sortBy from 'lodash/sortBy';
import { getAllDataSources } from './config';
import { CortexEndpoint, DataSourceType, HttpError, LokiEndpoint } from './enums';

export function getDatasourceByName(name: string): DataSourceInstanceSettings<DataSourceJsonData> | undefined {
  return getAllDataSources().find((source) => source.name === name);
}

export function getAlertmanagerSelectItems() {
  return instanceSettingsToSelectItems(getAllDataSources().filter((ds) => ds.type === DataSourceType.Alertmanager));
}

export function getAlertingSources() {
  const datasources = getAllDataSources().filter(
    (ds) =>
      isValidPromSource(ds) ||
      ds.type === DataSourceType.Loki ||
      ds.type === DataSourceType.Alertmanager ||
      ds.type === DataSourceType.Ruler
  );
  return sortBy(datasources, ['name']);
}

export function getRulesEndpoint(dataSource: DataSourceSettings | DataSourceInstanceSettings) {
  return dataSource.type === DataSourceType.Loki ? LokiEndpoint.Rules : CortexEndpoint.Rules;
}

export function getRulesSourcesSelectItems() {
  const sources = getAllDataSources().filter((ds) => isValidPromSource(ds) || ds.type === DataSourceType.Loki);
  return instanceSettingsToSelectItems(sortBy(sources, ['name']));
}

function instanceSettingsToSelectItems(instanceSettings: DataSourceInstanceSettings[]): DataSourceSelectItem[] {
  return instanceSettings.map(
    (ds) =>
      ({
        value: ds.name,
        name: ds.name,
        meta: ds.meta,
      } as DataSourceSelectItem)
  );
}

function isValidPromSource(ds: DataSourceInstanceSettings) {
  let matchesExclusion = false;
  if (ds.jsonData && ds.jsonData.hasOwnProperty('directUrl')) {
    matchesExclusion = (ds.jsonData as any).directUrl.includes('billing.grafana.net');
  }

  return ds.type === DataSourceType.Prometheus && !ds.meta.builtIn && !matchesExclusion;
}

export const httpStatusToError = (status: number | undefined): HttpError => {
  return status && Object.values(HttpError).includes(status) ? (status as HttpError) : HttpError.Other;
};

export function request(dataSourceName: string, url: string, options: Partial<BackendSrvRequest> = {}) {
  const datasource: DataSourceInstanceSettings | undefined = getDatasourceByName(dataSourceName);
  if (!datasource) {
    throw new Error(`No datasource called ${dataSourceName} found.`);
  }

  const _options: Partial<BackendSrvRequest> = {
    headers: {},
    method: 'GET',
    url: datasource.url + url,
    ...options,
  };

  if (datasource.basicAuth || datasource.withCredentials) {
    _options.withCredentials = true;
  }

  if (datasource.basicAuth && _options.headers) {
    _options.headers.Authorization = datasource.basicAuth;
  }

  return getBackendSrv()
    .fetch<any>(_options as BackendSrvRequest)
    .toPromise();
}
