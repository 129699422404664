import { GrafanaTheme } from '@grafana/data';
import { Icon, useStyles } from '@grafana/ui';
import { AlertLabels } from 'components/AlertLabel';
import { css } from 'emotion';
import React from 'react';
import { AlertmanagerAlert } from 'types/alertsInternal';
import { fontSize, marginTop, padding } from 'utils/styles';

type Props = {
  alerts?: AlertmanagerAlert[];
  isCollapsed?: boolean;
  isPreview?: boolean;
  onToggleCollapse?: () => void;
  silenceId?: string;
};

export const getStyles = (theme: GrafanaTheme) => ({
  affectedAlertHeading: css`
    ${marginTop(theme).xs};
  `,
  affectedAlertLink: css`
    white-space: nowrap;

    color: ${theme.palette.blue95};
    ${fontSize(theme).md};
  `,
  containerAlert: css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${padding(theme).sm};

    &:nth-child(even) {
      background-color: ${theme.isDark ? theme.palette.gray10 : theme.palette.white};
    }
  `,
  heading: (isPreview = false) => css`
    font-weight: 500;

    ${!isPreview && 'cursor: pointer;'}
  `,
});

export const AffectedAlerts = React.memo(({ alerts, isCollapsed, isPreview, onToggleCollapse, silenceId }: Props) => {
  const styles = useStyles(getStyles);

  const alertsElements = alerts?.map((alert) => (
    <div data-testid="affected-alert" className={styles.containerAlert} key={alert.fingerprint}>
      <div>
        <h6 className={styles.affectedAlertHeading}>{alert.endsAt.toISOString()}</h6>
        <AlertLabels labels={alert.labels} />
      </div>
      {alert.generatorURL && (
        <a className={styles.affectedAlertLink} href={alert.generatorURL}>
          View in Explore
        </a>
      )}
    </div>
  ));

  const collapseToggle = !isPreview && alerts && alerts.length > 0 && (
    <Icon
      aria-label={`${isCollapsed ? 'Expand' : 'Collapse'} silence ${silenceId}`}
      size="xl"
      name={isCollapsed ? 'angle-right' : 'angle-down'}
    />
  );

  return (
    <>
      <div className={styles.heading(isPreview)} onClick={onToggleCollapse}>
        {collapseToggle} {`${alerts?.length || 'No'} affected notification${alerts?.length === 1 ? '' : 's'}`}
      </div>
      {!isCollapsed && alertsElements}
    </>
  );
});
