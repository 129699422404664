import { config } from '@grafana/runtime';
import { css } from 'emotion';
import React from 'react';
import { warn } from 'utils/misc';

type Props = {
  href: string;

  isUnstyled?: boolean;
};

const style = css`
  text-decoration: underline;
`;

export const Link: React.FC<Props> = ({ children, href, isUnstyled }) => {
  if (!href.startsWith('/')) {
    warn('Warning: The component Link should be used only for internal links that start with "/".');
  }

  return (
    <a className={isUnstyled ? '' : style} href={config.appSubUrl + href}>
      {children}
    </a>
  );
};
