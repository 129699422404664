import { Receiver } from 'types/alertmanager';
import { AlertmanagerIntegration } from 'utils/enums';

export const ALERTMANAGER_CONFIG_ERROR_PREFIX = 'error validating Alertmanager config: ';
export const ALERTMANAGER_CONFIG_TEMPLATE_ERROR_RE = /^error validating Alertmanager config:\stemplate:\s(.+):\d+:(.*)/;

export const alertmanagerIntegrationNames: Record<AlertmanagerIntegration, string> = {
  [AlertmanagerIntegration.Email]: 'Email',
  [AlertmanagerIntegration.OpsGenie]: 'OpsGenie',
  [AlertmanagerIntegration.PagerDuty]: 'PagerDuty',
  [AlertmanagerIntegration.Pushover]: 'Pushover',
  [AlertmanagerIntegration.Slack]: 'Slack',
  [AlertmanagerIntegration.Webhook]: 'Webhook',
  [AlertmanagerIntegration.WeChat]: 'WeChat',
  [AlertmanagerIntegration.VictorOps]: 'VictorOps',
};

export const receiverConfigKeyToIntegration: Record<keyof Omit<Receiver, 'name'>, AlertmanagerIntegration> = {
  email_configs: AlertmanagerIntegration.Email,
  opsgenie_configs: AlertmanagerIntegration.OpsGenie,
  pagerduty_configs: AlertmanagerIntegration.PagerDuty,
  pushover_configs: AlertmanagerIntegration.Pushover,
  slack_configs: AlertmanagerIntegration.Slack,
  webhook_configs: AlertmanagerIntegration.Webhook,
  victorops_configs: AlertmanagerIntegration.VictorOps,
  wechat_configs: AlertmanagerIntegration.WeChat,
};
