import { Button, Tooltip } from '@grafana/ui';
import { isUndefined } from 'lodash';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useReduxSelector } from 'store';
import { rulesActions } from 'store/rules';
import { getRuler } from 'store/utils/datasources';
import { isAdmin } from 'utils/consts';
import { DataSourceType } from 'utils/enums';
import { trackEvent } from 'utils/tracking';

interface Props {
  dataSourceName: string;
}

export const EditRulesButton: FC<Props> = ({ dataSourceName }) => {
  const dispatch = useDispatch();
  const enterEditMode = () => {
    trackEvent('Click edit rules');
    dispatch(rulesActions.setIsEditingRules(true));
  };

  const { ruler, rulesSource } = useReduxSelector((state) => {
    return getRuler(state, dataSourceName);
  });

  // TODO: Remove this logic once HG provisions Loki Ruler data sources
  // for all customers.
  const isLokiWithoutRuler = isAdmin && rulesSource.type === DataSourceType.Loki && isUndefined(ruler);
  const editRulesButton = (
    <Button disabled={isLokiWithoutRuler} onClick={enterEditMode}>
      Edit rules
    </Button>
  );

  return isLokiWithoutRuler ? (
    <Tooltip content="This functionality will be rolled out shortly">{editRulesButton}</Tooltip>
  ) : (
    <>{editRulesButton}</>
  );
};
