import { GrafanaTheme } from '@grafana/data';
import { useStyles } from '@grafana/ui';
import { css } from 'emotion';
import React, { FC, Children } from 'react';
import { marginBottom } from 'utils/styles';

const getStyle = (multipleChildren: boolean) => (theme: GrafanaTheme) => css`
  display: flex;
  justify-content: ${multipleChildren ? 'space-between' : 'flex-end'};
  ${marginBottom(theme).md};
`;

export const GlobalConfigHeader: FC = ({ children }) => {
  return <div className={useStyles(getStyle(Children.count(children) > 1))}>{children}</div>;
};
