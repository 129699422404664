import { RecentlyCreatedGroupNames, BaseRuleGroup, BaseRules } from 'types/rulesInternal';

// returns new instance of `payload` that will reshuffle some groups to top based on `recently created groups` list
export function moveRecentlyCreatedGroupsToTop<G extends BaseRuleGroup>(
  payload: BaseRules<G>,
  recentlyCreatedGroupNames: RecentlyCreatedGroupNames
): BaseRules<G> {
  const newPayload: BaseRules<G> = {};
  Object.entries(payload).forEach(([namespace, groups]) => {
    const newGroupNames = recentlyCreatedGroupNames[namespace];
    if (newGroupNames?.length) {
      newPayload[namespace] = [
        ...newGroupNames.map((name) => groups.find((g) => g.name === name)).filter((g): g is G => !!g),
        ...groups.filter((g) => !newGroupNames.includes(g.name)),
      ];
    } else {
      newPayload[namespace] = groups;
    }
  });
  return newPayload;
}
