import { GrafanaTheme } from '@grafana/data';
import { InfoBox, useStyles } from '@grafana/ui';
import { css } from 'emotion';
import React from 'react';
import { AsyncStateStatus } from 'react-async-hook';
import { getGlobalStyles } from 'styles';
import { AlertmanagerAlert } from 'types/alertsInternal';
import { text } from 'utils/consts';
import { fontSize, marginTop } from 'utils/styles';
import { AffectedAlerts } from './AffectedAlerts';

type Props = {
  status: AsyncStateStatus;

  error?: Error;
  matchingAlerts?: AlertmanagerAlert[];
};

const getStyles = (theme: GrafanaTheme) => ({
  containerContent: css`
    ${marginTop(theme).lg};
  `,
  infoBox: css`
    ${fontSize(theme).base};
  `,
});

export const AlertsPreview: React.FC<Props> = ({ error, matchingAlerts, status }) => {
  const styles = useStyles(getStyles);
  const globalStyles = useStyles(getGlobalStyles);

  const content =
    status === 'error' && error ? (
      <InfoBox className={styles.infoBox}>{error.message}</InfoBox>
    ) : status === 'success' && matchingAlerts && matchingAlerts.length === 0 ? (
      <InfoBox className={styles.infoBox}>{text.error.silencesNoMatchingAlerts}</InfoBox>
    ) : status === 'success' && matchingAlerts ? (
      <div className={globalStyles.containerCard()}>
        <AffectedAlerts alerts={matchingAlerts} isPreview />
      </div>
    ) : null;

  return content && <div className={styles.containerContent}>{content}</div>;
};
