import { useStyles, Icon } from '@grafana/ui';
import { StatusColoredText } from 'components/StatusColoredText';
import React, { FC, useState, useEffect } from 'react';
import { useReduxSelector } from 'store';
import { RuleGroup as RuleGroupType, Rule as RuleType } from 'types/rulesInternal';
import { AlertingRuleState, RuleType as RuleTypeEnum } from 'utils/enums';
import { NewGroupTag } from './NewGroupTag';
import { Rule } from './Rule';
import { getRuleListStyles } from './styles/ruleList';
import { getRuleGroupStats } from './utils/transformations';

type Props = {
  namespace: string;
  group: RuleGroupType;
  isGroupNew: boolean;
  rules: RuleType[];
};

const RuleGroup: FC<Props> = ({ namespace, group, rules, isGroupNew }) => {
  const groupStats = getRuleGroupStats(group);
  const styles = useStyles(getRuleListStyles);

  const alertStateFilter = useReduxSelector((state) => state.rules.alertStateFilter);
  const showAlertingRules = useReduxSelector((state) => state.rules.showAlertingRules);
  const showRecordingRules = useReduxSelector((state) => state.rules.showRecordingRules);
  const isFullyExpanded = useReduxSelector((state) => state.rules.isFullyExpanded);

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setIsExpanded(isFullyExpanded);
  }, [isFullyExpanded]);

  const showRule = (rule: RuleType) => {
    if (rule.type === RuleTypeEnum.Alerting && showAlertingRules) {
      return alertStateFilter.includes(rule.state);
    }
    return rule.type === RuleTypeEnum.Recording && showRecordingRules;
  };
  return (
    <div className={styles.groupControls}>
      <button className={styles.expandButton} onClick={() => setIsExpanded(!isExpanded)}>
        <Icon name={isExpanded ? 'angle-down' : 'angle-right'} />
      </button>
      <div className={styles.containerRuleGroup} data-testid="rule-group">
        <div className={styles.containerHeaderRuleGroup} onClick={() => setIsExpanded(!isExpanded)}>
          <h6 className={styles.headerRuleGroup}>
            {namespace} &gt; {group.name} {isGroupNew && <NewGroupTag />}
          </h6>
          <div>
            <StatusColoredText status={AlertingRuleState.Firing}>
              {groupStats[AlertingRuleState.Firing]} firing
            </StatusColoredText>
            ,{' '}
            <StatusColoredText status={AlertingRuleState.Pending}>
              {groupStats[AlertingRuleState.Pending]} pending
            </StatusColoredText>
            ,{' '}
            <StatusColoredText status={AlertingRuleState.Inactive}>
              {groupStats[AlertingRuleState.Inactive]} inactive
            </StatusColoredText>{' '}
            out of {group.rules.length} total.
          </div>
        </div>
        {isExpanded && (
          <div>
            <hr className={styles.hr} />
            {rules.map((rule, index) => {
              return <Rule key={`${rule.name}-${index}`} rule={rule} isVisible={showRule(rule)} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default RuleGroup;
