// Based on: https://egghead.io/lessons/javascript-redux-persisting-the-state-to-the-local-storage
import { get, set } from 'idb-keyval';
import cloneDeep from 'lodash/cloneDeep';
import throttle from 'lodash/throttle';
import { ValuesType } from 'utility-types';
import { ReduxState } from '.';
import { AlertsState } from './alerts';
import { RoutesState } from './routes';
import { RulesState } from './rules';
import { SilencesState } from './silences';

const schemaVersion = 'V4';
const schemaKey = 'grafana.plugin.alertingUI.redux' + schemaVersion;

type Exclusions = Readonly<{
  alerts: Readonly<Array<keyof AlertsState>>;
  routes: Readonly<Array<keyof RoutesState>>;
  rules: Readonly<Array<keyof RulesState>>;
  silences: Readonly<Array<keyof SilencesState>>;
}>;

function checkExclusions<A extends Exclusions>(a: A): A {
  return a;
}

const exclusions = checkExclusions({
  alerts: [
    'alertGroups',
    'dataSourceError',
    'groupsExpanded',
    'filterStartDate',
    'filterReceiver',
    'showInhibited',
    'showSilenced',
    'labelFilters',
  ],
  routes: ['search'],
  rules: [
    'editRuleLocation',
    'isEditingRules',
    'rules',
    'rulesConfig',
    'editGroupNameLocation',
    'isWriteInProgress',
    'newlyCreatedGroupNames',
  ],
  silences: ['silences', 'dataSourceError'],
});

type PersistedReduxState = {
  alerts: Omit<AlertsState, ValuesType<typeof exclusions['alerts']>>;
  routes: Omit<RoutesState, ValuesType<typeof exclusions['routes']>>;
  rules: Omit<RulesState, ValuesType<typeof exclusions['rules']>>;
  silences: Omit<SilencesState, ValuesType<typeof exclusions['silences']>>;
};
export function createPersistedStateObject(state: ReduxState): PersistedReduxState {
  const persistedState = cloneDeep(state);

  for (const namespace of Object.keys(exclusions) as Array<keyof typeof exclusions>) {
    for (const key of exclusions[namespace]) {
      // @ts-expect-error - TS can't know which namespace we operate on at runtime
      delete persistedState[namespace][key];
    }
  }

  return persistedState;
}

export async function loadState(): Promise<PersistedReduxState | undefined> {
  return get(schemaKey);
}

export const saveState = throttle((state) => {
  set(schemaKey, createPersistedStateObject(state));
}, 1000);
