import { GrafanaTheme } from '@grafana/data';
import { useStyles } from '@grafana/ui';
import { css } from 'emotion';
import React, { FC } from 'react';
import { marginLeft } from 'utils/styles';

const getStyles = (theme: GrafanaTheme) => css`
  padding-top: ${theme.spacing.sm};
  & > * {
    vertical-align: baseline;
  }
  & > * + * {
    ${marginLeft(theme).sm};
  }
`;

export const ButtonsContainer: FC = ({ children }) => <div className={useStyles(getStyles)}>{children}</div>;
