import { BackendSrvRequest } from '@grafana/runtime';
import { AnyDataSourceSettings } from 'types';
import { PromRulesResponse, RuleGroupYML } from 'types/rulesExternal';
import { Rules, RulesConfig } from 'types/rulesInternal';
import { getRulesEndpoint, request } from 'utils/datasource';
import { CortexEndpoint, DataSourceType, LokiEndpoint } from 'utils/enums';
import yaml from 'yaml';
import { RuleMeta } from '../EditRuleList';
import { transformPrometheusPayload, sortRuleGroups } from './transformations';

export function setRuleGroup(
  ruler: AnyDataSourceSettings,
  rulesSource: AnyDataSourceSettings,
  namespace: string,
  group: RuleGroupYML
): Promise<unknown> {
  const requestOptions: Partial<BackendSrvRequest> = {
    data: yaml.stringify(group),
    headers: {
      'Content-Type': 'application/yaml',
    },
    method: 'POST',
  };
  return request(ruler.name, `${getRulesEndpoint(rulesSource)}/${encodeURIComponent(namespace)}`, requestOptions);
}

export function deleteRuleGroup(
  ruler: AnyDataSourceSettings,
  rulesSource: AnyDataSourceSettings,
  namespace: string,
  groupName: string
): Promise<unknown> {
  const requestOptions: Partial<BackendSrvRequest> = {
    method: 'DELETE',
  };
  return request(
    ruler.name,
    `${getRulesEndpoint(rulesSource)}/${encodeURIComponent(namespace)}/${encodeURIComponent(groupName)}`,
    requestOptions
  );
}

export function deleteNamespace(
  ruler: AnyDataSourceSettings,
  rulesSource: AnyDataSourceSettings,
  namespace: string
): Promise<unknown> {
  return request(ruler.name, `${getRulesEndpoint(rulesSource)}/${encodeURIComponent(namespace)}`, {
    method: 'DELETE',
  });
}

export async function checkIfCortex(rulesSource: AnyDataSourceSettings): Promise<boolean> {
  // if it's prometheus, it returns 200 with html, because this api endpoint does not exist and it defaults to index page
  // if it's cortex, it returns 200 with rules in yaml, or if there are no rules, 404
  try {
    const response = await request(rulesSource.name, getRulesEndpoint(rulesSource));
    return response.headers.get('content-type') === 'application/yaml';
  } catch (e) {
    if (e.status === 404) {
      return true;
    }
    throw e;
  }
}

export async function fetchRulesConfig(
  ruler: AnyDataSourceSettings,
  rulesSource: AnyDataSourceSettings
): Promise<RulesConfig> {
  const response = await request(ruler.name, getRulesEndpoint(rulesSource));
  return sortRuleGroups(yaml.parse(response.data));
}

export async function fetchRules(rulesSource: AnyDataSourceSettings): Promise<Rules> {
  const response: PromRulesResponse = await request(
    rulesSource.name,
    rulesSource.type === DataSourceType.Loki ? LokiEndpoint.PrometheusRules : CortexEndpoint.PrometheusRules
  );

  if (response.status === 200 && response.data.status === 'success') {
    return sortRuleGroups(transformPrometheusPayload(response.data.data.groups));
  } else if (response.status === 404) {
    return {};
  } else {
    throw new Error(`http error status=${response.status} body=${JSON.stringify(response.data)}`);
  }
}

/*
For example:
given

```
3:13: group "2", rule 0, "1": invalid recording rule name: 1
```

return "invalid recording rule name: 1"
*/
export function extractRuleValidationError(ruleMeta: RuleMeta, errorMessage: string): string | undefined {
  // general yaml parsing error (cortex)
  if (errorMessage === 'unable to decoded rule group\n') {
    return 'Invalid rule definition.';
  }
  // problem with specific rule (cortex)
  const cortexRuleError = errorMessage
    .split('\n')
    .find((line) => line.includes(`group "${ruleMeta.groupName}", rule ${ruleMeta.location.ruleIndex},`))
    ?.replace(/\d+:\d+:\sgroup\s".*", rule \d+, ".*":\s/, '');

  // by default, return the entire message
  return cortexRuleError ?? errorMessage;
}
