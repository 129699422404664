import { useCallback } from 'react';
import { useAsync, UseAsyncReturn } from 'react-async-hook';
import { AlertmanagerConfig } from 'types/alertmanager';
import { getAlertmanagerSelectItems } from 'utils/datasource';
import { AlertmanagerStatus, QueryKey } from 'utils/enums';
import { useQueryParam, useSelectedAlertManagerName } from 'utils/hooks';
import yaml from 'yaml';
import * as api from './api';

export function useSelectedAlertmanager(): string | undefined {
  const alertmanagerSources = getAlertmanagerSelectItems();
  return useQueryParam<string>(
    QueryKey.SelectedAlertmanagerName,
    // If more than one source, user has to actively select one => no default
    alertmanagerSources.length === 1 ? alertmanagerSources[0].name : undefined
  )[0];
}

export type ParsedCortexAlertmanagerConfig = {
  templates: Record<string, string>;
  config: AlertmanagerConfig;
};

export type ConfigRequest = UseAsyncReturn<ParsedCortexAlertmanagerConfig | undefined, Array<string | undefined>>;
export type UpdateConfigFn = (payload: AlertmanagerConfig, templates?: Record<string, string>) => Promise<void>;
export type DeleteConfigFn = () => Promise<void>;
type UseAlertManagerConfigReturn = {
  configRequest: ConfigRequest;
  updateConfig: UpdateConfigFn;
  deleteConfig: DeleteConfigFn;
};

export function useAlertmanagerConfig(): UseAlertManagerConfigReturn {
  const selectedAlertmanagerName = useSelectedAlertManagerName();

  const configRequest = useAsync(
    async (datasourceName?: string) => {
      if (datasourceName) {
        const conf = await api.getConfig(datasourceName);
        if (conf) {
          return {
            templates: conf.template_files,
            config: (yaml.parse(conf.alertmanager_config) || {}) as AlertmanagerConfig,
          };
        }
      }
      return;
    },
    [selectedAlertmanagerName]
  );

  const updateConfig = useCallback(
    async (payload: AlertmanagerConfig, templates?: Record<string, string>) => {
      if (selectedAlertmanagerName) {
        await api.saveConfig(selectedAlertmanagerName, {
          template_files: templates || configRequest.result?.templates || {},
          alertmanager_config: yaml.stringify(payload),
        });
        configRequest.merge({
          result: {
            templates: templates || configRequest.result?.templates || {},
            config: payload,
          },
        });
      }
    },
    [configRequest, selectedAlertmanagerName]
  );

  const deleteConfig = useCallback(async () => {
    if (selectedAlertmanagerName) {
      await api.deleteConfig(selectedAlertmanagerName);
      await configRequest.execute();
    }
  }, [selectedAlertmanagerName, configRequest]);

  return { configRequest, updateConfig, deleteConfig };
}

export function useDefaultAlertmanagerConfig() {
  const selectedAlertmanagerName = useSelectedAlertManagerName();

  const configRequest = useAsync(
    async (datasourceName?: string) => {
      if (datasourceName) {
        const config = await api.getStatus(datasourceName);
        return config?.config.original;
      }
      return;
    },
    [selectedAlertmanagerName]
  );

  return configRequest;
}

export function useAlertmanagerStatus(selectedAlertmanagerName?: string) {
  const { status } = useAsync(
    async (datasourceName: string | undefined) => {
      if (datasourceName) {
        return api.getStatus(datasourceName);
      }
      return null;
    },
    [selectedAlertmanagerName]
  );
  if (status === 'success') {
    return AlertmanagerStatus.Available;
  } else if (status === 'error') {
    return AlertmanagerStatus.Unavailable;
  } else {
    return AlertmanagerStatus.Unknown;
  }
}
