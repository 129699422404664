import { AppEvents, urlUtil } from '@grafana/data';
import { config } from '@grafana/runtime';
import intervalToDuration from 'date-fns/intervalToDuration';
import appEvents from 'grafana/app/core/app_events';
import isArray from 'lodash/isArray';
import { Value } from 'slate';
import yaml from 'yaml';
import { getQuerySearchParams } from './url';

const durationMap: { [key in Required<keyof Duration>]: string[] } = {
  years: ['y', 'Y', 'years'],
  months: ['M', 'months'],
  weeks: ['w', 'W', 'weeks'],
  days: ['d', 'D', 'days'],
  hours: ['h', 'H', 'hours'],
  minutes: ['m', 'minutes'],
  seconds: ['s', 'S', 'seconds'],
};

export function createExploreLink(dataSourceName: string, query: string) {
  return urlUtil.renderUrl(config.appSubUrl + '/explore', {
    left: JSON.stringify([
      'now-1h',
      'now',
      dataSourceName,
      { datasource: dataSourceName, expr: query },
      { ui: [true, true, true, 'none'] },
    ]),
  });
}

export function emitAppEventsError(texts: string[]) {
  appEvents.emit(AppEvents.alertError, texts);
}

/**
 * Prints distance between a start date and now as "X years, Y months, [...]"
 * until minutes.
 * Starts with the largest unit that is not 0.
 */
export function getDistanceToNow(start: Date) {
  const duration = intervalToDuration({
    start,
    end: Date.now(),
  });

  const units: Array<keyof Duration> = ['years', 'months', 'days', 'hours', 'minutes'];
  const durationArray = [];
  let doPush = false;
  for (const unit of units) {
    if (doPush) {
      durationArray.push(`${duration[unit]} ${unit}`);
    } else if (!doPush && duration[unit]) {
      durationArray.push(`${duration[unit]} ${unit}`);
      doPush = true;
    }
  }

  return durationArray.join(', ');
}

export function isNgAlertingEnabled(): boolean {
  return !!((config as any).unifiedAlertingEnabled || config.featureToggles.ngalert);
}

export function isFeatureEnabled(name: string) {
  let isEnabledThroughQueryParam = false;
  const queryParamFeatures: string | undefined = getQuerySearchParams()['features'];
  if (queryParamFeatures) {
    isEnabledThroughQueryParam = queryParamFeatures.includes(name);
  }

  // @ts-ignore
  // FeatureToggle misses the index signature, needs to be addressed in grafana core
  return Boolean(config.featureToggles[name]) || isEnabledThroughQueryParam;
}

export function isValidDate(dateString: string) {
  return !isNaN(Date.parse(dateString));
}

export function indentText(text: string, indentSize = 2, indentTimes = 1) {
  const indentString = ' '.repeat(indentSize * indentTimes);
  return text
    .split('\n')
    .map((line) => indentString + line)
    .join('\n');
}

export function log(...args: Parameters<typeof console.log>) {
  if (process.env.NODE_ENV === 'development') {
    console.log(...args);
  }
}

export function warn(...args: Parameters<typeof console.warn>) {
  if (process.env.NODE_ENV === 'development') {
    console.warn(...args);
  }
}

/**
 * Limits to specified precision and removes trailing 0s.
 * Examples:
 * (4.0001, 1) => 4
 * (4.1234, 1) => 4.1
 * (4.6789, 1) => 4.6
 */
function limitFloatingPrecision(x: number, precision: number): string {
  return parseFloat(x.toFixed(precision)).toString();
}

export function durationToAbbreviatedString(duration: Duration): string {
  return (Object.entries(duration) as Array<[keyof Duration, number | undefined]>).reduce((str, [unit, value]) => {
    if (value && value !== 0) {
      const padding = str !== '' ? ' ' : '';
      return str + `${padding}${value}${durationMap[unit][0]}`;
    }

    return str;
  }, '');
}

/**
 * Formats a duration provided in seconds. If smaller than 0, the most suitable
 * unit will be picked. Truncated at precision, not rounded.
 * (Ex.: (0.043678, 2) => '43.67 ms')
 */
export function formatDuration(duration: number, precision = 1): string {
  return duration > 1
    ? limitFloatingPrecision(duration, precision) + ' s'
    : duration * 1000 > 1
    ? limitFloatingPrecision(duration * 1000, precision) + ' ms'
    : limitFloatingPrecision(duration * 1000 * 1000, precision) + ' μs';
}

export function parseDuration(duration: string): Duration {
  return duration.split(' ').reduce<Duration>((acc, value) => {
    const match = value.match(/(\d+)(.+)/);
    if (match === null || match.length !== 3) {
      return acc;
    }

    const key = Object.entries(durationMap).find(([_, abbreviations]) => abbreviations?.includes(match[2]))?.[0];
    return !key ? acc : { ...acc, [key]: match[1] };
  }, {});
}

/**
 * When merging state, avoids arrays from deep merge, instead arrays are overriden.
 */
export function reduxStateMergeCustomizer(objValue: unknown, srcValue: unknown): unknown | undefined {
  return isArray(srcValue) ? srcValue : undefined;
}

/**
 * If it proves unreliable, use slate-plain-serializer instead.
 */
export function serializeSlateValue(value: Value): string {
  const valueAsJson = value.toJSON();
  // @ts-ignore - see: https://docs.slatejs.org/concepts/09-serializing#plaintext
  const nodes = valueAsJson.document.nodes[0].nodes;
  // @ts-ignore
  return nodes.map((node) => node.nodes[0].text).join('\n');
}

export function assertIsDefined<T>(x: T | undefined, msg = 'Unexpected undefined value.'): T {
  if (x !== undefined) {
    return x;
  }
  throw Error(msg);
}

export function deepCopy<A = any>(value: A): A {
  return JSON.parse(JSON.stringify(value));
}
export function jsonStringRepl<A>(obj: A, searchValue: RegExp, replaceValue: string): A {
  return JSON.parse(JSON.stringify(obj).replace(searchValue, replaceValue));
}

export function setYamlParameters() {
  // Increase the YAML line width limit from 80 to 1000 characters.
  // This prevents the automatic folding of strings containing long
  // URLs (more than 80 chararcters), where the '|' character gets
  // switched to a '>'.
  // See https://github.com/eemeli/yaml/blob/v1.10.1/docs/03_options.md#yamlscalaroptions
  yaml.scalarOptions.str.fold.lineWidth = 1000;
}
