import { GrafanaTheme } from '@grafana/data';
import { Tag, useStyles } from '@grafana/ui';
import { OnTagClick } from '@grafana/ui/components/Tags/Tag';
import { css, cx } from 'emotion';
import React, { FC } from 'react';
import { thinBorder } from 'utils/styles';

// no such colors in Tag at the moment...
// @TODO revisit once core is updated to latest design system, Tag should have this palette built-in eventually.
// color for light theme should be `gray25` once colors are updated. Though hopefully Tag will include this, so we dont have to at all
const getStyle = (theme: GrafanaTheme) => css`
  background-color: ${theme.isDark ? theme.palette.gray15 : theme.palette.gray98};
  border: ${thinBorder(theme)};
  color: ${theme.isDark ? theme.palette.gray70 : '#343B40'};
`;

type GrayTagProps = {
  name: string;

  className?: string;
  onClick?: OnTagClick;
};

export const GrayTag: FC<GrayTagProps> = ({ name, className, onClick }) => {
  return <Tag className={cx(useStyles(getStyle), className)} name={name} onClick={onClick} />;
};
