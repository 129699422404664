import { dateTimeFormat, GrafanaTheme } from '@grafana/data';
import { Icon, useStyles } from '@grafana/ui';
import { AlertLabel } from 'components/AlertLabel';
import { GrayTag } from 'components/GrayTag';
import { TagGroup } from 'components/TagGroup';
import { css } from 'emotion';
import React from 'react';
import { SilenceData } from 'types/silencesInternal';
import { SilenceState } from 'utils/enums';
import { padding, marginBottom, marginRight } from 'utils/styles';

type Props = {
  silence: SilenceData;
};

export const getStyles = (theme: GrafanaTheme) => ({
  containerHeader: css`
    display: flex;
    justify-content: space-between;
    ${padding(theme).sm};
  `,
  containerHeaderLeft: css``,
  containerHeaderRight: css``,
  containerMatchers: css`
    padding-top: ${theme.spacing.md};
    padding-bottom: ${theme.spacing.sm};
  `,
  heading: css`
    display: inline-block;
    ${marginBottom(theme).sm};
    ${marginRight(theme).sm};

    color: ${theme.isDark ? theme.palette.white : theme.palette.gray10};
  `,
  headingMatchers: css`
    padding-bottom: ${theme.spacing.xs};

    font-weight: 500;
  `,
  popOutOfExpiredSilence: css`
    position: relative;
    z-index: 1;
  `,
});

export function SilenceHeader({ silence }: Props) {
  const { status, startsAt, endsAt, matchers, createdBy, id, comment } = silence;
  const silenceState = status.state;
  const hasExpired = silenceState === SilenceState.Expired;

  const styles = useStyles(getStyles);

  const matchersElement = matchers && (
    <div className={styles.containerMatchers}>
      <div className={styles.headingMatchers}>Matchers</div>
      <TagGroup>
        {matchers.map((matcher) => (
          <AlertLabel key={matcher.name} labelKey={matcher.name} value={matcher.value} />
        ))}
      </TagGroup>
    </div>
  );

  return (
    <div className={styles.containerHeader}>
      <div className={styles.containerHeaderLeft}>
        <h6 className={styles.heading}>
          {hasExpired ? 'Expired' : 'Ends'} at{' '}
          {dateTimeFormat(endsAt, {
            timeZone: 'browser',
          })}{' '}
        </h6>
        <GrayTag
          className={hasExpired ? styles.popOutOfExpiredSilence : ''}
          name={hasExpired ? 'expired' : silenceState === SilenceState.Pending ? 'pending' : 'active'}
        />
        <br />
        Created by {createdBy} | {hasExpired ? 'Started' : 'Starts'} at{' '}
        {dateTimeFormat(startsAt, {
          timeZone: 'browser',
        })}{' '}
        | ID: {id}
        <br />
        <Icon name="comment-alt" /> {comment}
        {matchersElement}
      </div>
    </div>
  );
}
