import { useStyles } from '@grafana/ui';
import { DelayedSpinner } from 'components/DelayedSpinner';
import React, { useEffect, useState } from 'react';
import { useReduxSelector } from 'store';
import { AlertingRule, RecordingRule, Rules } from 'types/rulesInternal';
import { AlertingRuleState, RuleType } from 'utils/enums';
import RuleGroupComponent from './RuleGroup';
import { RulesOptions } from './RulesOptions';
import { getRuleListStyles } from './styles/ruleList';
import { getSharedStyles } from './styles/shared';
import { RulesSorting } from './utils/enums';

type Props = {
  rules: Rules;
  isEditable: boolean;
  dataSourceName: string;
};

export type RuleLocation = {
  groupIndex: number;
  namespace: string;
  ruleIndex: number;
};

// Used for sorting by states
const alertStateOrder = [AlertingRuleState.Firing, AlertingRuleState.Pending, AlertingRuleState.Inactive];

function sortRules(rules: Array<RecordingRule | AlertingRule>, sortBy: RulesSorting) {
  return sortBy === RulesSorting.Alphabetical
    ? rules.sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
    : sortBy === RulesSorting.State
    ? rules.sort((a, b) => {
        if (a.type === RuleType.Alerting && b.type === RuleType.Alerting) {
          return alertStateOrder.indexOf(a.state) - alertStateOrder.indexOf(b.state);
        }

        // sort recording rules to the bottom
        if (a.type === RuleType.Recording && b.type === RuleType.Alerting) {
          return 1;
        } else if (a.type === RuleType.Alerting && b.type === RuleType.Recording) {
          return -1;
        }
        return a.name.localeCompare(b.name);
      })
    : rules;
}

export function RuleList({ rules, isEditable, dataSourceName }: Props) {
  const [items, setItems] = useState<JSX.Element[] | null>(null);
  const [cntVisibleRules, setCntVisibleRules] = useState(-1);

  const alertStateFilter = useReduxSelector((state) => state.rules.alertStateFilter);
  const showAlertingRules = useReduxSelector((state) => state.rules.showAlertingRules);
  const showRecordingRules = useReduxSelector((state) => state.rules.showRecordingRules);
  const sortBy = useReduxSelector((state) => state.rules.sortBy);
  const recentlyCreatedGroupNames = useReduxSelector((state) => state.rules.newlyCreatedGroupNames);

  const styles = useStyles(getRuleListStyles);
  const sharedStyles = useStyles(getSharedStyles);

  useEffect(() => {
    if (!rules) {
      setItems(null);
    } else {
      const namespaces: JSX.Element[] = [];
      let _cntVisibleRules = 0;

      const showRule = (rule: RecordingRule | AlertingRule) => {
        if (rule.type === RuleType.Alerting && showAlertingRules) {
          return alertStateFilter.includes(rule.state);
        }

        return rule.type === RuleType.Recording && showRecordingRules;
      };

      for (const namespace of Object.keys(rules)) {
        const ruleGroups = rules[namespace];

        const groupComponents: JSX.Element[] = [];
        ruleGroups.forEach((group, index) => {
          const sortedRules = sortRules([...group.rules], sortBy);

          let cntVisibleRulesInGroup = 0;
          sortedRules.forEach((rule) => {
            if (showRule(rule)) {
              cntVisibleRulesInGroup++;
            }
          });

          _cntVisibleRules += cntVisibleRulesInGroup;
          if (cntVisibleRulesInGroup !== 0) {
            const isGroupNew = !!(group.name && recentlyCreatedGroupNames[namespace]?.includes(group.name));

            // Group names aren't unique, so key has to include the index
            groupComponents.push(
              <RuleGroupComponent
                key={group.name + index}
                namespace={namespace}
                isGroupNew={isGroupNew}
                group={group}
                rules={sortedRules}
              />
            );
          }
        });

        // Group by namespace to stay flexible if namespace level
        // things will be required.
        namespaces.push(
          <div className={styles.containerNamespace} key={namespace}>
            {groupComponents}
          </div>
        );
      }

      setCntVisibleRules(_cntVisibleRules);
      setItems(namespaces);
    }
  }, [
    alertStateFilter,
    rules,
    showAlertingRules,
    showRecordingRules,
    sortBy,
    styles.containerHeaderRuleGroup,
    styles.containerNamespace,
    styles.containerRuleGroup,
    styles.headerRuleGroup,
    styles.hr,
    recentlyCreatedGroupNames,
  ]);

  return (
    <>
      {!items ? (
        <DelayedSpinner />
      ) : (
        <>
          <RulesOptions rules={rules} isEditable={isEditable} dataSourceName={dataSourceName} />
          {cntVisibleRules !== 0 ? (
            items
          ) : (
            <h5 className={sharedStyles.headerError}>No matches for this filter setting.</h5>
          )}
        </>
      )}
    </>
  );
}
