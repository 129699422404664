import { UrlQueryMap } from '@grafana/data';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

export type UpdateQueryParams = (params: UrlQueryMap, replace?: boolean) => void;

export type NavContextValue = {
  queryParams: UrlQueryMap;
  updateQueryParams: UpdateQueryParams;
};

export const NavContext = React.createContext<NavContextValue>({
  queryParams: {},
  updateQueryParams: () => {},
});

export const NavProvider: FC<NavContextValue> = ({ queryParams, updateQueryParams, children }) => {
  const value = useMemo(
    (): NavContextValue => ({
      queryParams,
      updateQueryParams,
    }),
    [queryParams, updateQueryParams]
  );

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
};

type InMemoryNavProviderProps = {
  initialQueryParams?: UrlQueryMap;
  onUpdateQueryParams?: (queryParams: UrlQueryMap) => void;
};

export const InMemoryNavProvider: FC<InMemoryNavProviderProps> = ({
  initialQueryParams,
  onUpdateQueryParams,
  children,
}) => {
  const [queryParams, setQueryParams] = useState(initialQueryParams || {});

  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const updateQueryParams = useCallback(
    (params: UrlQueryMap) => {
      setImmediate(() => {
        if (isMountedRef.current) {
          setQueryParams((existing) => ({
            ...existing,
            ...params,
          }));
          if (onUpdateQueryParams) {
            onUpdateQueryParams(params);
          }
        }
      });
    },
    [onUpdateQueryParams, setQueryParams]
  );

  return (
    <NavProvider queryParams={queryParams} updateQueryParams={updateQueryParams}>
      {children}
    </NavProvider>
  );
};
