import { Icon, useStyles } from '@grafana/ui';
import { cx } from 'emotion';
import React, { FC } from 'react';
import { RuleLocation } from 'store/rules/thunks';
import { trackEvent } from 'utils/tracking';
import { RuleMeta } from '../EditRuleList';
import { getRuleHeaderStyles } from '../styles/ruleHeader';
import { MoveRuleSelect } from './MoveRuleSelect';

export type Props = {
  onDelete: () => void;
  onStartEditing: () => void;
  onMove: (location: RuleLocation) => void;
  ruleMeta: RuleMeta;
};

export const EditRuleHeaderControls: FC<Props> = ({ onDelete, onStartEditing, ruleMeta, onMove }) => {
  const styles = useStyles(getRuleHeaderStyles);

  const moveUp = () => {
    trackEvent(`Click move rule up arrow`);
    onMove({ ...ruleMeta.location, ruleIndex: ruleMeta.location.ruleIndex - 1 });
  };
  const moveDown = () => {
    trackEvent(`Click move rule down arrow`);

    // +2 is not a bug: rule will be inserted at the specified index position, increasing position of all subsequent rules by 1.
    // +1 would move the next rule up and in it's place put a copy of this rule, then remove the previous instance of this rule.
    // in the end order of rules would not change
    onMove({
      ...ruleMeta.location,
      ruleIndex: ruleMeta.location.ruleIndex + 2,
    });
  };
  const moveViaSelect = (destination: RuleLocation) => {
    trackEvent(`Click move rule via dropdown`);
    onMove(destination);
  };

  return (
    <div className={cx(styles.containerRight, styles.editControls)}>
      <div className={styles.editControl}>
        <MoveRuleSelect onChange={moveViaSelect} ruleMeta={ruleMeta} />
      </div>
      <div className={cx(styles.editControl, styles.rearrangeArrowsContainer)}>
        {!ruleMeta.isFirstInGroup && (
          <a onClick={moveUp} title="Move rule up">
            <Icon className={styles.rearrangeArrow} name="arrow-up" />
          </a>
        )}
        {!ruleMeta.isLastInGroup && (
          <a onClick={moveDown} title="Move rule down">
            <Icon className={styles.rearrangeArrow} name="arrow-down" />
          </a>
        )}
      </div>
      <a onClick={onDelete} className={styles.editControl}>
        Delete
      </a>
      <a onClick={onStartEditing} className={styles.editControl}>
        Edit
      </a>
    </div>
  );
};
