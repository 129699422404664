import React, { useEffect, useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { createStore } from 'store';
import { PromiseType } from 'utility-types';

export const AsyncReduxProvider: React.FC = ({ children }) => {
  const [store, setStore] = useState<PromiseType<ReturnType<typeof createStore>>>();

  useEffect(() => {
    let canceled = false;

    async function createStoreAsync() {
      const _store = await createStore();
      if (!canceled) {
        setStore(_store);
      }
    }
    createStoreAsync();

    // Doing this is actually important because AppRoot is quickly mounted
    // twice on initial page load.
    return () => {
      canceled = true;
    };
  }, []);

  if (!store) {
    return null;
  }

  return <ReduxProvider store={store}>{children}</ReduxProvider>;
};
