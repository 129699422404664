import { createExploreLink } from 'utils/misc';
import { exprLinkStyle } from '../styles/rule';

// Searches for `expr:` in the provided code element and replaces the
// string that follows it with a link constructed based on `dataSourceName`
// and `query` provided.
export function linkExprToExplore(codeElement: HTMLDivElement, dataSourceName: string, query: string) {
  function createLinkElement(linkContent: ChildNode[]) {
    const link = document.createElement('a');

    link.href = createExploreLink(dataSourceName, query);
    link.target = '_blank';
    link.className = exprLinkStyle;

    for (const node of linkContent) {
      link.appendChild(node);
    }

    // Padding to the left of the expression should not be inside of the link => remove it.
    const firstChild = link.firstChild;
    if (firstChild !== null) {
      firstChild.textContent =
        firstChild.textContent !== null ? firstChild.textContent.trimLeft() : firstChild.textContent;
    }

    return link;
  }

  let nodes: ChildNode[] = [];
  let doDeleteNodes = false;
  let skipNodesAfterExpr = 1;
  Array.from(codeElement.childNodes).find((child, idx) => {
    if (doDeleteNodes) {
      if (skipNodesAfterExpr > 0) {
        skipNodesAfterExpr--;
        return false;
      }

      // Is the current child the next prop after 'expr'?
      if (child instanceof HTMLSpanElement && child.className === 'token key atrule') {
        const link = createLinkElement(nodes);

        codeElement.insertBefore(link, child);
        codeElement.insertBefore(document.createTextNode(' '), link);

        return true;
      }

      nodes.push(child);

      // Is `expr` the last property in the rule code block?
      if (idx === codeElement.childNodes.length - 1) {
        const link = createLinkElement(nodes);

        codeElement.appendChild(link);
        codeElement.insertBefore(document.createTextNode(' '), link);

        return true;
      }
    }

    if (child.textContent === 'expr') {
      doDeleteNodes = true;
    }

    return false;
  });
}
