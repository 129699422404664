import { urlUtil } from '@grafana/data';
import { AlertmanagerAlertPayload } from 'types/alertsExternal';
import { AlertmanagerAlert } from 'types/alertsInternal';
import { SilenceCreationPayload, SilenceMatcher, SilencePayload } from 'types/silencesExternal';
import { SilenceData } from 'types/silencesInternal';
import { transformDatesInPayload } from 'utils/alertmanager/transformations';
import { request } from 'utils/datasource';
import { AlertManagerEndpoint } from 'utils/enums';

function escapeQuotes(value: string): string {
  return value.replace(/"/g, '\\"');
}

type Request = Parameters<typeof request>;
export function alertmanagerRequest(dataSourceName: string, url: Request[1], options?: Request[2]) {
  return request(dataSourceName, url, options);
}

export function createOrUpdateSilence(datasourceName: string, data: SilenceCreationPayload): Promise<unknown> {
  return alertmanagerRequest(datasourceName, `${AlertManagerEndpoint.Silences}`, {
    method: 'POST',
    data,
  });
}

export function expireSilence(dataSourceName: string, id: string): Promise<unknown> {
  return alertmanagerRequest(dataSourceName, `${AlertManagerEndpoint.Silence}/${id}`, {
    method: 'DELETE',
  });
}

export async function fetchAlerts(dataSourceName: string, matchers?: SilenceMatcher[]): Promise<AlertmanagerAlert[]> {
  const filters =
    matchers
      ?.map(
        (matcher) =>
          `filter=${encodeURIComponent(
            `${escapeQuotes(matcher.name)}=${matcher.isRegex ? '~' : ''}"${escapeQuotes(matcher.value)}"`
          )}`
      )
      .join('&') || '';

  const { data }: { data: AlertmanagerAlertPayload[] } = await alertmanagerRequest(
    dataSourceName,
    urlUtil.appendQueryToUrl(AlertManagerEndpoint.Alerts, filters)
  );
  return transformDatesInPayload(data);
}

export async function fetchSilences(dataSourceName: string): Promise<SilenceData[]> {
  return transformDatesInPayload(
    (await alertmanagerRequest(dataSourceName, AlertManagerEndpoint.Silences)).data as SilencePayload[]
  );
}
