import { GrafanaTheme } from '@grafana/data';
import { IconButton, useTheme } from '@grafana/ui';
import { css } from 'emotion';
import React, { ComponentProps, FC } from 'react';
import { marginRight } from 'utils/styles';

type Props = Omit<ComponentProps<typeof IconButton>, 'name'> & {
  onClick: () => void;

  isCollapsed?: boolean;
};

const style = (theme: GrafanaTheme) => css`
  align-self: center;

  ${marginRight(theme).sm};
`;

export const CollapseButton: FC<Props> = ({ isCollapsed, onClick, ...restProps }) => {
  return (
    <IconButton
      className={style(useTheme())}
      size="xl"
      name={isCollapsed ? 'angle-right' : 'angle-down'}
      onClick={onClick}
      {...restProps}
    />
  );
};
