import { AppEvents, GrafanaTheme } from '@grafana/data';
import { Field, Icon, InfoBox, Input, useStyles } from '@grafana/ui';
import { Button } from 'components/Button';
import { css } from 'emotion';
import appEvents from 'grafana/app/core/app_events';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { AlertmanagerConfig } from 'types/alertmanager';
import { ParsedCortexAlertmanagerConfig } from 'utils/alertmanager/hooks';
import { text } from 'utils/consts';
import { Color } from 'utils/enums';
import { jsonStringRepl } from 'utils/misc';
import { fontSize } from 'utils/styles';
import { trackEvent } from 'utils/tracking';
import { GlobalConfigReadModeProps } from './GlobalConfigReadMode';

type Props = Pick<GlobalConfigReadModeProps, 'updateConfig' | 'alertmanagerConfig'>;

const GRAFANA_EXAMPLE_EMAIL = '<example@email.com>';
const GRAFANA_SMTP_DOCS_URL =
  'https://grafana.com/docs/grafana-cloud/alerts/grafana-cloud-alerting/alertmanager/#use-the-grafana-labs-supplied-smtp-option';

export const grafanaSmtpConfig = {
  smtp_from: 'noreply@grafana.net',
  smtp_smarthost: 'smtprelay:2525',
  smtp_require_tls: false,
} as const;

const getStyles = (theme: GrafanaTheme) => ({
  infoBox: css`
    ${fontSize(theme).base};
  `,
  successMessage: css`
    color: ${theme.isDark ? Color.GreenLightest : Color.GreenDarkest};
  `,
});

function generateUpdatedConfig(alertmanagerConfig: ParsedCortexAlertmanagerConfig, email?: string): AlertmanagerConfig {
  const conf: AlertmanagerConfig = email
    ? jsonStringRepl(alertmanagerConfig.config, new RegExp(GRAFANA_EXAMPLE_EMAIL, 'g'), email)
    : alertmanagerConfig.config;

  const emailReceiver = {
    name: 'email',
    email_configs: [{ to: email || '<example@email.com>' }],
  };

  // add route if not present
  conf.route = conf.route || {
    receiver: 'email',
  };

  // update global smtp config
  conf.global = {
    ...alertmanagerConfig.config.global,
    ...grafanaSmtpConfig,
  };

  // add email receiver if not present
  if (conf.receivers && !conf.receivers.find((receiver) => receiver.name === 'email')) {
    conf.receivers.push(emailReceiver);
  } else if (!conf.receivers) {
    conf.receivers = [emailReceiver];
  }

  return conf;
}

export const GrafanaSmtpConfig: React.FC<Props> = ({ alertmanagerConfig, updateConfig }) => {
  const styles = useStyles(getStyles);

  const [wasUpdateSuccessful, setWasUpdateSuccessful] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { register, errors, handleSubmit } = useForm();

  if (!alertmanagerConfig) {
    return null;
  }

  const globalConfig = alertmanagerConfig.config.global || {};
  const hasExampleEmail = JSON.stringify(alertmanagerConfig.config).includes(GRAFANA_EXAMPLE_EMAIL);
  const hasGrafanaSmtp =
    'smtp_smarthost' in globalConfig && globalConfig.smtp_smarthost === grafanaSmtpConfig.smtp_smarthost;

  const submitForm = async (email?: string) => {
    setIsUpdating(true);
    try {
      await updateConfig(generateUpdatedConfig(alertmanagerConfig, email));
      trackEvent('Click button to use grafana SMTP config');
      setWasUpdateSuccessful(true);
    } catch (e) {
      appEvents.emit(AppEvents.alertError, [text.toast.amConfigFailure]);
    }
    setIsUpdating(false);
  };

  const addSmtpButton = (hasExampleEmail || !hasGrafanaSmtp) && (
    <Button type="submit" isLoading={isUpdating}>
      {text.globalConfig.addGrafanaServerButton}
    </Button>
  );

  const updateMessage = wasUpdateSuccessful && (
    <div className={styles.successMessage}>
      <Icon name="check" /> {text.globalConfig.addGrafanaServerSuccess}
    </div>
  );

  return (
    <InfoBox url={GRAFANA_SMTP_DOCS_URL} title={text.globalConfig.grafanaSmtpTitle} className={styles.infoBox}>
      {addSmtpButton || updateMessage ? (
        <>
          {text.globalConfig.grafanaSmtpDescription}
          <br />
          <br />
          <form
            onSubmit={handleSubmit((formData: { email?: string }) => submitForm(formData.email))}
            style={{ maxWidth: '250px' }}
          >
            {!updateMessage && (
              <Field label="Email address" invalid={!!errors.email} error="Email address is required">
                <Input
                  type="email"
                  id="email"
                  name="email"
                  ref={register({ required: true })}
                  placeholder="Email address"
                />
              </Field>
            )}
            {addSmtpButton}
            {updateMessage}
          </form>
        </>
      ) : (
        text.globalConfig.grafanaSmtpAddedDescription
      )}
    </InfoBox>
  );
};
