import { ConfirmModal } from '@grafana/ui';
import React, { FC } from 'react';
import { text } from 'utils/consts';

type Props = {
  receiverName: string;
  numRoutesAffected: number;
  onDismiss: () => void;
  onConfirm: () => void;
};

export const ConfirmDeleteReceiverModal: FC<Props> = ({ receiverName, numRoutesAffected, onConfirm, onDismiss }) => {
  return (
    <ConfirmModal
      isOpen
      title={text.receivers.deleteReceiverModalTitle(receiverName)}
      body={
        numRoutesAffected
          ? text.receivers.deleteReceiverModalBodyWithRoutes(receiverName, numRoutesAffected)
          : text.receivers.deleteReceiverModalBodyNoRoutes(receiverName)
      }
      confirmText={text.receivers.deleteReceiverModalConfirmButton}
      onConfirm={onConfirm}
      dismissText={text.receivers.deleteReceiverModalCancelButton}
      onDismiss={onDismiss}
    />
  );
};
