import { GrafanaTheme } from '@grafana/data';
import { css } from 'emotion';
import { AlertingRuleState, Color } from 'utils/enums';

export const exprLinkStyle = css`
  text-decoration: underline;
`;

const gradients = {
  [AlertingRuleState.Firing]: {
    left: Color.Red,
    right: Color.RedLightest,
  },
  [AlertingRuleState.Inactive]: {
    left: Color.Green,
    right: Color.GreenLightest,
  },
  [AlertingRuleState.Pending]: {
    left: Color.Orange,
    right: Color.OrangeLightest,
  },
};

export const getGradientStyle = (alertState: AlertingRuleState) => css`
  &::before {
    display: block;
    height: 2px;
    content: ' ';

    position: absolute;
    left: 0;
    right: 0;
    /* Needed for gradient to cover border */
    top: -1px;

    border-radius: 2px;
    background-image: linear-gradient(90deg, ${gradients[alertState].left} 0%, ${gradients[alertState].right} 100%);
  }
`;

export const getSharedRuleStyles = (theme: GrafanaTheme) => ({
  exprLink: css`
    text-decoration: underline;
  `,
});
