import { AlertmanagerCortexConfig, AlertmanagerStatusPayload } from 'types/alertmanager';
import { isFetchError } from 'utils/api';
import { request } from 'utils/datasource';
import { AlertManagerEndpoint } from 'utils/enums';
import yaml from 'yaml';

const noConfigErrorMessages = ['the Alertmanager is not configured', 'alertmanager config not found'];

const isNoConfigError = (e: unknown) =>
  isFetchError(e) && e.status === 404 && !!noConfigErrorMessages.find((msg) => e.data.message.includes(msg));

export async function getConfig(datasourceName: string): Promise<AlertmanagerCortexConfig | undefined> {
  try {
    const result = await request(datasourceName, AlertManagerEndpoint.Config);
    return yaml.parse(result.data);
  } catch (e) {
    if (isNoConfigError(e)) {
      return undefined;
    }
    throw e;
  }
}

export async function saveConfig(datasourceName: string, config: AlertmanagerCortexConfig): Promise<void> {
  await request(datasourceName, AlertManagerEndpoint.Config, {
    data: yaml.stringify(config),
    headers: {
      'Content-Type': 'application/yaml',
    },
    method: 'POST',
  });
}

export async function deleteConfig(datasourceName: string): Promise<void> {
  await request(datasourceName, AlertManagerEndpoint.Config, {
    method: 'DELETE',
  });
}

export async function getStatus(datasourceName: string): Promise<AlertmanagerStatusPayload | undefined> {
  try {
    const result = await request(datasourceName, AlertManagerEndpoint.Status);
    return result.data;
  } catch (e) {
    if (isNoConfigError(e)) {
      return undefined;
    }
    throw e;
  }
}
