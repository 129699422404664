import { Button, useTheme } from '@grafana/ui';
import { Options } from 'components/Options';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { rulesActions } from 'store/rules';
import { trackEvent } from 'utils/tracking';

type Props = {
  isFetchRulesDone: boolean;
  showAddGroupButton?: boolean;
};

export const EditOptions: FC<Props> = ({ isFetchRulesDone, showAddGroupButton }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const onClickFinishEditing = () => {
    trackEvent('Click finish editing');
    dispatch(rulesActions.setIsEditingRules(false));
  };

  const addGroup = useCallback(() => {
    trackEvent('Click add group');
    dispatch(rulesActions.addGroup());
  }, [dispatch]);

  const leftContent = !isFetchRulesDone ? (
    // placeholder for layout purposes
    <div />
  ) : showAddGroupButton ? (
    <Button variant="secondary" icon="plus" onClick={addGroup}>
      Create new rule group
    </Button>
  ) : (
    <div style={{ fontSize: theme.typography.size.base }}>
      You are currently in rule editing mode. Finish editing to go back to the alert rules list.
    </div>
  );

  return (
    <Options>
      {leftContent}
      <Button onClick={onClickFinishEditing}>Finish editing</Button>
    </Options>
  );
};
