import { dateTime, DefaultTimeZone, GrafanaTheme, TimeZone, toUtc } from '@grafana/data';
import { config } from '@grafana/runtime';
import { Field, Input, Legend, TextArea, useStyles } from '@grafana/ui';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button } from 'components/Button';
import add from 'date-fns/add';
import intervalToDuration from 'date-fns/intervalToDuration';
import { css } from 'emotion';
import { isUndefined } from 'lodash';
import React, { MouseEventHandler, useMemo, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'react-use';
import { AppDispatch } from 'store';
import { createOrUpdateSilenceThunk } from 'store/silences/thunks';
import { SilenceCreationPayload } from 'types/silencesExternal';
import { INPUT_DEBOUNCE_DELAY, text } from 'utils/consts';
import { useSelectedAlertManagerName } from 'utils/hooks';
import { durationToAbbreviatedString, isValidDate, parseDuration, assertIsDefined } from 'utils/misc';
import { marginRight } from 'utils/styles';
import { trackEvent } from 'utils/tracking';
import { AlertsPreview } from './AlertsPreview';
import { Matchers } from './Matchers';
import { SilencePeriod } from './SilencePeriod';
import { fetchAlerts } from './utils/api';

export type SilenceEditorProps = {
  onSubmit: () => void;

  defaultValues?: Partial<SilenceEditorValues>;
};

export type SilenceEditorValues = SilenceCreationPayload & {
  duration: string;
  timeZone: TimeZone;
};

type EmptyFormValues = Omit<SilenceEditorValues, 'id'>;

const getStyles = (theme: GrafanaTheme) => ({
  buttons: css`
    & > button {
      ${marginRight(theme).sm};
    }
  `,
  input: css`
    width: 200px;
  `,
});

export const defaultDuration: Readonly<Duration> = { hours: 2 };

const generateEmptyFormValues = (): EmptyFormValues => {
  const start = new Date();
  const end = add(start, defaultDuration);
  const duration = intervalToDuration({
    start,
    end,
  });

  return {
    comment: '',
    matchers: [{ isRegex: false, name: '', value: '' }],
    createdBy: config.bootData.user.name,
    startsAt: start.toISOString(),
    endsAt: end.toISOString(),
    duration: durationToAbbreviatedString(duration),
    timeZone: DefaultTimeZone,
  };
};

export const SilenceEditor = React.memo(function SilenceEditor({ defaultValues, onSubmit }: SilenceEditorProps) {
  const defaultValuesWithCurrentUser: Partial<SilenceEditorValues> = useMemo(
    () => ({
      ...generateEmptyFormValues(),
      ...defaultValues,
    }),
    [defaultValues]
  );
  const isEditingExistingSilence = Boolean(defaultValues?.id);

  const selectedAlertmanagerName = assertIsDefined(useSelectedAlertManagerName());

  const dispatch: AppDispatch = useDispatch();

  const {
    control,
    clearErrors,
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm<SilenceEditorValues>({
    defaultValues: defaultValuesWithCurrentUser,
  });

  const styles = useStyles(getStyles);

  const fetchMatchingAlerts = useAsyncCallback(async () => {
    const matchers = getValues().matchers?.filter((matcher) => matcher.name !== '' && matcher.value !== '');
    if (matchers && matchers.length > 0 && selectedAlertmanagerName) {
      return fetchAlerts(selectedAlertmanagerName, matchers);
    }

    throw new Error(text.error.silencesMissingMatchers);
  });

  const duration = watch('duration');
  const startsAt = watch('startsAt');
  const endsAt = watch('endsAt');

  // Keep duration and endsAt in sync
  const [prevDuration, setPrevDuration] = useState(duration);
  useDebounce(
    () => {
      if (isValidDate(startsAt) && isValidDate(endsAt)) {
        if (duration !== prevDuration) {
          setValue('endsAt', dateTime(add(new Date(startsAt), parseDuration(duration))));
          setPrevDuration(duration);
        } else {
          const startValue = new Date(startsAt).valueOf();
          const endValue = new Date(endsAt).valueOf();
          if (endValue > startValue) {
            const nextDuration = durationToAbbreviatedString(
              intervalToDuration({
                start: new Date(startsAt),
                end: new Date(endsAt),
              })
            );
            setValue('duration', nextDuration);
            setPrevDuration(nextDuration);
          }
        }
      }
    },
    INPUT_DEBOUNCE_DELAY,
    [clearErrors, duration, endsAt, prevDuration, setValue, startsAt]
  );

  // BEGIN EVENT HANDLERS
  const clearForm: MouseEventHandler = () => {
    trackEvent('Click clear form (Silences)');

    const emptyFormValues = generateEmptyFormValues();
    for (const key of Object.keys(emptyFormValues) as Array<keyof EmptyFormValues>) {
      setValue(key, emptyFormValues[key]);
    }

    fetchMatchingAlerts.reset();
  };

  const createOrUpdateSilenceHandler = useAsyncCallback(async (formData: SilenceEditorValues) => {
    trackEvent(isUndefined(defaultValues) ? 'Submit create silence form' : 'Submit recreate/edit silence form');

    const { duration, timeZone, ...payload } = formData;
    if (defaultValues?.id) {
      payload.id = defaultValues.id;
    }

    payload.startsAt = toUtc(payload.startsAt).toISOString();
    payload.endsAt = toUtc(payload.endsAt).toISOString();

    dispatch(createOrUpdateSilenceThunk({ dataSourceName: selectedAlertmanagerName, data: payload }))
      .then(unwrapResult)
      .then(onSubmit)
      .catch((error) => {
        // error handling for rejected thunk in createOrUpdateSilenceThunk()
        if (error?.message !== 'Rejected') {
          throw error;
        }
      });
  });

  const previewAlerts: React.MouseEventHandler = () => {
    trackEvent('Click preview alerts');

    fetchMatchingAlerts.execute();
  };
  // END EVENT HANDLERS

  return (
    <form onSubmit={handleSubmit(createOrUpdateSilenceHandler.execute)}>
      <fieldset>
        <Legend>{isEditingExistingSilence ? 'Edit silence' : 'New silence'}</Legend>

        <SilencePeriod control={control} />

        <Field
          label="Duration"
          invalid={!!errors.duration}
          error={errors.duration && (errors.duration.type === 'required' ? 'Required field' : errors.duration.message)}
        >
          <Input
            className={styles.input}
            id="duration"
            name="duration"
            ref={register({
              validate: (value) =>
                Object.keys(parseDuration(value)).length === 0
                  ? 'Invalid duration. Valid example: 1d 4h (Available units: y, M, w, d, h, m, s)'
                  : undefined,
            })}
          />
        </Field>

        <Matchers control={control} errors={errors} register={register} />

        <Field label="Creator" invalid={!!errors.createdBy} error="Creator is required">
          <Input className={styles.input} id="createdBy" name="createdBy" ref={register({ required: true })} />
        </Field>
        <Field label="Comment" invalid={!!errors.comment} error="Comment is required">
          <TextArea rows={3} id="comment" name="comment" ref={register({ required: true })} />
        </Field>
        <div className={styles.buttons}>
          <Button type="submit" isLoading={createOrUpdateSilenceHandler.loading}>
            {isEditingExistingSilence ? 'Update' : 'Create'}
          </Button>
          <Button type="button" variant="secondary" onClick={previewAlerts} isLoading={fetchMatchingAlerts.loading}>
            Show affected notifications
          </Button>
          <Button
            type="button"
            variant="destructive"
            disabled={createOrUpdateSilenceHandler.loading}
            onClick={clearForm}
          >
            Clear form
          </Button>
        </div>

        <AlertsPreview
          error={fetchMatchingAlerts.error}
          matchingAlerts={fetchMatchingAlerts.result}
          status={fetchMatchingAlerts.status}
        />
      </fieldset>
    </form>
  );
});
