import { GrafanaTheme } from '@grafana/data';
import { Checkbox, FieldValidationMessage, Input, useStyles } from '@grafana/ui';
import { Button } from 'components/Button';
import { css } from 'emotion';
import React, { FC, useEffect } from 'react';
import { useFieldArray, UseFormMethods } from 'react-hook-form';
import { SilenceCreationPayload, SilenceMatcher } from 'types/silencesExternal';
import { fontWeight, marginBottom, marginTop, padding } from 'utils/styles';

type Props = Pick<UseFormMethods<SilenceCreationPayload>, 'control' | 'errors' | 'register'>;

const getStyles = (theme: GrafanaTheme) => ({
  error: css`
    &:before {
      border-bottom: 0;
    }
  `,
  matchersLabel: css`
    color: ${theme.isDark ? theme.palette.gray70 : theme.palette.gray33};
    ${fontWeight(theme).semibold};
  `,
  table: css`
    width: 100%;

    & tr {
      line-height: initial;
    }

    & tbody > tr:nth-child(odd) {
      background: ${theme.isDark ? theme.palette.gray15 : theme.palette.gray98};
    }

    & th {
      ${padding(theme).sm};
    }

    & td {
      ${padding(theme).xs};
    }

    & td:nth-child(1) {
      width: 20%;
    }

    & td:nth-child(2) {
      width: 100%;
    }

    & td:nth-child(3) {
      display: flex;
      justify-content: center;
      ${marginTop(theme).xs};
    }
  `,

  // CONTAINERS
  containerTable: css`
    ${marginBottom(theme).sm};
  `,
});

export const Matchers: FC<Props> = ({ control, errors, register }) => {
  const matchers = useFieldArray<SilenceMatcher>({
    control,
    name: 'matchers',
  });

  const styles = useStyles(getStyles);

  useEffect(() => {
    if (matchers.fields.length === 0) {
      matchers.append({ isRegex: false, name: '', value: '' }, false);
    }
  }, [matchers.fields]); // eslint-disable-line

  return (
    <div className={styles.containerTable}>
      <div className={styles.matchersLabel}>Matchers - notifications affected by this silence</div>
      {errors.matchers && (
        <FieldValidationMessage className={styles.error}>
          Names and values for matchers are required
        </FieldValidationMessage>
      )}
      <table className={styles.table}>
        <thead>
          <tr>
            <th id="matchersNameHeading">Name</th>
            <th id="matchersValueHeading">Value</th>
            <th id="matchersRegexHeading">Regex</th>
          </tr>
        </thead>
        <tbody>
          {matchers.fields.map((field, index) => (
            <tr key={field.id}>
              <td>
                <Input
                  aria-labelledby="matchersNameHeading"
                  defaultValue={field.name}
                  name={`matchers[${index}].name`}
                  ref={register({ required: true })}
                />
              </td>
              <td>
                <Input
                  aria-labelledby="matchersValueHeading"
                  defaultValue={field.value}
                  name={`matchers[${index}].value`}
                  ref={register({ required: true })}
                />
              </td>
              <td>
                <Checkbox
                  aria-labelledby="matchersRegexHeading"
                  defaultChecked={field.isRegex}
                  name={`matchers[${index}].isRegex`}
                  ref={register()}
                />
              </td>
              <td>
                {matchers.fields.length > 1 && (
                  <Button
                    variant="link"
                    onClick={(e) => {
                      e.preventDefault();
                      matchers.remove(index);
                    }}
                  >
                    Delete
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Button
        variant="link"
        icon="plus"
        onClick={(e) => {
          e.preventDefault();
          matchers.append({ isRegex: false, name: '', value: '' }, false);
        }}
      >
        Add matcher
      </Button>
    </div>
  );
};
