import { GrafanaTheme } from '@grafana/data';
import { useStyles } from '@grafana/ui';
import { css, cx } from 'emotion';
import React, { FC, useRef, useLayoutEffect } from 'react';
import { useSyntaxHighlighting } from 'utils/hooks';
import { thinBorder } from 'utils/styles';

export type YMLCodeProps = {
  value: string;
  linkToExplore?: string;
};

export const getStyles = (theme: GrafanaTheme) => ({
  pre: css`
    margin: 0;
    border: ${thinBorder(theme)};
    padding: 6px 8px; // align with grafana slate style to prevent padding "jump" when opening edit mode
  `,
  code: css`
    margin: 0;
  `,
});

export const YMLCode: FC<YMLCodeProps> = ({ value, linkToExplore }) => {
  const codeRef = useRef<HTMLDivElement>(null);
  const styles = useStyles(getStyles);

  const highlight = useSyntaxHighlighting();

  useLayoutEffect(() => {
    if (codeRef.current) {
      highlight(codeRef.current, linkToExplore);
    }
  }, [linkToExplore, highlight, value]);

  return (
    <div className="slate-query-field">
      <pre className={styles.pre}>
        <code data-testid="code" className={cx(styles.code, 'language-yml')} ref={codeRef}>
          {value}
        </code>
      </pre>
    </div>
  );
};
