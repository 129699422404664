import { GrafanaTheme } from '@grafana/data';
import { RadioButtonGroup, useStyles, Button } from '@grafana/ui';
import { Options } from 'components/Options';
import { StatusColoredText } from 'components/StatusColoredText';
import { SwitchWithLabel } from 'components/SwitchWithLabel';
import { css } from 'emotion';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useReduxSelector } from 'store';
import { rulesActions } from 'store/rules';
import { Rules } from 'types/rulesInternal';
import { isAdmin } from 'utils/consts';
import { AlertingRuleState, Color } from 'utils/enums';
import { fontSize, fontWeight } from 'utils/styles';
import { trackEvent } from 'utils/tracking';
import { EditRulesButton } from './EditRulesButton';
import { RulesSorting } from './utils/enums';
import { getAlertsStats } from './utils/transformations';

export type Props = {
  rules: Rules;
  isEditable: boolean;
  dataSourceName: string;
};

const {
  setSortBy,
  toggleAlertStateFilter,
  toggleShowAlertingRules,
  toggleShowAnnotations,
  toggleShowRecordingRules,
  toggleIsFullyExpanded,
} = rulesActions;

const sortByOptions = [
  { label: 'None', value: RulesSorting.None },
  { label: 'A-Z', value: RulesSorting.Alphabetical },
  { label: 'Alert state', value: RulesSorting.State },
];

const getStyles = (theme: GrafanaTheme) => ({
  containerRuleSorting: css`
    min-width: 224px;

    label {
      white-space: nowrap;
    }
  `,
  headerGroup: css`
    ${fontSize(theme).sm};
    ${fontWeight(theme).semibold};
  `,
  labelFiring: css`
    color: ${theme.isDark ? Color.RedLightest : Color.RedDarkest};
  `,
  labelInactive: css`
    color: ${theme.isDark ? Color.GreenLightest : Color.GreenDarkest};
  `,
  labelPending: css`
    color: ${theme.isDark ? Color.OrangeLightest : Color.OrangeDarkest};
  `,
});

export function RulesOptions({ rules, isEditable, dataSourceName }: Props) {
  const dispatch = useDispatch();

  const alertStateFilter = useReduxSelector((state) => state.rules.alertStateFilter);
  const showAlertingRules = useReduxSelector((state) => state.rules.showAlertingRules);
  const showRecordingRules = useReduxSelector((state) => state.rules.showRecordingRules);
  const showAnnotations = useReduxSelector((state) => state.rules.showAnnotations);
  const sortBy = useReduxSelector((state) => state.rules.sortBy);
  const isFullyExpanded = useReduxSelector((state) => state.rules.isFullyExpanded);
  const disabledSortByOptions = !showAlertingRules ? [RulesSorting.State] : [];

  const styles = useStyles(getStyles);

  const alertsStats = getAlertsStats(rules);
  const {
    [AlertingRuleState.Firing]: cntFiring,
    [AlertingRuleState.Inactive]: cntInactive,
    [AlertingRuleState.Pending]: cntPending,
  } = alertsStats;

  const toggleStateFilter = (state: AlertingRuleState) => {
    trackEvent(`Toggle ${state} filter`, String(!alertStateFilter.includes(state)));
    dispatch(toggleAlertStateFilter(state));
  };

  const onToggleShowAlertingRules = () => {
    trackEvent('Toggle alerting rules filter', String(!showAlertingRules));
    dispatch(toggleShowAlertingRules());
  };

  const onToggleShowRecordingRules = () => {
    trackEvent('Toggle recording rules filter', String(!showRecordingRules));
    dispatch(toggleShowRecordingRules());
  };

  const onToggleShowAnnotations = () => {
    trackEvent('Toggle annotations', String(!showAnnotations));
    dispatch(toggleShowAnnotations());
  };

  const onSortChange = (sortBy: RulesSorting | undefined) => {
    trackEvent('Click sort rules', sortBy);
    dispatch(setSortBy(sortBy!));
  };

  const onToggleExpandCollapse = () => {
    trackEvent('Toggle expand collapse');
    dispatch(toggleIsFullyExpanded());
  };

  return (
    <Options>
      <div>
        <h6 className={styles.headerGroup}>Filter by alert state</h6>
        <SwitchWithLabel
          value={alertStateFilter.includes(AlertingRuleState.Firing)}
          onChange={() => toggleStateFilter(AlertingRuleState.Firing)}
        >
          <StatusColoredText status={AlertingRuleState.Firing}>Firing ({cntFiring})</StatusColoredText>
        </SwitchWithLabel>
        <SwitchWithLabel
          value={alertStateFilter.includes(AlertingRuleState.Pending)}
          onChange={() => toggleStateFilter(AlertingRuleState.Pending)}
        >
          <StatusColoredText status={AlertingRuleState.Pending}>Pending ({cntPending})</StatusColoredText>
        </SwitchWithLabel>
        <SwitchWithLabel
          value={alertStateFilter.includes(AlertingRuleState.Inactive)}
          onChange={() => toggleStateFilter(AlertingRuleState.Inactive)}
        >
          <StatusColoredText status={AlertingRuleState.Inactive}>Inactive ({cntInactive})</StatusColoredText>
        </SwitchWithLabel>
      </div>
      <div>
        <h6 className={styles.headerGroup}>Filter by rule type</h6>
        <SwitchWithLabel value={showAlertingRules} onChange={onToggleShowAlertingRules}>
          Alerting rules
        </SwitchWithLabel>
        <SwitchWithLabel value={showRecordingRules} onChange={onToggleShowRecordingRules}>
          Recording rules
        </SwitchWithLabel>
      </div>
      <div>
        <h6 className={styles.headerGroup}>View options</h6>
        <SwitchWithLabel value={showAnnotations} onChange={onToggleShowAnnotations}>
          Show annotations
        </SwitchWithLabel>
      </div>
      <div className={styles.containerRuleSorting}>
        <h6 className={styles.headerGroup}>Rule sorting</h6>
        {/* TODO: RadioButtonGroup can never trigger onChange with an undefined value - should be fixed in @grafana/ui */}
        <RadioButtonGroup
          options={sortByOptions}
          disabledOptions={disabledSortByOptions}
          value={sortBy}
          onChange={onSortChange}
        />
      </div>
      <Button
        onClick={onToggleExpandCollapse}
        icon={isFullyExpanded ? 'angle-up' : 'angle-double-down'}
        variant={'secondary'}
      >
        {`${isFullyExpanded ? 'Collapse' : 'Expand'} all`}
      </Button>
      <div>{isEditable && isAdmin && <EditRulesButton dataSourceName={dataSourceName} />}</div>
    </Options>
  );
}
