import { AppEvents, DataSourceInstanceSettings, DataSourceSettings } from '@grafana/data';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import appEvents from 'grafana/app/core/app_events';
import * as api from 'pages/Rules/utils/api';
import { RulesSorting } from 'pages/Rules/utils/enums';
import { ReduxState } from 'store';
import { getRuler } from 'store/utils/datasources';
import { RuleYML } from 'types/rulesExternal';
import { Rules, RulesConfig, RecentlyCreatedGroupNames as NewlyCreatedGroupNames } from 'types/rulesInternal';
import { text, DEFAULT_NAMESPACE } from 'utils/consts';
import { httpStatusToError } from 'utils/datasource';
import { AlertingRuleState, HttpError } from 'utils/enums';
import yaml from 'yaml';
import { RuleLocation } from './thunks';
import { moveRecentlyCreatedGroupsToTop } from './utils';

export type EditRuleLocation = RuleLocation & {
  isNewGroup?: boolean;
  isNewRule?: boolean;
};

type GroupLocation = {
  namespace: string;
  groupName: string;
};

type EditGroupNameLocation = GroupLocation & {
  isNewGroup?: boolean; // if true, group will be rendered at the top
};

export type RulesState = {
  alertStateFilter: AlertingRuleState[];
  dsSettings: {
    [name: string]: DataSourceSettings;
  };
  isEditingRules: boolean;
  isWriteInProgress: boolean;
  rules: Rules | null;
  rulesConfig: RulesConfig | null;
  showAlertingRules: boolean;
  showAnnotations: boolean;
  showRecordingRules: boolean;
  sortBy: RulesSorting;

  dataSourceError?: HttpError;
  editRuleLocation?: EditRuleLocation;
  editGroupNameLocation?: EditGroupNameLocation;
  isFullyExpanded: boolean;

  // keep track of which groups were created "recently" (in current browser session),
  // so we can have them sorted to top and "new" label added
  newlyCreatedGroupNames: NewlyCreatedGroupNames;
};

export const initialState: RulesState = {
  alertStateFilter: [...Object.values(AlertingRuleState)],
  dsSettings: {},
  isEditingRules: false,
  isWriteInProgress: false,
  rules: null,
  rulesConfig: null,
  showAlertingRules: true,
  showAnnotations: true,
  showRecordingRules: true,
  sortBy: RulesSorting.None,
  newlyCreatedGroupNames: {},
  isFullyExpanded: false,
};

const EMPTY_RULE: RuleYML = Object.freeze({
  alert: '',
  expr: '',
});

export const fetchRules = createAsyncThunk(
  'rules/fetchRules',
  async (dataSource: DataSourceInstanceSettings, thunkAPI) => {
    try {
      return await api.fetchRules(dataSource);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchRulesConfig = createAsyncThunk(
  'rules/fetchRulesConfig',
  async ({ dataSourceName }: { dataSourceName: string }, thunkAPI) => {
    try {
      const { ruler, rulesSource } = getRuler(thunkAPI.getState() as ReduxState, dataSourceName);
      if (ruler) {
        return await api.fetchRulesConfig(ruler, rulesSource);
      } else {
        appEvents.emit(AppEvents.alertError, [text.toast.errorRulerNotFound]);
        thunkAPI.dispatch(rulesActions.setIsEditingRules(false));
        return thunkAPI.rejectWithValue(Error('Tried to fetch Ruler config with missing Ruler data source.'));
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const rulesSlice = createSlice({
  name: 'rules',
  initialState,
  reducers: {
    addGroup: (state) => {
      let namespace = DEFAULT_NAMESPACE;
      if (state.rulesConfig) {
        namespace = Object.keys(state.rulesConfig)[0] ?? DEFAULT_NAMESPACE;
        state.rulesConfig[namespace].unshift({
          name: '',
          rules: [EMPTY_RULE],
        });
      } else {
        state.rulesConfig = {
          [DEFAULT_NAMESPACE]: [
            {
              name: '',
              rules: [EMPTY_RULE],
            },
          ],
        };
      }
      state.editRuleLocation = {
        namespace,
        groupIndex: 0,
        ruleIndex: 0,
        isNewGroup: true,
        isNewRule: true,
      };
      delete state.editGroupNameLocation;
    },
    addRule: (state, { payload: { groupIndex, namespace, ruleIndex } }: PayloadAction<RuleLocation>) => {
      if (state.rulesConfig) {
        state.rulesConfig[namespace][groupIndex].rules.splice(ruleIndex, 0, EMPTY_RULE);
      } else {
        throw new Error(`Trying to add rule, but rulesConfig is null!`);
      }
      state.editRuleLocation = {
        groupIndex,
        namespace,
        ruleIndex,
        isNewRule: true,
      };

      delete state.editGroupNameLocation;
    },
    editRule: (state, { payload }: PayloadAction<RuleLocation>) => {
      state.editRuleLocation = { ...payload };
      delete state.editGroupNameLocation;
    },
    // renames in redux store without persisting! used when creating new group
    renameGroup: (state, { payload }: PayloadAction<GroupLocation & { newName: string }>) => {
      const { namespace, groupName, newName } = payload;
      const group = state.rulesConfig?.[namespace]?.find((g) => g.name === groupName);
      if (group) {
        group.name = newName;
      }
    },
    finishedWriting: (state) => {
      state.isWriteInProgress = false;
    },
    removeRule: (state, { payload: { groupIndex, namespace, ruleIndex } }: PayloadAction<RuleLocation>) => {
      // this is only used for removing rule that wasn't saved. use deleteRule thunk to delete existing rules
      if (state.rulesConfig) {
        // if it's the only rule in group, need to remove group too because it was created with "add group" but not persisted
        if (state.rulesConfig[namespace][groupIndex].rules.length === 1) {
          state.rulesConfig[namespace].splice(groupIndex, 1);
        } else {
          state.rulesConfig[namespace][groupIndex].rules.splice(ruleIndex, 1);
        }
      }
    },
    setAlertStateFilter: (state, { payload }: PayloadAction<AlertingRuleState[]>) => {
      state.alertStateFilter = payload.slice();
    },
    setDataSourceSettings: (state, { payload }: PayloadAction<DataSourceSettings[]>) => {
      state.dsSettings = {};

      const allSettings = payload;
      for (const settings of allSettings) {
        state.dsSettings[settings.name] = settings;
      }
    },
    setDatasourceError: (state, { payload }: PayloadAction<HttpError | undefined>) => {
      state.dataSourceError = payload;
    },
    setIsEditingRules: (state, { payload }: PayloadAction<boolean>) => {
      state.isEditingRules = payload;
    },
    setSortBy: (state, { payload }: PayloadAction<RulesSorting>) => {
      state.sortBy = payload;
    },
    startWriting: (state) => {
      state.isWriteInProgress = true;
    },
    stopEditingRule: (state) => {
      delete state.editRuleLocation;
    },
    toggleAlertStateFilter: (state, { payload }: PayloadAction<AlertingRuleState>) => {
      if (state.alertStateFilter.includes(payload)) {
        state.alertStateFilter = state.alertStateFilter.filter((el) => el !== payload);
      } else {
        state.alertStateFilter.push(payload);
      }
    },
    toggleShowAlertingRules: (state) => {
      state.showAlertingRules = !state.showAlertingRules;

      if (!state.showAlertingRules && state.sortBy === RulesSorting.State) {
        state.sortBy = RulesSorting.None;
      }
    },
    toggleShowAnnotations: (state) => {
      state.showAnnotations = !state.showAnnotations;
    },
    toggleShowRecordingRules: (state) => {
      state.showRecordingRules = !state.showRecordingRules;
    },
    toggleIsFullyExpanded: (state) => {
      state.isFullyExpanded = !state.isFullyExpanded;
    },
    updateRule: (state, { payload }: PayloadAction<{ location: RuleLocation; definition: string }>) => {
      const {
        location: { groupIndex, namespace, ruleIndex },
        definition,
      } = payload;
      if (state.rulesConfig) {
        state.rulesConfig[namespace][groupIndex].rules[ruleIndex] = yaml.parse(definition);
      }
    },
    setEditGroupNameLocation: (state, { payload }: PayloadAction<EditGroupNameLocation | undefined>) => {
      state.editGroupNameLocation = payload;
    },
    addNewlyCreatedGroupName: (state, { payload }: PayloadAction<GroupLocation>) => {
      state.newlyCreatedGroupNames[payload.namespace] = [
        payload.groupName,
        ...(state.newlyCreatedGroupNames[payload.namespace] || []).filter((name) => name !== payload.groupName),
      ];
    },
    changeNewlyCreatedGroupName: (state, { payload }: PayloadAction<GroupLocation & { newName: string }>) => {
      state.newlyCreatedGroupNames[payload.namespace] = state.newlyCreatedGroupNames[payload.namespace]?.map((name) =>
        name === payload.groupName ? payload.newName : name
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRules.fulfilled, (state, action) => {
      delete state.dataSourceError;
      state.rules = moveRecentlyCreatedGroupsToTop(action.payload, state.newlyCreatedGroupNames);
    });
    builder.addCase(fetchRules.rejected, (state, action) => {
      state.dataSourceError = httpStatusToError((action.payload as any).status);
    });
    builder.addCase(fetchRulesConfig.fulfilled, (state, { payload }) => {
      // Cortex always sends both `alert` and `record` keys.
      // So we need to remove the empty one.
      for (const namespace of Object.keys(payload)) {
        for (const ruleGroup of payload[namespace]) {
          for (const rule of ruleGroup.rules) {
            if ('alert' in rule && rule.alert === '') {
              delete (rule as any).alert;
            } else if ('record' in rule && rule.record === '') {
              delete (rule as any).record;
            }
          }
        }
      }

      state.rulesConfig = moveRecentlyCreatedGroupsToTop(payload, state.newlyCreatedGroupNames);
    });
    builder.addCase(fetchRulesConfig.rejected, (state) => {
      state.rulesConfig = null;
    });
  },
});

export const { actions: rulesActions } = rulesSlice;
