import React, { FC } from 'react';
import { AlertmanagerConfig } from 'types/alertmanager';
import { UpdateConfigFn } from 'utils/alertmanager/hooks';
import { QueryKey } from 'utils/enums';
import { useQueryParam } from 'utils/hooks';
import { EditReceiver } from './EditReceiver';
import { ReceiverList } from './ReceiverList';

export type ReceiverRoutesProps = {
  config: AlertmanagerConfig;
  updateConfig: UpdateConfigFn;
};

export const ReceiverRoutes: FC<ReceiverRoutesProps> = ({ config, updateConfig }) => {
  const [receiverName] = useQueryParam(QueryKey.ReceiversReceiverName);

  if (receiverName) {
    return <EditReceiver receiverName={receiverName} config={config} updateConfig={updateConfig} />;
  }
  return <ReceiverList updateConfig={updateConfig} config={config} />;
};
