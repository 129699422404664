import { GrafanaTheme } from '@grafana/data';
import { useStyles } from '@grafana/ui';
import { css, cx } from 'emotion';
import React from 'react';
import { marginBottom } from 'utils/styles';

const getStyles = (theme: GrafanaTheme) => ({
  containerOptions: css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    ${marginBottom(theme).lg};
  `,
});

type Props = {
  className?: string;
};

export const Options: React.FC<Props> = ({ children, className }) => {
  const styles = useStyles(getStyles);
  return (
    <div data-testid="options" className={cx(styles.containerOptions, className)}>
      {children}
    </div>
  );
};
