import { DataSourceInstanceSettings, OrgRole } from '@grafana/data';
import { config } from '@grafana/runtime';

export function getUserOrgRole(): OrgRole {
  return config.bootData.user.orgRole;
}

export function getAllDataSources(): DataSourceInstanceSettings[] {
  return Object.values(config.datasources);
}
