import { GrafanaTheme } from '@grafana/data';
import { useStyles } from '@grafana/ui';
import { css, cx } from 'emotion';
import React, { FC } from 'react';
import { altBackgroundColor, fontSize, thinBorder } from 'utils/styles';

const getTableStyle = (theme: GrafanaTheme) => css`
  border: ${thinBorder(theme)};
  border-radius: 2px;
  border-spacing: 0;
  border-collapse: separate; // collapse does not work with border-radius on tables

  width: 100%;

  thead tr,
  tbody tr:nth-child(even) {
    background-color: ${altBackgroundColor(theme)};
  }

  td,
  th {
    padding: ${theme.spacing.sm} ${theme.spacing.md};
    ${fontSize(theme).md};
    font-weight: ${theme.typography.weight.regular};
  }
`;

export const StripedTable: FC<
  React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>
> = ({ children, className, ...props }) => {
  return (
    <table className={cx(useStyles(getTableStyle), className)} {...props}>
      {children}
    </table>
  );
};
