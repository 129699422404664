import { useStyles } from '@grafana/ui';
import { YMLCode } from 'components/YMLCode';
import { css, cx } from 'emotion';
import React, { useState } from 'react';
import { getGlobalStyles } from 'styles';
import { AlertingRule, RecordingRule } from 'types/rulesInternal';
import { RuleType } from 'utils/enums';
import { LabelsAndAnnotationsTable } from './LabelsAndAnnotationsTable';
import { RuleHeader } from './RuleHeader';
import { getGradientStyle } from './styles/rule';

export type Props = {
  rule: AlertingRule | RecordingRule;
  isVisible: boolean;
};

export const Rule = React.memo(({ isVisible, rule }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const globalStyles = useStyles(getGlobalStyles);

  return (
    <div
      data-testid="rule-container"
      className={cx(
        globalStyles.containerCard(false),
        rule.type === RuleType.Alerting && getGradientStyle(rule.state),
        !isVisible && css('display: none')
      )}
    >
      <RuleHeader isCollapsed={isCollapsed} onToggleCollapse={() => setIsCollapsed(!isCollapsed)} rule={rule} />
      <YMLCode value={rule.definition} linkToExplore={rule.query} />
      {!isCollapsed && rule.type === RuleType.Alerting && rule.alerts.length > 0 && (
        <LabelsAndAnnotationsTable rule={rule} />
      )}
    </div>
  );
});
