import { GrafanaTheme } from '@grafana/data';
import { useStyles } from '@grafana/ui';
import { Button } from 'components/Button';
import { css, cx } from 'emotion';
import React, { useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useDispatch } from 'react-redux';
import { fetchSilencesThunk } from 'store/silences/thunks';
import { getGlobalStyles } from 'styles';
import { SilenceData } from 'types/silencesInternal';
import { text } from 'utils/consts';
import { SilenceState } from 'utils/enums';
import { emitAppEventsError } from 'utils/misc';
import { marginRight, padding, thinBorder } from 'utils/styles';
import { trackEvent } from 'utils/tracking';
import { AffectedAlerts } from './AffectedAlerts';
import { SilenceHeader } from './SilenceHeader';
import * as api from './utils/api';
import { canEditSilences } from './utils/permissions';

export type SilenceProps = {
  dataSourceName: string;
  onClickEdit: (silence: SilenceData) => void;
  onClickRecreate: (silence: SilenceData) => void;
  silence: SilenceData;
};

export const getStyles = (theme: GrafanaTheme) => ({
  containerAlerts: css`
    ${padding(theme).sm};

    border-top: ${thinBorder(theme)};
  `,
  containerAlertsWithButtonsBeneath: css`
    border-bottom: ${thinBorder(theme)};
  `,
  containerButtons: css`
    white-space: nowrap;
    ${padding(theme).sm};

    & > button:not(:last-child) {
      ${marginRight(theme).sm};
    }
  `,
  containerExpiredSilence: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: ${theme.isDark ? theme.palette.gray10 : theme.palette.white};
    opacity: 0.5;

    pointer-events: none;
  `,
  popOutOfExpiredSilence: css`
    position: relative;
    z-index: 1;
  `,
});

export function Silence({ onClickEdit, onClickRecreate, silence, dataSourceName }: SilenceProps) {
  const { affectedAlerts, id, status, affectedAlertsError } = silence;
  const silenceState = status.state;
  const hasExpired = silenceState === SilenceState.Expired;

  const dispatch = useDispatch();

  const [isCollapsed, setIsCollapsed] = useState(true);

  const globalStyles = useStyles(getGlobalStyles);
  const styles = useStyles(getStyles);

  const onClickExpire = useAsyncCallback(async () => {
    trackEvent('Click expire silence');

    try {
      await api.expireSilence(dataSourceName, id);
      dispatch(fetchSilencesThunk(dataSourceName));
    } catch (error) {
      emitAppEventsError([text.toast.errorGeneric]);
      throw error;
    }
  });

  const buttons = hasExpired ? (
    <Button
      variant="secondary"
      className={styles.popOutOfExpiredSilence}
      onClick={() => {
        onClickRecreate(silence);
      }}
      icon="bell"
    >
      Recreate
    </Button>
  ) : (
    <>
      <Button variant="secondary" icon="pen" onClick={() => onClickEdit(silence)}>
        Edit silence
      </Button>
      <Button variant="secondary" icon="clock-nine" onClick={onClickExpire.execute}>
        Expire silence
      </Button>
    </>
  );

  return (
    <div data-testid="silence" className={globalStyles.containerCard(false, false)}>
      <SilenceHeader silence={silence} />
      <div className={cx(styles.containerAlerts, canEditSilences() && styles.containerAlertsWithButtonsBeneath)}>
        {(!affectedAlertsError || !!affectedAlerts?.length) && (
          <AffectedAlerts
            alerts={affectedAlerts}
            isCollapsed={isCollapsed}
            onToggleCollapse={() => setIsCollapsed((prevState) => !prevState)}
            silenceId={id}
          />
        )}
        {affectedAlertsError && (
          <p>
            {text.error.errorFetchingAffectedAlerts}: {affectedAlertsError}
          </p>
        )}
      </div>
      {canEditSilences() && <div className={styles.containerButtons}>{buttons}</div>}
      {hasExpired && <div className={styles.containerExpiredSilence} />}
    </div>
  );
}
