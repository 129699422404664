import { GrafanaTheme } from '@grafana/data';
import { css } from 'emotion';
import { marginBottom, marginTop } from 'utils/styles';

export const getRuleListStyles = (theme: GrafanaTheme) => ({
  groupControls: css`
    display: flex;
    & + & {
      ${marginTop(theme).md};
    }
  `,
  expandButton: css`
    height: 36px;
    width: 36px;
    background-color: ${theme.colors.bg2};
    border: none;
  `,
  containerAddFirstRule: css`
    display: flex;
    justify-content: space-between;
  `,
  containerHeaderRuleGroup: css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &:hover {
      cursor: pointer;
    }
  `,
  containerHeaderRuleGroupEdit: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  containerNamespace: css`
    & + & {
      ${marginTop(theme).xl};
    }
  `,
  containerRuleGroup: css`
    flex-grow: 1;
    padding: ${theme.spacing.sm};
    background-color: ${theme.colors.bg2};
    & + & {
      ${marginTop(theme).xl};
    }
  `,
  containerRuleGroupEdit: css`
    & + & {
      ${marginTop(theme).xl};
    }
  `,
  headerRuleGroup: css`
    display: inline-block;
    ${marginBottom(theme).xs};
  `,
  hr: css`
    margin: ${theme.spacing.sm} 0;
  `,
});
