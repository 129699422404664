import { GrafanaTheme } from '@grafana/data';
import { Icon, useStyles } from '@grafana/ui';
import { Button } from 'components/Button';
import { StripedTable } from 'components/StripedTable';
import { css } from 'emotion';
import React, { FC } from 'react';
import { marginBottom, fontSize } from 'utils/styles';
import { canEditReceivers } from './utils/permissions';

type SectionTableProps = {
  header: string;
  description: string;
  addButtonLabel: string;
  onAdd: () => void;
};

const getStyles = (theme: GrafanaTheme) => ({
  header: css`
    ${fontSize(theme).lg};
  `,
  topContainer: css`
    display: flex;
    ${marginBottom(theme).md};

    & > div:first-child {
      flex: 1;
      ${fontSize(theme).md};
    }

    & > div:nth-child(2) {
      flex: 0;
    }
  `,
});

export const SectionTable: FC<SectionTableProps> = (props) => {
  const { header, description, addButtonLabel, onAdd, children } = props;

  const styles = useStyles(getStyles);

  return (
    <>
      <h3 className={styles.header}>{header}</h3>
      <div className={styles.topContainer}>
        <div>{description}</div>
        {canEditReceivers() && (
          <div>
            <Button onClick={onAdd}>
              <Icon name="plus" /> {addButtonLabel}
            </Button>
          </div>
        )}
      </div>
      <StripedTable>{children}</StripedTable>
    </>
  );
};
