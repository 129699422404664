import { GrafanaTheme } from '@grafana/data';
import { css } from 'emotion';
import { fontSize, marginBottom, marginRight, marginLeft, paddingRight } from 'utils/styles';

export const getRuleHeaderStyles = (theme: GrafanaTheme) => ({
  collapseToggle: css`
    cursor: pointer;
    ${marginRight(theme).xs};
  `,
  containerAlertName: css`
    display: flex;
    align-items: center;

    word-break: break-all;
    color: ${theme.isDark ? theme.palette.white : theme.palette.black};
    ${fontSize(theme).md};
  `,
  containerHeader: (isEditing: boolean) => css`
    display: flex;
    align-items: ${isEditing ? 'flex-start' : 'center'};
    justify-content: space-between;
    min-height: 24px;

    & > * {
      align-self: center;
    }

    ${marginBottom(theme).sm};

    a {
      color: ${theme.colors.textBlue};
    }
  `,
  containerLeft: css`
    ${marginRight(theme).md};
  `,
  containerRight: css`
    display: flex;
    align-items: center;
  `,
  editControls: css`
    ${paddingRight(theme).xs};
  `,
  editControl: css`
    ${fontSize(theme).md};
    ${marginLeft(theme).xl};
  `,
  rearrangeArrowsContainer: css`
    min-width: 64px;
    margin-right: -${theme.spacing.xs};
    white-space: nowrap;
  `,
  rearrangeArrow: css`
    ${marginLeft(theme).sm};
    ${marginRight(theme).sm};
  `,
  evaluationError: css`
    display: inline-block;
    ${marginRight(theme).xs};
  `,
});
