import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAlertGroups } from 'pages/Alerts/utils/api';

export const fetchAlertGroupsThunk = createAsyncThunk(
  'alerts/fetchAlertGroups',
  async (dataSourceName: string, thunkAPI) => {
    try {
      return await fetchAlertGroups(dataSourceName);
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);
