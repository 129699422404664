import { GrafanaTheme } from '@grafana/data';
import { useStyles } from '@grafana/ui';
import { css, cx } from 'emotion';
import React, { FC } from 'react';
import { marginRight, marginTop } from 'utils/styles';

const getStyle = (theme: GrafanaTheme) => css`
  & > * {
    display: inline-block;
    ${marginTop(theme).xs};
    ${marginRight(theme).xs};
  }
`;

export const TagGroup: FC<JSX.IntrinsicElements['div']> = ({ className, ...props }) => {
  return <div className={cx(useStyles(getStyle), className)} {...props} />;
};
