import { GrafanaTheme } from '@grafana/data';
import { Button, Tag, useStyles } from '@grafana/ui';
import { CardContainer } from 'components/CardContainer';
import { CollapseButton } from 'components/CollapseButton';
import { LabelsAndAnnotations } from 'components/LabelsAndAnnotations';
import { css, cx } from 'emotion';
import { expireSilence } from 'pages/Silences/utils/api';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { alertsActions } from 'store/alerts';
import { fetchAlertGroupsThunk } from 'store/alerts/thunks';
import { silencesActions } from 'store/silences';
import { AlertmanagerAlert } from 'types/alertsInternal';
import { SilencePayload } from 'types/silencesExternal';
import { text } from 'utils/consts';
import { AlertState } from 'utils/enums';
import { useQueryParam, useSelectedAlertManagerName } from 'utils/hooks';
import { assertIsDefined, emitAppEventsError } from 'utils/misc';
import { marginBottom, marginLeft, marginRight, marginTop, thinBorder } from 'utils/styles';
import { trackEvent } from 'utils/tracking';
import { useMatchersFromLabels } from './utils/hooks';

enum UiAlertState {
  Unprocessed = 'unprocessed',
  Active = 'active',
  Suppressed = 'suppressed',

  // More specific suppression status than we get from Alertmanager
  Inhibited = 'inhibited',
  Silenced = 'silenced',
}

const convertToUiAlertState = (status: AlertmanagerAlert['status']): UiAlertState => {
  if (status.state === AlertState.Suppressed) {
    if (status.inhibitedBy.length > 0) {
      return UiAlertState.Inhibited;
    }
    if (status.silencedBy.length > 0) {
      return UiAlertState.Silenced;
    }
    return UiAlertState.Suppressed;
  }

  return (status.state as unknown) as UiAlertState;
};

const getStyles = (theme: GrafanaTheme) => ({
  containerButtons: css`
    ${marginTop(theme).sm};

    > * {
      ${marginRight(theme).sm};
    }
  `,
  containerActiveHeading: css`
    &::before {
      display: block;
      height: 2px;
      content: ' ';

      position: absolute;
      left: 0;
      right: 0;
      /* Needed for gradient to cover border */
      top: -1px;

      border-radius: 2px;
      background-image: linear-gradient(90deg, ${theme.palette.blue77} 0%, ${theme.palette.blue95} 100%);
    }
  `,
  containerHeading: css`
    display: flex;
    align-items: center;

    ${marginBottom(theme).xxs};
  `,
  containerLabelsAndAnnotations: css`
    ${marginTop(theme).sm};
    ${marginBottom(theme).sm};
  `,

  activeTag: css`
    display: inline-block;
    ${marginLeft(theme).sm};

    background-color: ${theme.palette.blue95};
    color: ${theme.isDark ? 'black' : 'white'};
  `,
  alertName: css`
    margin: 0;

    color: ${theme.isDark ? 'white' : 'black'};
  `,
  tag: css`
    display: inline-block;
    ${marginLeft(theme).sm};

    background-color: ${theme.isDark ? theme.palette.gray15 : theme.palette.gray98};
    color: inherit;

    border: ${thinBorder(theme)};
  `,
});

export const AlertCard: FC<AlertmanagerAlert> = ({ annotations, labels, startsAt, generatorURL, status }) => {
  const alertName = labels.alertname ?? 'No alertname defined on this alert.';
  const alertState = convertToUiAlertState(status);
  const isActive = alertState === UiAlertState.Active;
  const collapsible = Object.keys(annotations).length > 0 || Object.keys(labels).length > 0;

  const dispatch = useDispatch();

  const silenceMatchers: SilencePayload['matchers'] = useMatchersFromLabels(labels);

  const [, setTab] = useQueryParam('tab');

  const selectedAlertmanagerName = assertIsDefined(useSelectedAlertManagerName());

  const [isCollapsed, setIsCollapsed] = useState(true);

  const styles = useStyles(getStyles);

  const onClickLabel = (label: string, value: string) => {
    const labelValue = `${label}=${value}`;
    trackEvent('Notifications: Click on alert label to add to filter', labelValue);
    dispatch(alertsActions.addLabelFilter(labelValue));
  };

  const expireSilenceButton = (
    <Button
      variant="secondary"
      icon="clock-nine"
      onClick={async () => {
        trackEvent('Alerts list: Click expire silence');

        try {
          for (let silencedBy of status.silencedBy) {
            await expireSilence(selectedAlertmanagerName, silencedBy);
          }
          dispatch(fetchAlertGroupsThunk(selectedAlertmanagerName));
        } catch (error) {
          emitAppEventsError([text.toast.errorGeneric]);
          throw error;
        }
      }}
    >
      Expire silence
    </Button>
  );

  const silenceButton = (
    <Button
      variant="secondary"
      icon="bell-slash"
      onClick={() => {
        trackEvent('Alerts list: Click silence');
        dispatch(silencesActions.setEditorDefaultValues({ matchers: silenceMatchers }));
        setTab('silences');
      }}
    >
      Silence
    </Button>
  );

  return (
    <CardContainer as="li" data-testid="alert">
      <div className={cx(styles.containerHeading, isActive && styles.containerActiveHeading)}>
        {collapsible && (
          <CollapseButton
            aria-label={`${isCollapsed ? 'Expand' : 'Collapse'} alert ${alertName}`}
            isCollapsed={isCollapsed}
            onClick={() => setIsCollapsed((prev) => !prev)}
          />
        )}
        <h6 className={styles.alertName}>{alertName}</h6>
        <Tag className={isActive ? styles.activeTag : styles.tag} name={alertState} />
      </div>
      <div>{startsAt.toISOString()}</div>

      {!isCollapsed && (
        <div className={styles.containerLabelsAndAnnotations}>
          <LabelsAndAnnotations
            labels={labels}
            showLabelsLabel={true}
            annotations={annotations}
            showAnnotations={true}
            onClickLabel={onClickLabel}
          />
        </div>
      )}
      <div className={styles.containerButtons}>
        {generatorURL && (
          <a href={generatorURL} target="_blank">
            <Button variant="secondary" icon="graph-bar">
              Source graph
            </Button>
          </a>
        )}
        {alertState === UiAlertState.Silenced ? expireSilenceButton : silenceButton}
      </div>
    </CardContainer>
  );
};
