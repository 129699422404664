import { GrafanaTheme } from '@grafana/data';
import { useStyles } from '@grafana/ui';
import { css } from 'emotion';
import React, { FC } from 'react';
import { Alert } from 'types/rulesInternal';
import { marginTop } from 'utils/styles';
import { AlertLabels } from './AlertLabel';
import { TextWithLinks } from './TextWithLinks';

type Props = {
  annotations: Alert['annotations'];
  labels: Alert['labels'];

  onClickLabel?: (label: string, value: string) => void;
  showAnnotations?: boolean;
  showLabelsLabel?: boolean;
};

const getStyles = (theme: GrafanaTheme) => ({
  containerAnnotation: css`
    ${marginTop(theme).xs};
  `,
});

export const LabelsAndAnnotations: FC<Props> = ({
  onClickLabel,
  annotations,
  labels,
  showLabelsLabel = false,
  showAnnotations = true,
}) => {
  const styles = useStyles(getStyles);

  const annotationElements =
    showAnnotations &&
    Object.keys(annotations).map((key) => (
      <div className={styles.containerAnnotation} key={`${key}=${annotations[key]}`}>
        <strong>{key}</strong>
        <br />
        <TextWithLinks container="span">{annotations[key]}</TextWithLinks>
      </div>
    ));

  return (
    <>
      {showLabelsLabel && <strong>Labels</strong>}
      <AlertLabels labels={labels} onClick={onClickLabel} />
      {annotationElements}
    </>
  );
};
