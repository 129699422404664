import { ReduxState } from 'store';
import { DataSourceType } from 'utils/enums';

export function getRuler(state: ReduxState, dataSourceName: string) {
  const dsSettings = state.rules.dsSettings;
  const rulesSource = dsSettings[dataSourceName];
  return {
    ruler: Object.values(dsSettings).find(
      (currSource) => currSource.type === DataSourceType.Ruler && currSource.basicAuthUser === rulesSource.basicAuthUser
    ),
    rulesSource,
  };
}
