import deepEqual from 'fast-deep-equal';
import React, { FC } from 'react';
import { AlertingRuleYML, RecordingRuleYML } from 'types/rulesExternal';
import { RuleMeta } from '../EditRuleList';
import { EditRuleEditMode } from './EditRuleEditMode';
import { EditRuleReadMode } from './EditRuleReadMode';

export type Props = {
  isWriteInProgress: boolean;
  rule: AlertingRuleYML | RecordingRuleYML;
  ruleMeta: RuleMeta;
  dataSourceName: string;
};

export const EditRule: FC<Props> = React.memo(
  (props) => (props.ruleMeta.isEditing ? <EditRuleEditMode {...props} /> : <EditRuleReadMode {...props} />),
  deepEqual
);
