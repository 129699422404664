import { OrgRole } from '@grafana/data';
import { getUserOrgRole } from 'utils/config';
import { HttpError } from './enums';

const userOrgRole = getUserOrgRole();
export const isAdmin = userOrgRole === OrgRole.Admin;
export const isEditor = userOrgRole === OrgRole.Editor;
export const isViewer = userOrgRole === OrgRole.Viewer;

export const INPUT_DEBOUNCE_DELAY = 500;

export const intervals = {
  alertmanager: {
    refetchAlertGroups: 5000,
  },
  alerts: {
    refetchRules: 5000,
  },
  silences: {
    refetchSilences: 5000,
  },
};

const httpErrors = {
  [HttpError.NotFound]: 'Not found.',
  [HttpError.Unreachable]: 'Data source is unreachable.',
  [HttpError.Unauthorized]: 'Failed to authenticate.',
  [HttpError.Other]: 'Unexpected error.',
} as const;

export const DEFAULT_NAMESPACE = 'default';
export const NEW_RULE_CURSOR_POSITION = 7;

export const text = {
  datasources: {
    healthCheckSuccess: 'Health check passed.',
    healthCheckFailure: 'Health check failed.',
  },
  error: {
    alertmanagerNoConfig: 'No Alertmanager configuration found.',
    alertmanagerConfigError: 'Unable to fetch Alertmanager configuration due to an unexpected error.',
    responseDiscarded: 'Response was discarded.',
    silencesMissingMatchers: 'Cannot display affected notifications, matchers need to be defined.',
    silencesNoMatchingAlerts: 'No matching notifications.',
    receiversNoneFound: 'Currently, there are no receivers defined.',
    errorFetchingAffectedAlerts: 'Error fetching affected alerts',
  },
  globalConfig: {
    addGrafanaServerButton: 'Update configuration',
    addGrafanaServerSuccess: 'Config file updated',
    grafanaSmtpTitle: 'Send alert email notifications from Grafana Cloud',
    grafanaSmtpDescription: `
Use this option if you want to use Grafana Cloud to send notifications for your cloud alerts via email. 
You can skip this step if you prefer using your own email server.`,
    grafanaSmtpAddedDescription: `
Default configurations have been added to use Grafana Cloud to send notifications for your cloud alerts via email.`,
  },
  toast: {
    amConfigSuccess: 'Changes might take a minute to take effect.',
    amConfigFailure: 'Config could not be stored.',
    errorRulerNotFound: 'Ruler datasource not found.',
    errorGeneric: 'An unknown error occurred.',
    successGeneric: 'Changes saved.',
    silenceCreationFailure: 'Could not create silence.',
    errorDeletingReceiver: 'Failed to delete receiver due to unexpected error.',
    successDeletingReceiver: 'Receiver deleted. Changes might take a minute to take effect.',
    successSavingReceiver: 'Receiver saved. Changes might take a minute to take effect.',
  },
  receivers: {
    // delete receivers modal
    deleteReceiverModalTitle: (receiverName: string) => `Delete receiver "${receiverName}"`,
    deleteReceiverModalBodyNoRoutes: (receiverName: string) =>
      `Are you sure you want to delete receiver "${receiverName}"?`,
    deleteReceiverModalBodyWithRoutes: (receiverName: string, numRoutes: number) =>
      `This receiver is configured on ${numRoutes} ${
        numRoutes === 1 ? 'route' : 'routes'
      }. It will be unset from the routes, but routes will not be deleted. Are you sure you want to proceed?`,
    deleteReceiverModalConfirmButton: 'Delete',
    deleteReceiverModalCancelButton: 'Cancel',

    // receivers table
    receiversSectionTitle: 'Receivers',
    receiversSectionDescription: 'Defines where the notifications will be sent to (slack, email, etc.)',
    newReceiverButton: 'New receiver',
    cannotDeleteDefaultReceiverMsg: 'Cannot delete default root route receiver.',
    receiversTableColName: 'Receiver name',
    receiversTableColIntTypes: 'Integration types',
    receiversTableColRouteLabels: 'Route labels',
    receiversTableColActions: 'Actions',
    actionDelete: 'Delete',
    actionEdit: 'Edit',

    // edit receiver form
    editReceiverHeader: 'Edit receiver',
    newReceiverHeader: 'New receiver',
    submitReceiverButton: 'Save receiver configuration',
    cancelEditingReceiverButton: 'Cancel',
    defaultReceiverInfo: 'This receiver will be set as default on the root route.',

    // form validation errors
    validationNameRequired: '"name" property is required.',
    validationNameType: '"name" must be a string.',
    validationReceiverAlreadyExists: 'another receiver with this name already exists.',
  },
  httpErrors,
} as const;
