import { GrafanaTheme, SelectableValue, TimeRange } from '@grafana/data';
import { Field, useStyles, MultiSelect, Select, TimeRangeInput } from '@grafana/ui';
import { Options } from 'components/Options';
import { SwitchWithLabel } from 'components/SwitchWithLabel';
import { css, cx } from 'emotion';
import React, { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useReduxSelector } from 'store';
import { alertsActions } from 'store/alerts';
import { Rules } from 'types/rulesInternal';
import { Color } from 'utils/enums';
import { fontSize, fontWeight, paddingBottom, marginTop } from 'utils/styles';
import { trackEvent } from 'utils/tracking';

export type Props = {
  rules: Rules;
  isEditable: boolean;
};

const getStyles = (theme: GrafanaTheme) => ({
  containerRuleSorting: css`
    min-width: 224px;

    label {
      white-space: nowrap;
    }
  `,
  headerGroup: css`
    ${fontSize(theme).sm};
    ${fontWeight(theme).semibold};
  `,
  labelFiring: css`
    color: ${theme.isDark ? Color.RedLightest : Color.RedDarkest};
  `,
  labelInactive: css`
    color: ${theme.isDark ? Color.GreenLightest : Color.GreenDarkest};
  `,
  labelPending: css`
    color: ${theme.isDark ? Color.OrangeLightest : Color.OrangeDarkest};
  `,
  // ad-hoc fix for spacing when tags wrap if they don't fit horizontally
  labelFilterInput: css`
    & > div > div:first-child {
      ${paddingBottom(theme).xs};

      & > div {
        ${marginTop(theme).xs};
      }
    }
  `,
  labelFilterField: css`
    min-width: 300px;
  `,
  receiverFilterField: css`
    min-width: 160px;
  `,
  dateFilterField: css`
    min-width: 154px;
  `,
  options: css`
    justify-content: flex-start;
    flex-wrap: wrap;
    & > *:not(:last-child) {
      margin-right: 48px;
    }
  `,
});

export const AlertsOptions: FC = () => {
  const dispatch = useDispatch();

  const alertGroups = useReduxSelector((state) => state.alerts.alertGroups);

  const showInhibited = useReduxSelector((state) => state.alerts.showInhibited);
  const showSilenced = useReduxSelector((state) => state.alerts.showSilenced);
  const labelFilters = useReduxSelector((state) => state.alerts.labelFilters);
  const filterReceiver = useReduxSelector((state) => state.alerts.filterReceiver);
  const filterStartDate = useReduxSelector((state) => state.alerts.filterStartDate);

  const styles = useStyles(getStyles);

  const labelOptions = useMemo((): SelectableValue[] => {
    const uniqueLabels = new Set<string>();
    alertGroups?.forEach((group) =>
      group.alerts.forEach((alert) => {
        Object.entries(alert.labels).forEach(([key, value]) => {
          const pair = `${key}=${value}`;
          uniqueLabels.add(pair);
        });
      })
    );

    return Array.from(uniqueLabels.values())
      .sort()
      .map((value) => ({
        value,
        label: value,
      }));
  }, [alertGroups]);

  const receiverOptions = useMemo((): SelectableValue[] => {
    const uniqueReceivers =
      alertGroups?.reduce<string[]>((receivers, group) => {
        if (group.receiver.name && !receivers.includes(group.receiver.name)) {
          return [group.receiver.name, ...receivers];
        }
        return receivers;
      }, []) ?? [];

    return [
      {
        label: 'All',
        value: '_all_',
      },
      ...uniqueReceivers.map((receiver) => ({
        label: receiver,
        value: receiver,
      })),
    ];
  }, [alertGroups]);

  const onLabelFitlersChange = (items: Array<SelectableValue<string>>) => {
    const labels = items.map((item) => item.value).filter((v): v is string => !!v);
    trackEvent('Notifications: Set label filter', labels.join(', '));
    dispatch(alertsActions.setLabelFilters(labels));
  };

  const toggleInhibitedFilter = () => {
    trackEvent('Notifications: Toggle inhibited filter', String(!showInhibited));
    dispatch(alertsActions.toggleShowInhibited());
  };

  const toggleSilencedFilter = () => {
    trackEvent('Notifications: Toggle silenced filter', String(!showSilenced));
    dispatch(alertsActions.toggleShowSilenced());
  };

  const onReceiverFilterChange = (item: SelectableValue) => {
    trackEvent('Notifications: Select receiver filter', item.value);
    dispatch(alertsActions.setFilterReceiver(item.value === '_all_' ? undefined : item.value));
  };

  const onStartDateFilterChange = (range: TimeRange) => {
    trackEvent('Notifications: Select start date filter range', `${range.raw.from} - ${range.raw.to}`);
    dispatch(alertsActions.setFilterStartDate(range));
  };

  return (
    <Options className={styles.options}>
      <div>
        <h6 className={styles.headerGroup}>Notification state</h6>
        <SwitchWithLabel value={showSilenced} onChange={toggleSilencedFilter}>
          Silenced
        </SwitchWithLabel>
        <SwitchWithLabel value={showInhibited} onChange={toggleInhibitedFilter}>
          Inhibited
        </SwitchWithLabel>
      </div>
      <div data-testid="receiver-filter-container">
        <Field label="Receiver" className={styles.receiverFilterField}>
          <Select value={filterReceiver ?? '_all_'} options={receiverOptions} onChange={onReceiverFilterChange} />
        </Field>
      </div>
      <div data-testid="date-filter-container">
        <Field label="Start date" className={styles.dateFilterField}>
          <TimeRangeInput value={filterStartDate} onChange={onStartDateFilterChange} clearable={true} />
        </Field>
      </div>
      <div data-testid="label-filter-container">
        <Field label={`Label name/value`} className={styles.labelFilterField}>
          <MultiSelect
            className={cx({ [styles.labelFilterInput]: !!labelFilters.length })}
            options={labelOptions}
            value={labelFilters}
            onChange={onLabelFitlersChange}
            placeholder='Custom matcher, eg. "env=production"'
          />
        </Field>
      </div>
    </Options>
  );
};
