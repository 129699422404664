import { dateTimeForTimeZone, DefaultTimeZone } from '@grafana/data';
import { useStyles } from '@grafana/ui';
import { AlertmanagerDatasourcePicker } from 'components/DataSourcePicker';
import { DeprecationNotice } from 'components/DeprecationNotice';
import intervalToDuration from 'date-fns/intervalToDuration';
import { css } from 'emotion';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useReduxSelector } from 'store';
import { silencesActions } from 'store/silences';
import { fetchSilencesThunk } from 'store/silences/thunks';
import { getGlobalStyles } from 'styles';
import { SilenceData } from 'types/silencesInternal';
import { intervals } from 'utils/consts';
import { useSelectedAlertManagerName } from 'utils/hooks';
import { durationToAbbreviatedString } from 'utils/misc';
import { trackEvent } from 'utils/tracking';
import { Silence } from './Silence';
import { SilenceEditor, SilenceEditorProps } from './SilenceEditor';
import { SilencesOptions } from './SilencesOptions';
import { canEditSilences } from './utils/permissions';

const silencesStyle = css`
  min-width: 290px;
`;

export function Silences() {
  const dispatch = useDispatch();

  const dataSourceError = useReduxSelector((state) => state.silences.dataSourceError);
  const defaultsFromRedux = useReduxSelector((state) => state.silences.editorDefaultValues);
  const silences = useReduxSelector((state) => state.silences.silences);

  const selectedAlertmanagerName = useSelectedAlertManagerName();

  const [defaultEditValues, setDefaultEditValues] = useState<Partial<SilenceEditorProps['defaultValues']>>();
  const [showEditor, setShowEditor] = useState(false);

  const globalStyles = useStyles(getGlobalStyles);

  useEffect(() => trackEvent('View Silences'), []);

  useEffect(() => {
    if (selectedAlertmanagerName) {
      const fetchInterval = setInterval(
        () => dispatch(fetchSilencesThunk(selectedAlertmanagerName)),
        intervals.silences.refetchSilences
      );
      dispatch(fetchSilencesThunk(selectedAlertmanagerName));

      return () => {
        clearInterval(fetchInterval);
      };
    }

    return () => {};
  }, [dispatch, selectedAlertmanagerName]);

  useEffect(() => {
    if (defaultsFromRedux) {
      setDefaultEditValues(defaultsFromRedux);
      setShowEditor(true);
      dispatch(silencesActions.removeEditorDefaultValues());
    }
  }, [defaultsFromRedux, dispatch]);

  const cancelEditing = () => {
    trackEvent('Click cancel silence editor');

    setShowEditor(false);
  };

  const editExistingSilence = (silence: SilenceData) => {
    trackEvent('Click edit silence');

    const { affectedAlerts, updatedAt, ...propsForEditor } = silence;
    setDefaultEditValues({
      ...propsForEditor,
      startsAt: dateTimeForTimeZone(DefaultTimeZone, propsForEditor.startsAt).toISOString(),
      endsAt: dateTimeForTimeZone(DefaultTimeZone, propsForEditor.endsAt).toISOString(),
      duration: durationToAbbreviatedString(
        intervalToDuration({
          start: propsForEditor.startsAt,
          end: propsForEditor.endsAt,
        })
      ),
      timeZone: DefaultTimeZone,
    });
    setShowEditor(true);
  };

  const editorSubmitted = useCallback(() => {
    setShowEditor(false);
  }, []);

  const createNewSilence = () => {
    trackEvent('Click new silence');

    setDefaultEditValues(undefined);
    setShowEditor(true);
  };

  const recreateSilence = (silence: SilenceData) => {
    trackEvent('Click recreate silence');

    const { affectedAlerts, endsAt, id, startsAt, status, updatedAt, ...propsForEditor } = silence;
    setDefaultEditValues(propsForEditor);
    setShowEditor(true);
  };
  return (
    <>
      <DeprecationNotice
        url={
          '/alerting/silences' +
          (selectedAlertmanagerName ? `?alertmanager=${encodeURIComponent(selectedAlertmanagerName)}` : '')
        }
      />
      <AlertmanagerDatasourcePicker disabled={showEditor} />
      {selectedAlertmanagerName && (
        <div className={silencesStyle}>
          {dataSourceError ? (
            <h5 className={globalStyles.headerError}>{dataSourceError}</h5>
          ) : (
            <>
              {canEditSilences() && (
                <SilencesOptions showCancel={showEditor} onCancel={cancelEditing} onNewSilence={createNewSilence} />
              )}
              {showEditor ? (
                <SilenceEditor defaultValues={defaultEditValues} onSubmit={editorSubmitted} />
              ) : (
                <>
                  {silences?.map((silence) => (
                    <Silence
                      dataSourceName={selectedAlertmanagerName}
                      key={silence.id}
                      onClickEdit={editExistingSilence}
                      onClickRecreate={recreateSilence}
                      silence={silence}
                    />
                  ))}
                  {!silences?.length && <p>No silences found.</p>}
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}
