import { useMemo } from 'react';
import { AlertmanagerAlert } from 'types/alertsInternal';

export function useMatchersFromLabels(labels: AlertmanagerAlert['labels']) {
  return useMemo(
    () =>
      Object.entries(labels).map(([label, value]) => ({
        isRegex: false,
        name: label,
        value,
      })),
    [labels]
  );
}
