import { formatDistanceToNowStrict } from 'date-fns';
import React, { FC } from 'react';
import { useRerenderEvery } from 'utils/hooks';

export type TimeDistanceToNowProps = {
  date: Date;
};

export const TimeDistanceToNow: FC<TimeDistanceToNowProps> = ({ date }) => {
  useRerenderEvery(1000);
  return (
    <>
      {formatDistanceToNowStrict(date, {
        roundingMethod: 'floor',
      })}
    </>
  );
};
