import { GrafanaTheme } from '@grafana/data';
import { useStyles } from '@grafana/ui';
import { css } from 'emotion';
import React from 'react';
import linkify from 'utils/linkifyit';

type Props = {
  children: string;

  container?: keyof JSX.IntrinsicElements | ((props: any) => JSX.Element);
};

const getStyle = (theme: GrafanaTheme) => css`
  color: ${theme.colors.linkExternal};
`;

export const TextWithLinks: React.FC<Props> = ({ children, container: Container = '<>' }) => {
  const results = linkify.match(children);

  const linkClassName = useStyles(getStyle);

  if (!results) {
    return <Container>{children}</Container>;
  }

  const elements = [];
  let lastIndex = 0;

  results.forEach((result, i) => {
    if (result.index > lastIndex) {
      elements.push(children.substring(lastIndex, result.index));
    }

    elements.push(
      <a href={result.url} className={linkClassName} target="_blank" key={i}>
        {result.text}
      </a>
    );

    lastIndex = result.lastIndex;
  });

  if (children.length > lastIndex) {
    elements.push(children.substring(lastIndex));
  }

  return <Container>{elements}</Container>;
};
