type AlertmanagerPayloadWithDates = {
  endsAt: string;
  startsAt: string;
};

type AlertmanagerPayloadWithTransformedDates<T> = Omit<T, 'endsAt' | 'startsAt'> & {
  endsAt: Date;
  startsAt: Date;
};

// Used for e.g. silences and alert groups.
export function transformDatesInPayload<T extends AlertmanagerPayloadWithDates>(
  payload: T[]
): Array<AlertmanagerPayloadWithTransformedDates<T>> {
  return payload.map((alert) => ({
    ...alert,
    endsAt: new Date(alert.endsAt),
    startsAt: new Date(alert.startsAt),
  }));
}
