import { GrafanaTheme } from '@grafana/data';
import { useStyles } from '@grafana/ui';
import { css } from 'emotion';
import React from 'react';

const getStyle = (theme: GrafanaTheme) => css`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  background-color: ${theme.isDark ? 'black' : 'white'};
  opacity: 0.5;
`;

export function Lightbox() {
  const style = useStyles(getStyle);

  return <div className={style} />;
}
