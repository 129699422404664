import { GrafanaTheme } from '@grafana/data';
import { Icon, Input, useStyles } from '@grafana/ui';
import { Button } from 'components/Button';
import { css } from 'emotion';
import React, { FC, useState, KeyboardEvent, useEffect } from 'react';
import { marginLeft } from 'utils/styles';

type EditableLabelProps = {
  text: string;
  onSave: (text: string) => void;
  isSaving?: boolean;
  isEditing: boolean;
  onStartEditing: () => void;
  onStopEditing: () => void;
  editIconTitle?: string;
  // should save button be be disabled if user did not change it? default false
  disableSaveWhenUnchanged?: boolean;
};

const getStyles = (theme: GrafanaTheme) => {
  return {
    inputWrapper: css`
      display: inline-flex;
      max-width: 368px;
      ${marginLeft(theme).xs};
    `,
    button: css`
      ${marginLeft(theme).sm};
    `,
    editIcon: css`
      cursor: pointer;
    `,
  };
};

export const EditableLabel: FC<EditableLabelProps> = (props) => {
  const {
    text,
    onSave,
    isSaving,
    onStartEditing,
    onStopEditing,
    editIconTitle,
    isEditing,
    disableSaveWhenUnchanged,
  } = props;
  const [value, setValue] = useState(text);

  const styles = useStyles(getStyles);

  useEffect(() => setValue(text), [text]);

  const canSave = value && (value !== text || disableSaveWhenUnchanged === false) && !isSaving;

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      onStopEditing();
    } else if (event.key === 'Enter' && canSave) {
      onSave(value);
    }
  };

  if (!isEditing) {
    return (
      <span>
        {text}{' '}
        <Icon title={editIconTitle} className={styles.editIcon} size="xs" name="pen" onClick={() => onStartEditing()} />
      </span>
    );
  }
  return (
    <div className={styles.inputWrapper}>
      <Input
        disabled={isSaving}
        value={value}
        ref={(inpt) => inpt?.focus()}
        onKeyDown={onKeyDown}
        onChange={(e) => setValue(e.currentTarget.value)}
      />
      <Button isLoading={isSaving} disabled={!canSave} className={styles.button} onClick={() => onSave(value)}>
        {isSaving ? 'Saving...' : 'Save'}
      </Button>
      <Button onClick={() => onStopEditing()} className={styles.button} variant="secondary">
        Cancel
      </Button>
    </div>
  );
};
