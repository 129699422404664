import { createAsyncThunk } from '@reduxjs/toolkit';
import { isUndefined } from 'lodash';
import { createOrUpdateSilence, fetchAlerts, fetchSilences } from 'pages/Silences/utils/api';
import { AlertmanagerAlert } from 'types/alertsInternal';
import { SilenceCreationPayload } from 'types/silencesExternal';
import { SilenceData } from 'types/silencesInternal';
import { text } from 'utils/consts';
import { emitAppEventsError } from 'utils/misc';

type CreateOrUpateSilenceParams = {
  data: SilenceCreationPayload;
  dataSourceName: string;
};

export const createOrUpdateSilenceThunk = createAsyncThunk(
  'silences/createOrUpdateSilence',
  async ({ dataSourceName, data }: CreateOrUpateSilenceParams, thunkAPI) => {
    try {
      await createOrUpdateSilence(dataSourceName, {
        ...data,
        endsAt: new Date(data.endsAt).toISOString(),
        startsAt: new Date(data.startsAt).toISOString(),
      });
    } catch (error) {
      if (error.data && error.data.response) {
        emitAppEventsError([error.data.response]);
      } else {
        emitAppEventsError([text.toast.silenceCreationFailure]);
      }

      return thunkAPI.rejectWithValue(error);
    }

    await thunkAPI.dispatch(fetchSilencesThunk(dataSourceName));
    return undefined;
  }
);

export const fetchSilencesThunk = createAsyncThunk(
  'silences/fetchSilences',
  async (datasourceName: string, thunkAPI) => {
    try {
      const silences = await fetchSilences(datasourceName);

      const affectedAlertsResponses = await Promise.all(
        silences
          .filter((silence) => !isUndefined(silence.matchers))
          .map(
            ({ id, matchers }): Promise<{ id: string; data: AlertmanagerAlert[]; error?: string }> =>
              fetchAlerts(datasourceName, matchers)
                .then((data: AlertmanagerAlert[]) => {
                  return {
                    id,
                    data,
                  };
                })
                .catch((error) => {
                  return {
                    id,
                    data: [],
                    error: error?.data?.message || error?.message || 'Unexpected error.',
                  };
                })
          )
      );

      return silences.map((silence) => {
        const affectedAlerts = affectedAlertsResponses.find(({ id }) => id === silence.id);
        return {
          ...silence,
          affectedAlerts: affectedAlerts?.data,
          affectedAlertsError: affectedAlerts?.error,
        } as SilenceData;
      });
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
