import { Button } from '@grafana/ui';
import { Options } from 'components/Options';
import React from 'react';

type Props = {
  showCancel: boolean;
  onCancel: () => void;
  onNewSilence: () => void;
};

export function SilencesOptions({ showCancel, onCancel, onNewSilence }: Props) {
  return (
    <Options>
      <div />
      <div>
        {showCancel ? (
          <Button onClick={onCancel}>Cancel</Button>
        ) : (
          <Button icon="plus" onClick={onNewSilence}>
            New silence
          </Button>
        )}
      </div>
    </Options>
  );
}
