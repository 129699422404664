import { Switch, useStyles } from '@grafana/ui';
import { css } from 'emotion';
import React, { ComponentProps } from 'react';

// Some weird issue related to emotion polluting the global scope
// makes this necessary:
// https://github.com/emotion-js/emotion/issues/1800
// grafana docs explicitly state that we don't support the
// css attribute anyway.
type Props = Omit<ComponentProps<typeof Switch>, 'css'>;

const getStyles = () => ({
  label: css`
    cursor: pointer;

    display: flex;
    margin-bottom: 4px;
  `,
  switchContainer: css`
    display: inline-block;
    margin-right: 4px;
  `,
});

export function SwitchWithLabel({ children, ...restProps }: Props) {
  const styles = useStyles(getStyles);

  return (
    <label className={styles.label}>
      <div className={styles.switchContainer}>
        <Switch {...restProps} />
      </div>
      {children}
    </label>
  );
}
