import { GrafanaTheme } from '@grafana/data';
import { Icon, useStyles } from '@grafana/ui';
import { AlertLabels } from 'components/AlertLabel';
import { Button } from 'components/Button';
import { CardContainer } from 'components/CardContainer';
import { CollapseButton } from 'components/CollapseButton';
import { css } from 'emotion';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { alertsActions } from 'store/alerts';
import { silencesActions } from 'store/silences';
import { AlertmanagerGroup } from 'types/alertsInternal';
import { SilencePayload } from 'types/silencesExternal';
import { useQueryParam } from 'utils/hooks';
import { fontSize, marginLeft, marginTop } from 'utils/styles';
import { trackEvent } from 'utils/tracking';
import { AlertCard } from './AlertCard';
import { useMatchersFromLabels } from './utils/hooks';

const getStyles = (theme: GrafanaTheme) => ({
  containerAlertList: css`
    ${marginLeft(theme).xl};
  `,
  containerAlertGroup: css`
    display: flex;
    align-items: center;
  `,
  containerCardContent: css`
    width: 100%;
    ${fontSize(theme).md};
  `,
  containerCardContentLeft: css`
    display: flex;
  `,
  containerCardContentMeta: css`
    white-space: nowrap;
  `,
  containerCardFoldout: css`
    margin-top: 0;
    padding-top: 0;
    ${marginLeft(theme).xl};
  `,
  containerCollapse: css`
    background-color: red;

    display: inline-block;
  `,
  silenceGroupButton: css`
    ${marginTop(theme).sm};
  `,
});

type Props = AlertmanagerGroup & {
  isCollapsed: boolean;
};

export const AlertGroup: FC<Props> = ({ alerts, labels, receiver, isCollapsed }) => {
  const isNotGroupedAlerts = Object.keys(labels).length === 0;

  const dispatch = useDispatch();

  const silenceMatchers: SilencePayload['matchers'] = useMatchersFromLabels(labels);

  const [, setTab] = useQueryParam('tab');

  const styles = useStyles(getStyles);

  const onClickLabel = (label: string, value: string) => {
    const labelValue = `${label}=${value}`;
    trackEvent('Notifications: Click on alert group label to add to filter', labelValue);
    dispatch(alertsActions.addLabelFilter(labelValue));
  };

  const silenceGroup = () => {
    trackEvent('Alerts list: Click silence group');

    dispatch(silencesActions.setEditorDefaultValues({ matchers: silenceMatchers }));
    setTab('silences');
  };

  const collapseToggle = (
    <CollapseButton
      aria-label={`${isCollapsed ? 'Expand' : 'Collapse'} alerts group ${JSON.stringify(labels)}`}
      isCollapsed={isCollapsed}
      onClick={() => dispatch(alertsActions.toggleGroupCollapse(labels))}
    />
  );

  const alertList = (
    <ul className={styles.containerAlertList}>
      {alerts.map((alert) => (
        <AlertCard key={alert.fingerprint} {...alert} />
      ))}
    </ul>
  );

  return (
    <li data-testid="alert-group">
      <CardContainer className={styles.containerAlertGroup}>
        <div className={styles.containerCardContent}>
          <div className={styles.containerCardContentLeft}>
            {collapseToggle}
            <div style={{ display: 'inline-block' }}>
              {isNotGroupedAlerts ? 'Not grouped' : <AlertLabels labels={labels} onClick={onClickLabel} />}
            </div>
          </div>
        </div>
      </CardContainer>
      <CardContainer className={styles.containerCardFoldout}>
        <div className={styles.containerCardContentMeta}>
          {alerts.length} alerts | Receiver: {receiver.name}
        </div>
        {!isCollapsed && !isNotGroupedAlerts && (
          <Button variant="secondary" onClick={silenceGroup} className={styles.silenceGroupButton}>
            {/* @ts-ignore Remove once this got released: https://github.com/grafana/grafana/pull/29579 */}
            <Icon name="bell-slash" /> Silence group
          </Button>
        )}
      </CardContainer>
      {!isCollapsed && alertList}
    </li>
  );
};
