import { Receiver, Route } from 'types/alertmanager';
import { receiverConfigKeyToIntegration } from 'utils/alertmanager/consts';
import { AlertmanagerIntegration } from 'utils/enums';

export function getReceiverIntegrations(receiver: Receiver): AlertmanagerIntegration[] {
  return Object.entries(receiver)
    .filter(([, value]) => !!value?.length)
    .map(([key]) => receiverConfigKeyToIntegration[key as keyof typeof receiverConfigKeyToIntegration])
    .filter((x) => !!x);
}

// returns the number of routes a receiver is configured on in a route tree
export function countReceiverUsage(route: Route, receiverName: string): number {
  const count = (route: Route): number => {
    return (
      (route.receiver === receiverName ? 1 : 0) + (route.routes || []).reduce((total, route) => total + count(route), 0)
    );
  };
  return count(route);
}
