import { useStyles } from '@grafana/ui';
import { cx } from 'emotion';
import React, { FC, HTMLAttributes } from 'react';
import { getGlobalStyles } from 'styles';

export type Props = HTMLAttributes<HTMLDivElement> & {
  as?: keyof JSX.IntrinsicElements | ((props: any) => JSX.Element);
  highlight?: boolean;
  withPadding?: boolean;
};

export const CardContainer: FC<Props> = ({ as: As = 'div', highlight, withPadding, className, ...props }) => {
  const globalStyles = useStyles(getGlobalStyles);
  return <As className={cx(globalStyles.containerCard(highlight, withPadding), className)} {...props} />;
};
