import { GrafanaTheme } from '@grafana/data';
import { FieldValidationMessage, Field, Input, useStyles } from '@grafana/ui';
import { Button } from 'components/Button';
import { YMLCodeEditor } from 'components/YMLCodeEditor';
import { css, cx } from 'emotion';
import React, { useMemo, FC } from 'react';
import { useForm, Controller, Validate, FieldError } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AppDispatch, useReduxSelector } from 'store';
import { fetchRulesConfig, rulesActions } from 'store/rules';
import { setGroup } from 'store/rules/thunks';
import { AlertingRuleYML, RecordingRuleYML } from 'types/rulesExternal';
import { isFetchError } from 'utils/api';
import { NEW_RULE_CURSOR_POSITION } from 'utils/consts';
import { useDispatchWithFeedback } from 'utils/hooks';
import { marginRight, padding, thinBorder } from 'utils/styles';
import { trackEvent } from 'utils/tracking';
import yaml from 'yaml';
import { RuleMeta } from '../EditRuleList';
import { extractRuleValidationError } from '../utils/api';
import { rulesText } from '../utils/consts';
import { EditRuleContainer } from './EditRuleContainer';
import { EditRuleHeader } from './EditRuleHeader';

const getStyles = (theme: GrafanaTheme) => ({
  buttonsStyle: css`
    padding-top: ${theme.spacing.sm};

    & > * {
      ${marginRight(theme).sm};
    }
  `,
  newGroupHeader: css`
    border-bottom: ${thinBorder(theme)};
    ${padding(theme).sm};
  `,
  parseError: css`
    font-family: ${theme.typography.fontFamily.monospace};
    white-space: pre;
  `,
  groupNameInput: css`
    max-width: 320px;
  `,
});

const validateRuleValue: Validate = (data: string) => {
  console.log('validate', data);
  let parsedDefinition: any;
  try {
    parsedDefinition = yaml.parse(data, { prettyErrors: true });
  } catch (error) {
    return `\nYAML Parse Error: \n${error.message};`;
  }

  if (!parsedDefinition) {
    return 'Required.';
  }

  if (typeof parsedDefinition !== 'object') {
    return 'Must be an object.';
  }
  if (!('expr' in parsedDefinition)) {
    return 'Must contain "expr" property.';
  }
  if (!('alert' in parsedDefinition || 'record' in parsedDefinition)) {
    return 'Must contain "alert" or "record" property.';
  }
  if ('alert' in parsedDefinition && !parsedDefinition.alert) {
    return '"alert" cannot be empty.';
  }
  if ('record' in parsedDefinition && !parsedDefinition.record) {
    return '"record" cannot be empty.';
  }
  if (!parsedDefinition.expr) {
    return '"expr" cannot be empty.';
  }
  // @TODO there's probably more stuff we can check
  return true;
};

const makeGroupNameValidator = (existingGroupNames: string[]): Validate => (groupName: string) => {
  if (existingGroupNames && existingGroupNames.includes(groupName)) {
    return 'Group with this name already exists.';
  }
  return true;
};

export type Props = {
  isWriteInProgress: boolean;
  rule: AlertingRuleYML | RecordingRuleYML;
  ruleMeta: RuleMeta;
  dataSourceName: string;
};

type FormValues = {
  groupName?: string;
  definition: string;
};

export const EditRuleEditMode: FC<Props> = ({ isWriteInProgress, ruleMeta, rule, dataSourceName }) => {
  const dispatch: AppDispatch = useDispatch();
  const dispatchWithFeedback = useDispatchWithFeedback();

  const { location, isNewGroup, isOnlyRule } = ruleMeta;

  const { register, control, handleSubmit, errors, setError, clearErrors } = useForm<FormValues>({
    reValidateMode: 'onSubmit',
  });

  const isNewRule = 'alert' in rule && rule.expr === '' && rule.alert === '';

  const defaultDefinition = useMemo(() => (isNewRule ? 'alert: \nexpr: ' : yaml.stringify(rule)), [rule, isNewRule]);

  const rulesConfig = useReduxSelector((state) => state.rules.rulesConfig);
  const groupNameValidator = useMemo(() => {
    const groupNames = rulesConfig?.[location.namespace].map((g) => g.name) || [];
    // if it's new group, omit the first entry, as it's currently unsaved and will result in validating against self
    return makeGroupNameValidator(ruleMeta.isNewGroup ? groupNames.slice(1) : groupNames);
  }, [rulesConfig, location.namespace, ruleMeta.isNewGroup]);

  const styles = useStyles(getStyles);

  const save = (data: FormValues) => {
    dispatch(rulesActions.updateRule({ location, definition: data.definition }));
    if (data.groupName) {
      dispatch(
        rulesActions.renameGroup({
          namespace: location.namespace,
          groupName: ruleMeta.groupName,
          newName: data.groupName,
        })
      );
      dispatch(rulesActions.addNewlyCreatedGroupName({ namespace: location.namespace, groupName: data.groupName }));
    }
    dispatchWithFeedback(setGroup({ ...location, dataSourceName }), {
      ...rulesText.dispatchToasts,
      errorHandler: (payload) => {
        if (isFetchError(payload) && payload.status === 400 && payload.data.message) {
          const message = extractRuleValidationError(
            {
              ...ruleMeta,
              groupName: data.groupName ?? ruleMeta.groupName,
            },
            payload.data.message
          );
          if (message) {
            setError('definition', { type: 'manual', message });
            return true;
          }
        }
        return false;
      },
    })
      .then(() => {
        dispatch(rulesActions.stopEditingRule());
      })
      .catch(() => {
        console.error('failed to save rule');
      });
  };

  const cancel = () => {
    trackEvent('Click cancel editing rule');
    if (isOnlyRule) {
      // Enforcing this order is important to prevent flashing of intermediate state
      dispatch(fetchRulesConfig({ dataSourceName })).then(() => dispatch(rulesActions.stopEditingRule()));
      return;
    }

    dispatch(rulesActions.stopEditingRule());

    if (ruleMeta.isNewRule) {
      dispatch(rulesActions.removeRule(location));
      return;
    }
  };

  const onSubmit = (e: React.BaseSyntheticEvent) => {
    trackEvent('Click save rule');
    return handleSubmit(save)(e);
  };
  const definitionError = errors.definition as FieldError | undefined;

  return (
    <EditRuleContainer highlight={true}>
      <form onSubmit={onSubmit}>
        {isNewGroup && (
          <div className={styles.newGroupHeader}>
            <Field
              label="New group name"
              className={styles.groupNameInput}
              invalid={!!errors.groupName}
              error={errors.groupName?.message || 'Required.'}
            >
              <Input
                name="groupName"
                placeholder="Group name"
                autoFocus={true}
                ref={register({ required: true, validate: groupNameValidator })}
              />
            </Field>
            Add a rule to create a new group
          </div>
        )}
        <EditRuleContainer.Inner>
          <EditRuleHeader rule={rule} hideName={ruleMeta.isNewRule} />
          <Controller
            control={control}
            name="definition"
            defaultValue={defaultDefinition}
            rules={{
              required: true,
              validate: validateRuleValue,
            }}
            render={({ onChange, value }) => (
              <YMLCodeEditor
                autoFocus={!isNewGroup}
                initialCursorPosition={isNewRule ? NEW_RULE_CURSOR_POSITION : undefined}
                onChange={(newValue) => {
                  // clear error if user makes a change
                  if (definitionError && newValue !== value) {
                    clearErrors();
                  }
                  onChange(newValue);
                }}
                readOnly={isWriteInProgress}
                defaultValue={defaultDefinition}
              />
            )}
          />
          {definitionError && (
            <FieldValidationMessage
              className={cx({ [styles.parseError]: definitionError.message?.includes('YAML Parse Error') })}
            >
              {definitionError.message || 'Required.'}
            </FieldValidationMessage>
          )}
          <div className={styles.buttonsStyle}>
            <Button isLoading={isWriteInProgress} disabled={isWriteInProgress} type="submit">
              Save
            </Button>
            <Button disabled={isWriteInProgress} type="button" variant="secondary" onClick={cancel}>
              Cancel
            </Button>
          </div>
        </EditRuleContainer.Inner>
      </form>
    </EditRuleContainer>
  );
};
