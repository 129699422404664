import { GrafanaTheme, dateTime } from '@grafana/data';
import { useStyles } from '@grafana/ui';
import { Button } from 'components/Button';
import { AlertmanagerDatasourcePicker } from 'components/DataSourcePicker';
import { DelayedSpinner } from 'components/DelayedSpinner';
import { DeprecationNotice } from 'components/DeprecationNotice';
import { css } from 'emotion';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useReduxSelector } from 'store';
import { alertsActions } from 'store/alerts';
import { fetchAlertGroupsThunk } from 'store/alerts/thunks';
import { getGlobalStyles } from 'styles';
import { AlertmanagerGroup } from 'types/alertsInternal';
import { intervals } from 'utils/consts';
import { useSelectedAlertManagerName } from 'utils/hooks';
import { trackEvent } from 'utils/tracking';
import { AlertGroup } from './AlertGroup';
import { AlertsOptions } from './AlertsOptions';

const getStyles = (theme: GrafanaTheme) => ({
  containerAlerts: css`
    min-width: 290px;
  `,
  containerAlertGroups: css`
    list-style-type: none;
  `,
});

export function Alerts() {
  const dispatch = useDispatch();

  const groupsExpanded = useReduxSelector((state) => state.alerts.groupsExpanded);
  const alertGroups = useReduxSelector((state) => state.alerts.alertGroups);
  const dataSourceError = useReduxSelector((state) => state.alerts.dataSourceError);
  const showInhibited = useReduxSelector((state) => state.alerts.showInhibited);
  const showSilenced = useReduxSelector((state) => state.alerts.showSilenced);
  const labelFilters = useReduxSelector((state) => state.alerts.labelFilters);
  const filterReceiver = useReduxSelector((state) => state.alerts.filterReceiver);
  const filterStartDate = useReduxSelector((state) => state.alerts.filterStartDate);

  const filteredGroups = useMemo((): AlertmanagerGroup[] | undefined => {
    return (
      alertGroups
        // filter by receiver
        ?.filter((group) => !filterReceiver || group.receiver.name === filterReceiver)
        .map((group) => ({
          ...group,
          alerts: group.alerts.filter((alert) => {
            // filter by inhibited / silenced
            if (
              alert.status.state !== 'active' &&
              !((showInhibited && alert.status.inhibitedBy.length) || (showSilenced && alert.status.silencedBy.length))
            ) {
              return false;
            }

            // filter by start date
            const { from, to } = filterStartDate;
            const startsAt = dateTime(alert.startsAt);
            if ((from.isValid() && startsAt.isBefore(from)) || (to.isValid() && to.isBefore(startsAt))) {
              return false;
            }

            // filter by labels
            if (
              labelFilters.length &&
              !labelFilters.every((label) => {
                const [key, ...valueParts] = label.split('=');
                return alert.labels[key] === valueParts.join('=');
              })
            ) {
              return false;
            }
            return true;
          }),
        }))
        // remove groups that have had all of their alerts filtered out
        .filter((group) => !!group.alerts.length)
    );
  }, [alertGroups, showInhibited, showSilenced, labelFilters, filterReceiver, filterStartDate]);

  const selectedAlertmanagerName = useSelectedAlertManagerName();

  const styles = useStyles(getStyles);
  const globalStyles = useStyles(getGlobalStyles);

  useEffect(() => trackEvent('View Notifications'), []);

  useEffect(() => {
    if (selectedAlertmanagerName) {
      const fetchInterval = setInterval(
        () => dispatch(fetchAlertGroupsThunk(selectedAlertmanagerName)),
        intervals.alertmanager.refetchAlertGroups
      );
      dispatch(fetchAlertGroupsThunk(selectedAlertmanagerName));

      return () => {
        clearInterval(fetchInterval);
      };
    }

    return;
  }, [dispatch, selectedAlertmanagerName, showInhibited, showSilenced]);

  const numCollapsedGroups = useMemo(
    () =>
      filteredGroups?.reduce((count, group) => {
        return !groupsExpanded[group.id] ? count + 1 : count;
      }, 0),
    [groupsExpanded, filteredGroups]
  );

  let content: JSX.Element;
  if (filteredGroups) {
    if (filteredGroups.length > 0) {
      content = (
        <>
          <Button
            variant="secondary"
            disabled={numCollapsedGroups === 0}
            onClick={() => dispatch(alertsActions.expandAll())}
            style={{ marginRight: 8 }}
          >
            Expand all
          </Button>
          <Button
            variant="secondary"
            disabled={numCollapsedGroups === filteredGroups.length}
            onClick={() => dispatch(alertsActions.collapseAll())}
          >
            Collapse all
          </Button>
          <ul className={styles.containerAlertGroups}>
            {filteredGroups.map((alertGroup) => (
              <AlertGroup key={alertGroup.id} isCollapsed={!groupsExpanded[alertGroup.id]} {...alertGroup} />
            ))}
          </ul>
        </>
      );
    } else {
      content = <h5 className={globalStyles.headerError}>No active/matching notifications.</h5>;
    }
  } else {
    content = <DelayedSpinner />;
  }

  return (
    <div className={styles.containerAlerts}>
      <DeprecationNotice
        url={
          '/alerting/groups' +
          (selectedAlertmanagerName ? `?alertmanager=${encodeURIComponent(selectedAlertmanagerName)}` : '')
        }
      />
      <AlertmanagerDatasourcePicker />
      {dataSourceError ? (
        <h5 className={globalStyles.headerError}>{dataSourceError}</h5>
      ) : (
        selectedAlertmanagerName && (
          <>
            <AlertsOptions />
            {content}
          </>
        )
      )}
    </div>
  );
}
